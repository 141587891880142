import logger from 'utils/logger';

import { LOGIN_SUCCESS } from '../AuthManager/reducer';

export const CHANGE_VOLUME = 'cs.com/PlayerSettings/CHANGE_VOLUME';
export const TOGGLE_AUTOPLAY = 'cs.com/PlayerSettings/TOGGLE_AUTOPLAY';
export const SETTINGS_FOUND = 'cs.com/PlayerSettings/SETTINGS_FOUND';
export const UPDATE_CC_SETTINGS = 'cs.com/PlayerSettings/UPDATE_CC_SETTINGS';
export const INIT_SUBTITLES = 'cs.com/PlayerSettings/INIT_SUBTITLES';
export const SWITCH_LIMIT_TO_HD = 'cs.com/PlayerSettings/SWITCH_LIMIT_TO_HD';
export const SWITCH_ACTIVE_AUTOPLAY =
  'cs.com/PlayerSettings/SWITCH_ACTIVE_AUTOPLAY';

const log = logger.extend('reducers:player');

const COLORS = [
  { label: 'White', value: '255,255,255' },
  { label: 'Black', value: '0,0,0' },
  { label: 'Yellow', value: '255,255,0' },
  { label: 'Green', value: '0,128,0' },
  { label: 'Cyan', value: '0,255,255' },
  { label: 'Blue', value: '0,0,255' },
  { label: 'Magenta', value: '255,0,255' },
  { label: 'Red', value: '255,0,0' },
];

const OPACITIES = [
  { label: '0%', value: 0 },
  { label: '25%', value: 0.25 },
  { label: '75%', value: 0.75 },
  { label: '100%', value: 1 },
];

const OPTIONS_IDS = [
  'Subtitles',
  'Font Family',
  'Font Color',
  'Font Size',
  'Background Color',
  'Background Opacity',
  'Window Color',
  'Window Opacity',
  'Character Edge Style',
  'Font Opacity',
];

const FONTS_OPTIONS = [
  {
    label: 'Default',
    value: '',
  },
  {
    label: 'Monospace Serif',
    value:
      'font-family: "Courier New", Courier, "Nimbus Mono L", "Cutive Mono", monospace;',
  },
  {
    label: 'Proportionnal Serif',
    value:
      'font-family: "Times New Roman", Times, Georgia, Cambria, "PT Serif Caption", serif;',
  },
  {
    label: 'Monospace Sans Serif',
    value:
      'font-family: "Andale Mono", "Deja Vu Sans Mono", "Lucida Console", Monaco, Consolas, "PT Mono", monospace;',
  },
  {
    label: 'Proportionnal Sans Serif',
    value: 'font-family: Arial, Helvetica, Verdana;',
  },
  {
    label: 'Casual',
    value: 'font-family: "Comic Sans MS", Impact, Handlee, fantasy;',
  },
  {
    label: 'Cursive',
    value:
      'font-family: "Pacifico", "Monotype Corsiva", "URW Chancery L", "Dancing Script", cursive;',
  },
  {
    label: 'Small Capitals',
    value:
      'font-family: "Arial Unicode Ms", Arial, Helvetica, Verdana, "Marcellus SC", sans-serif; font-variant: small-caps;',
  },
];

const EDGE_STYLES = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Drop Shadow',
    value:
      'text-shadow: rgba(34, 34, 34, 0.75) 2.48611px 2.48611px 3.72917px, rgba(34, 34, 34, 0.75) 2.48611px 2.48611px 4.97222px, rgba(34, 34, 34, 0.75) 2.48611px 2.48611px 6.21528px;',
  },
  {
    label: 'Raised',
    value:
      'text-shadow: rgba(34, 34, 34, 0.75) 1px 1px, rgba(34, 34, 34, 0.75) 2px 2px;',
  },
  {
    label: 'Depressed',
    value:
      'text-shadow: rgba(204, 204, 204, 0.75) 1px 1px, rgba(34, 34, 34, 0.75) -1px -1px;',
  },
  {
    label: 'Uniform',
    value:
      'text-shadow: rgba(34, 34, 34, 0.75) 0px 0px 1.41111px, rgba(34, 34, 34, 0.75) 0px 0px 1.41111px, rgba(34, 34, 34, 0.75) 0px 0px 1.41111px, rgba(34, 34, 34, 0.75) 0px 0px 1.41111px, rgba(34, 34, 34, 0.75) 0px 0px 1.41111px;',
  },
];

const SIZES = [
  { label: '50%', value: '50%' },
  { label: '75%', value: '75%' },
  { label: '100%', value: '100%' },
  { label: '200%', value: '200%' },
];

const ALL_OPTIONS = {
  'Font Family': {
    options: FONTS_OPTIONS,
    selectedOption: FONTS_OPTIONS[0],
  },
  'Font Color': {
    options: COLORS,
    selectedOption: COLORS[0],
  },
  'Font Size': {
    options: SIZES,
    selectedOption: SIZES[2],
  },
  'Background Color': {
    options: COLORS,
    selectedOption: COLORS[1],
  },
  'Background Opacity': {
    options: OPACITIES,
    selectedOption: OPACITIES[3],
  },
  'Window Color': {
    options: COLORS,
    selectedOption: COLORS[1],
  },
  'Window Opacity': {
    options: OPACITIES,
    selectedOption: OPACITIES[0],
  },
  'Character Edge Style': {
    options: EDGE_STYLES,
    selectedOption: EDGE_STYLES[0],
  },
  'Font Opacity': {
    options: OPACITIES,
    selectedOption: OPACITIES[3],
  },
};

const initialState = {
  activeAutoplay: false,
  volume: 1,
  ccSettings: ALL_OPTIONS,
};

const playerSettings = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
      };
    case SETTINGS_FOUND:
      return action.storedState;
    case TOGGLE_AUTOPLAY:
      return {
        ...state,
        autoPlay: action.autoplay,
      };
    case CHANGE_VOLUME:
      return {
        ...state,
        volume: action.volume,
      };
    case INIT_SUBTITLES:
      const ccOptions = action.cc.map((cc) => ({
        label: cc.language,
        value: cc.file,
      }));
      const newCcOptions = {
        ...state.ccSettings,
        Subtitles: {
          ...(state.ccSettings && state.ccSettings.Subtitles),
          options: ccOptions,
        },
      };
      return {
        ...state,
        ccSettings: newCcOptions,
      };
    case UPDATE_CC_SETTINGS:
      let newOptions;
      if (action.settingLabel === 'Subtitles') {
        newOptions = {
          ...state.ccSettings,
          Subtitles: {
            ...state.ccSettings.Subtitles,
            selectedIndex: action.index,
          },
        };
      } else {
        newOptions = {
          ...state.ccSettings,
          [action.settingLabel]: {
            ...state.ccSettings[action.settingLabel],
            selectedOption: action.value,
          },
        };
      }
      return {
        ...state,
        ccSettings: newOptions,
      };

    case SWITCH_ACTIVE_AUTOPLAY:
      return {
        ...state,
        activeAutoplay: action.value,
      };

    default:
      return state;
  }
};

export default playerSettings;

// actions creators

export const toggleAutoplay = (autoplay) => ({
  type: TOGGLE_AUTOPLAY,
  autoplay,
});

export const initSubtitlesOptions = (cc) => ({
  type: INIT_SUBTITLES,
  cc,
});

export const changeVolume = (volume) => ({
  type: CHANGE_VOLUME,
  volume,
});

export const setActiveAutoplay = (value) => ({
  type: SWITCH_ACTIVE_AUTOPLAY,
  value,
});

export const updateCcSettings = (settingLabel, value, index) => ({
  type: UPDATE_CC_SETTINGS,
  settingLabel,
  value,
  index,
});

// selectors

const getPlayerSettings = (state) => state.playerSettings;

const getSelectedOption = (state) => (optionLabel) => {
  if (optionLabel === 'Subtitles') {
    const subOpt =
      getPlayerSettings(state).ccSettings &&
      getPlayerSettings(state).ccSettings.Subtitles;
    return subOpt && subOpt.options[subOpt.selectedIndex || 0];
  }
  return (
    getPlayerSettings(state).ccSettings &&
    getPlayerSettings(state).ccSettings[optionLabel] &&
    getPlayerSettings(state).ccSettings[optionLabel].selectedOption
  );
};

const getSelectedValue = (state) => (optionLabel) =>
  getSelectedOption(state)(optionLabel) &&
  getSelectedOption(state)(optionLabel).value;

export const getSelectedLabel = (state) => (optionLabel) =>
  getSelectedOption(state)(optionLabel) &&
  getSelectedOption(state)(optionLabel).label;

export const getPlayerCcSettings = (state) => {
  const getValue = getSelectedValue(state);
  const result = `
  .subtitleDiv > div > div {
    z-index: 9;
    background-color: rgba(${getValue('Window Color')}, ${getValue(
    'Window Opacity'
  )}) !important;
    }
  .subtitleDiv > div > div > div {
    z-index: 9;
      
    ${getValue('Font Family')}
    font-size: ${getValue('Font Size')} !important;
    background-color: rgba(${getValue('Background Color')}, ${getValue(
    'Background Opacity'
  )}) !important;
    color: rgba(${getValue('Font Color')}, ${getValue(
    'Font Opacity'
  )}) !important;
  ${getValue('Character Edge Style')}
  `;
  return result;
};

export const getSelectedCc = (state) => getSelectedOption(state)('Subtitles');

export const getPlayerCcSettingProperties = (state) => OPTIONS_IDS;

export const getPlayerCcSettingByLabel = (state) => (label) =>
  getPlayerSettings(state).ccSettings[label];

export const getAutoplaySetting = (state) => getPlayerSettings(state).autoPlay;

export const getVolumeSetting = (state) => {
  const volume = getPlayerSettings(state).volume;

  return typeof volume === 'number' ? volume : 100;
};

export const getActiveAutoplay = (state) =>
  getPlayerSettings(state).activeAutoplay;

// utils

const LOCAL_STORAGE_PERSIST = 'playerSettings';

export const saveSettingsWhenChanged = (store) => () => {
  try {
    const { playerSettings } = store.getState();

    if (
      JSON.stringify(playerSettings) !==
      localStorage.getItem(LOCAL_STORAGE_PERSIST)
    ) {
      localStorage.setItem(
        LOCAL_STORAGE_PERSIST,
        JSON.stringify(playerSettings)
      );
    }
  } catch (e) {
    //could not save, but don't log stuff otherwize it's going to blow the call stack
  }
};

export const loadSettingsFromLocalStorage = (store) => {
  try {
    const persisted = localStorage.getItem(LOCAL_STORAGE_PERSIST);
    if (persisted) {
      store.dispatch({
        type: SETTINGS_FOUND,
        storedState: JSON.parse(persisted),
      });
    }
    log('player settings loaded', JSON.parse(persisted));
  } catch (e) {
    log('error, clearing localstorage', e);
    localStorage.removeItem(LOCAL_STORAGE_PERSIST);
  }
};

import React from 'react';

import Button from 'components/Button';
import FadeInDiv from 'components/FadeInDiv';
import Text from 'components/Text';

import styles from './styles.module.css';

const CategoryButton = props => {
  const { category, focus, onClick } = props;

  return (
    <Button
      id={`category-button-${category.id}`}
      focus={focus}
      noTransition
      className={styles.category}
      borderPosition="Outline"
      onClick={onClick}
    >
      <FadeInDiv
        className={styles.background}
        src={category.imageUrl}
        style={{
          backgroundPosition: '50% 25%'
        }}
      >
        <Text noMargin large className={styles.subDiv} thin={false}>
          {category.label}
        </Text>
      </FadeInDiv>
    </Button>
  );
};

export default CategoryButton;

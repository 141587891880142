import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withConfig, withAuth } from 'hoc';
import {
  getGroups,
  fetchHomeFeed,
  getIsLoading,
  getFeaturedItems,
  fetchFeaturedItems,
  getMediaForGroupKey,
  getMediaForGroupIdKey,
  fetchGroupPage
} from 'reducers/Home/reducer';

import Carousel from 'components/Carousel';
import GroupCarousel from 'components/GroupCarousel';

import Spinner from 'components/Spinner';
import ScrollArea from 'components/ScrollArea';

import { fireEvent } from 'utils/analytics';

class Featured extends React.Component {
  state = {
    viewedMediaCards: new Set([]),
  };

  componentDidMount() {
    if (!this.props.groups) {
      this.props.fetchHomeFeed();
    }
    if (!this.props.featuredItems) {
      this.props.fetchFeaturedItems();
    }

    if (window.document.getElementById('modal-')) {
      this.setState({ preventFocus: true });
    }

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'homescreen'
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.featuredItems &&
      this.props.groups &&
      !this.state.finishedLoading
    ) {
      this.setState({ finishedLoading: true });
      prevProps.onFinishedLoading && prevProps.onFinishedLoading();
    }

    if (!this.props.groups && !this.props.isLoadingHomeFeed) {
      prevProps.fetchHomeFeed();
    }
  }

  addViewedCard = (id) => {
    this.setState({
      viewedMediaCards: this.state.viewedMediaCards.add(id),
    });
  };

  ignoreEventCheck = (mediaId) => {
    this.state.viewedMediaCards.has(mediaId)
  };

  render() {
    const {
      featuredItems,
      groups,
      getMediaForGroupKey,
      getMediaForGroupIdKey,
      fetchGroupPage,
      config
    } = this.props;

    if (!featuredItems || !groups) {
      return <Spinner />;
    }
    let allGroups = groups.map((g, index) => (
      <GroupCarousel
        key={`${g.id}${index}`}
        group={g}
        media={getMediaForGroupKey(g.key)}
        fetchMoreAction={fetchGroupPage}
        analyticsData={(mediaId, index) => {
          return {
            category: 'homescreen_thumbnail_impression',
            action: g.name,
            label: g.id,
            property: mediaId,
            value: index,
            analyticsCallback: this.addViewedCard,
            ignoreEventCheck: this.ignoreEventCheck
          }
        }}
        onSelect={(m, i) =>
          fireEvent({
            category: 'homescreen_playlist',
            action: g.label,
            label: g.id,
            property: m.key,
            value: i,
          })
        }
      />
    ));

    if (config.lowMemoryDevice) {
      allGroups = [allGroups[0]];
    }

    return (
      <ScrollArea id="page-featured">
        <Carousel
          focus={!this.state.preventFocus}
          wrapAround
          cardsToDisplay={1}
          analyticsMediaId={(media) => `media_${media.mediaIds[0]}`}
          analyticsData={(mediaId, index) => {
            return {
              category: 'homescreen_thumbnail_impression',
              action: 'hero_media',
              label: 'hero_media',
              property: mediaId,
              value: index,
              analyticsCallback: this.addViewedCard,
              ignoreEventCheck: this.ignoreEventCheck
            }
          }}
          media={featuredItems.map(featuredItem => ({
            ...featuredItem,
            ...getMediaForGroupIdKey(`media_${featuredItem.mediaIds[0]}`),
          }))}
          onSelect={(m, i) =>
            fireEvent({
              category: 'homescreen_featured',
              action: 'click',
              property: m.key,
              value:
                i < 0 ? (i % featuredItems.length) + featuredItems.length : i,
            })
          }
        />
        {allGroups}
      </ScrollArea>
    );
  }
}

const mapStateToProps = state => ({
  getMediaForGroupKey: getMediaForGroupKey(state),
  getMediaForGroupIdKey: getMediaForGroupIdKey(state),
  isLoadingHomeFeed: getIsLoading(state),
  groups: getGroups(state),
  featuredItems: getFeaturedItems(state)
});

const mapDispatchToProps = {
  fetchHomeFeed,
  fetchFeaturedItems,
  fetchGroupPage
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withConfig,
  withAuth
)(Featured);

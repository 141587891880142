import { takeLatest, call, put } from 'redux-saga/effects';

import imageQueryBuilder from 'utils/imageQueryBuilder';

import {
  FETCH_FEATURED_ITEMS,
  FETCH_HOME_FEED,
  FETCH_UNTYPED_GROUP,
  gotHomeFeed,
  gotFeaturedItems,
  gotGroupItems,
  errorFetching
} from './reducer';

import request from 'utils/request';

export const MEDIA_LIMIT = 12;
const homeFeedUrl = `${
  process.env.REACT_APP_API_ROOT
}/v1/sections/7/mobile?cache=true&collections=true&media_limit=${MEDIA_LIMIT}&${imageQueryBuilder()}`;

const featuredUrl = `${process.env.REACT_APP_API_ROOT}/v2/featured`;

export function* getHomeFeedData() {
  try {
    const data = yield call(request, homeFeedUrl);
    const { groups } = data.data;
    yield put(gotHomeFeed(groups));
  } catch (e) {
    yield put(errorFetching(e.message));
  }
}

export function* fetchFeaturedItems() {
  try {
    const data = yield call(request, featuredUrl);
    yield put(gotFeaturedItems(data));
  } catch (e) {
    yield put(errorFetching(e.message));
  }
}

export function* fetchGroupData(action) {
  try {
    const { filterBy, term, limit = MEDIA_LIMIT, page, categoryId } = action;
    const groupQueryUrl = `${
      process.env.REACT_APP_API_ROOT
    }/v1/media?filterBy=${filterBy}&term=${term}&limit=${limit}&collections=true&page=${page ||
      1}`;
    const data = yield call(request, groupQueryUrl);
    yield put(
      gotGroupItems({
        ...action,
        categoryId,
        media: data.data,
        pagesLoaded: data.paginator.current_page,
        canFetchMore: data.paginator.current_page < data.paginator.total_pages
      })
    );
  } catch (e) {
    yield put(errorFetching(e.message));
  }
}

export function* getFeaturedItems() {
  yield takeLatest(FETCH_FEATURED_ITEMS, fetchFeaturedItems);
}

export function* getHomeData() {
  yield takeLatest(FETCH_HOME_FEED, getHomeFeedData);
}

export function* getGroupData() {
  yield takeLatest(FETCH_UNTYPED_GROUP, fetchGroupData);
}

export default [getHomeData, getFeaturedItems, getGroupData];

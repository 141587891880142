import { auth, session } from './tokenManager';
import { setUserEuropean, getGdprApprovesTracking } from './gdpr';
import { getConfig } from 'config';
import { intlLocale } from 'utils/intl';

const handleResponse = response => {
  if (!response.ok) {
    throw Error(response.statusText);
  } else {
    try {
      const isIpInEuropeanUnion = response.headers.get('x-eu-user') === '1';
      setUserEuropean(isIpInEuropeanUnion);

      const userCountry = response.headers.get('cs-country');
      if (!localStorage.getItem('country') !== userCountry)
        localStorage.setItem('country', userCountry);
    } catch (e) {
      //nope!
    }

    return response.json();
  }
};

export default function request(url, options = {}) {
  const config = getConfig();

  const headers = new Headers();
  headers.append('content-type', 'application/json');

  if (auth.get()) headers.append('x-auth-token', auth.get());
  if (session.get()) headers.append('X-Session-Token', session.get());

  if (process.env.REACT_APP_FORCE_FREE_MONTH)
    headers.append('X-Platform', `smarttv-vizio`);
  else if (config.hasFreeAccess)
    headers.append('X-Platform', `smarttv-${config.name}`);
  else headers.append('X-Platform', `smarttv-legacy`);

  headers.append('X-Client-Version', config.version);

  try {
    headers.append('X-Language', intlLocale.split('-')[0]);
  } catch (e) {
    // big boss coming in
  }

  headers.append('Accept-Language', intlLocale);

  let gdprPrivacyOptOut = getGdprApprovesTracking();
  if (gdprPrivacyOptOut !== null) {
    headers.append('X-Privacy-Opt-Out', !gdprPrivacyOptOut);
  }

  if (options.headers) {
    for (let header of options.headers) {
      headers.append(header.name, header.value);
    }
  }

  options.headers = headers;

  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  if (options.skipResponseHandling) return fetch(url, options);

  return fetch(url, options).then(handleResponse);
}

import { mapMedia } from 'utils/mapper';

import {
  FETCH_HOME_FEED_SUCCESS,
  FETCH_UNTYPED_GROUP_SUCCESS
} from '../Home/reducer';
import { FETCH_CATEGORY_MEDIAS_SUCCESS } from '../Categories/reducer';
import {
  FETCH_SERIES,
  FETCH_SERIES_SUCCESS,
  FETCH_RELATED_SUCCESS
} from '../Series/reducer';
import {
  FETCH_COLLECTIONS_SUCCESS,
  FETCH_COLLECTION_SUCCESS
} from '../Collections/reducer';
import { SIGN_OUT, LOGIN_SUCCESS } from '../AuthManager/reducer';
import { secondsToHoursAndMinutes } from 'utils/formatters';

// constants
export const UPDATE_DISPLAYED_VIDEO_RATING =
  'cs.com/Medias/UPDATE_DISPLAYED_VIDEO_RATING';

export const FETCH_BOOKMARKED = 'cs.com/Medias/FETCH_BOOKMARKED';
export const FETCH_BOOKMARKED_SUCCESS =
  'cs.com/Medias/FETCH_BOOKMARKED_SUCCESS';

export const CLEARVOTE_MEDIA = 'cs.com/Medias/CLEARVOTE_MEDIA';
export const DOWNVOTE_MEDIA = 'cs.com/Medias/DOWNVOTE_MEDIA';
export const BOOKMARK_MEDIA = 'cs.com/Medias/BOOKMARK_MEDIA';
export const UPVOTE_MEDIA = 'cs.com/Medias/UPVOTE_MEDIA';

export const FETCH_RELATED_MEDIA = 'cs.com/Medias/FETCH_RELATED_MEDIA';
export const FETCH_RELATED_MEDIA_SUCCESS =
  'cs.com/Medias/FETCH_RELATED_MEDIA_SUCCESS';
export const UPDATE_PROGRESS = 'cs.com/Medias/UPDATE_PROGRESS';

export const FETCH_HISTORY = 'cs.com/Medias/FETCH_HISTORY';
export const FETCH_HISTORY_SUCCESS = 'cs.com/Medias/FETCH_HISTORY_SUCCESS';

export const FETCH_WATCHING = 'cs.com/Medias/FETCH_WATCHING';
export const FETCH_WATCHING_SUCCESS = 'cs.com/Medias/FETCH_WATCHING_SUCCESS';

export const FETCH_VIDEO = 'cs.com/Medias/FETCH_VIDEO';
export const FETCH_VIDEO_SUCCESS = 'cs.com/Medias/FETCH_VIDEO_SUCCESS';
export const FETCH_VIDEO_FAILURE = 'cs.com/Medias/FETCH_VIDEO_FAILURE';

export const FETCH_RECOMMENDED = 'cs.com/Medias/FETCH_RECOMMENDED';
export const FETCH_RECOMMENDED_SUCCESS =
  'cs.com/Medias/FETCH_RECOMMENDED_SUCCESS';

export const FETCH_RECENTLY_ADDED = 'cs.com/Medias/FETCH_RECENTLY_ADDED';
export const FETCH_RECENTLY_ADDED_SUCCESS =
  'cs.com/Medias/FETCH_RECENTLY_ADDED_SUCCESS';

// reducer

const arrToObj = arr =>
  arr.reduce((o, curVal) => {
    o[curVal.key] = curVal;
    return o;
  }, {});

export const defaultState = {
  relatedMedias: {}
};

const addMediasToState = (state, medias, seriesSize, replace) => {
  const newState = {
    ...state
  };
  for (const media of medias) {
    if (!newState[media.key] || !replace) {
      newState[media.key] = media;
    }
  }
  return newState;
};

const medias = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_VIDEO:
    case FETCH_WATCHING:
    case FETCH_HISTORY:
      return {
        ...state,
        fetching: true,
        error: null
      };

    case FETCH_BOOKMARKED:
      return {
        ...state,
        fetchingBookmarked: true,
        error: null
      };

    case FETCH_RELATED_MEDIA:
      return {
        ...state,
        fetchingRelated: true,
        error: null
      };

    case SIGN_OUT:
      const encodingClearedState = {};
      for (const itemId in state) {
        if (state[itemId]) {
          if (state[itemId].encodings) {
            encodingClearedState[itemId] = {
              ...state[itemId],
              encodings: null
            };
          } else {
            encodingClearedState[itemId] = state[itemId];
          }
        }
      }
      return {
        ...encodingClearedState,
        bookmarked: null,
        userMedia: null
      };

    case LOGIN_SUCCESS:
      return {
        ...defaultState,
        userMedia: arrToObj(action.userMedia)
      };

    case FETCH_UNTYPED_GROUP_SUCCESS:
    case FETCH_CATEGORY_MEDIAS_SUCCESS:
      return {
        ...addMediasToState(state, action.media.map(mapMedia)),
        fetching: false
      };

    case FETCH_BOOKMARKED_SUCCESS:
      const bookmarkedMedia = action.media.map(m => ({
        ...m,
        is_bookmarked: m.is_collection
      }));
      return {
        ...addMediasToState(state, bookmarkedMedia.map(mapMedia)),
        bookmarked: bookmarkedMedia.map(mapMedia).map(m => m.key),
        fetchingBookmarked: false
      };

    case FETCH_WATCHING_SUCCESS:
      return {
        ...addMediasToState(state, action.media.map(mapMedia)),
        watching: action.media.map(mapMedia).map(m => m.key),
        fetching: false
      };

    case FETCH_HISTORY_SUCCESS:
      return {
        ...addMediasToState(state, action.media.map(mapMedia)),
        history: action.media.map(mapMedia).map(m => m.key),
        fetching: false
      };

    case FETCH_RECENTLY_ADDED_SUCCESS:
      return {
        ...addMediasToState(state, action.media.map(mapMedia)),
        recentlyAdded: action.media.map(mapMedia).map(m => m.key)
      };

    case FETCH_RECOMMENDED_SUCCESS:
      return {
        ...addMediasToState(state, action.media.map(mapMedia)),
        userRecommended: action.media.map(mapMedia).map(m => m.key)
      };

    case CLEARVOTE_MEDIA:
      return {
        ...state,
        userMedia: {
          ...state.userMedia,
          [`media_${action.videoId}`]: {
            ...state.userMedia[`media_${action.videoId}`],
            rating: null
          }
        }
      };

    case UPVOTE_MEDIA:
      return {
        ...state,
        userMedia: {
          ...state.userMedia,
          [`media_${action.videoId}`]: {
            ...state.userMedia[`media_${action.videoId}`],
            rating: 5
          }
        }
      };

    case DOWNVOTE_MEDIA:
      return {
        ...state,
        userMedia: {
          ...state.userMedia,
          [`media_${action.videoId}`]: {
            ...state.userMedia[`media_${action.videoId}`],
            rating: 1
          }
        }
      };

    case BOOKMARK_MEDIA:
      let bookmarked = state.bookmarked || [];
      if (action.bookmark) {
        bookmarked = [...bookmarked, action.mediaKey];
      } else {
        bookmarked = bookmarked.filter(a => a !== action.mediaKey);
      }
      return {
        ...state,
        bookmarked,
        userMedia: {
          ...state.userMedia,
          [action.mediaKey]: {
            ...state.userMedia[action.mediaKey],
            isBookmarked: action.bookmark
          }
        }
      };

    case FETCH_HOME_FEED_SUCCESS:
      const homeFeedMedia = action.groups.reduce(
        (prev, group) => [...prev, ...group.media.map(mapMedia)],
        []
      );
      return {
        ...addMediasToState(state, homeFeedMedia)
      };

    case FETCH_VIDEO_SUCCESS:
      const video = {
        ...action.media,
        // adds empty user_media when individually fetched
        user_media: action.media.user_media || {},
        // indicate media object is fully fetched
        is_fully_fetched: true
      };
      return {
        ...addMediasToState(state, [mapMedia(video)]),
        fetching: false
      };

    case FETCH_RELATED_MEDIA_SUCCESS:
    case FETCH_RELATED_SUCCESS:
      const relatedVideos = arrToObj(action.relatedVideos.map(mapMedia));
      const newState = {
        ...state,
        fetchingRelated: false,
        ...relatedVideos
      };
      if (action.videoId) {
        newState.relatedMedias[
          `media_${action.videoId}`
        ] = action.relatedVideos.map(mapMedia).map(m => m.key);
      }
      return newState;

    case FETCH_SERIES:
      return {
        ...state,
        fetchingSeries: true
      };

    case FETCH_SERIES_SUCCESS:
      const series = mapMedia({
        ...action.series[0],
        // indicate media object is fully fetched
        is_fully_fetched: true
      });
      const seriesMedia = arrToObj(action.series[0].media.map(mapMedia));
      return {
        ...state,
        [series.key]: series,
        ...seriesMedia,
        fetchingSeries: false
      };

    case FETCH_COLLECTION_SUCCESS:
      const medias = action.collection.media || [];
      return {
        ...addMediasToState(state, medias.map(mapMedia)),
        fetchingSeries: false
      };

    case FETCH_COLLECTIONS_SUCCESS:
      const media = action.collections.reduce(
        (prev, coll) => [...prev, ...(coll.media || [])],
        []
      );
      return {
        ...addMediasToState(state, media.map(mapMedia))
      };

    case FETCH_VIDEO_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error
      };

    case UPDATE_PROGRESS:
      return {
        ...state,
        userMedia: {
          ...state.userMedia,
          [`media_${action.videoId}`]: {
            ...state.userMedia[`media_${action.videoId}`],
            progressInSeconds: action.progress,
            progressPercentage: action.progressPercentage
          }
        }
      };

    case UPDATE_DISPLAYED_VIDEO_RATING:
      const updatedRatingVideo = {
        ...state[`media_${action.videoId}`],
        ratingsCount: action.ratingCount,
        rating: Math.round(action.ratingPercentage)
      };
      return {
        ...state,
        [`media_${action.videoId}`]: updatedRatingVideo
      };

    default:
      return state;
  }
};

export default medias;

// actions creators

export const updateRating = ({ videoId, ratingCount, ratingPercentage }) => ({
  type: UPDATE_DISPLAYED_VIDEO_RATING,
  videoId,
  ratingCount,
  ratingPercentage
});

export const updateProgress = ({ videoId, progress, progressPercentage }) => ({
  type: UPDATE_PROGRESS,
  videoId,
  progress,
  progressPercentage
});

export const fetchVideo = (videoId, forceCDN) => ({
  type: FETCH_VIDEO,
  videoId,
  forceCDN
});

export const fetchHistory = () => ({
  type: FETCH_HISTORY
});

export const fetchWatching = () => ({
  type: FETCH_WATCHING
});

export const gotVideo = media => ({
  type: FETCH_VIDEO_SUCCESS,
  media
});

export const gotHistory = media => ({
  type: FETCH_HISTORY_SUCCESS,
  media
});

export const gotBookmarked = media => ({
  type: FETCH_BOOKMARKED_SUCCESS,
  media
});

export const gotWatching = media => ({
  type: FETCH_WATCHING_SUCCESS,
  media
});

export const upvoteMedia = (videoId, title) => ({
  type: UPVOTE_MEDIA,
  title,
  videoId
});

export const downvoteMedia = (videoId, title) => ({
  type: DOWNVOTE_MEDIA,
  title,
  videoId
});

export const clearvoteMedia = videoId => ({
  type: CLEARVOTE_MEDIA,
  videoId
});

export const bookmarkMedia = (mediaKey, mediaTitle, bookmark) => ({
  type: BOOKMARK_MEDIA,
  mediaKey,
  mediaTitle,
  bookmark
});

export const failedVideo = err => {
  // need to do this crazy stringify to actually serialize error objects
  // (otherwise we just get an empty object when we store it)
  // https://stackoverflow.com/a/26199752
  const error = JSON.stringify(err, Object.getOwnPropertyNames(err));

  return {
    type: FETCH_VIDEO_FAILURE,
    error
  };
};

export const fetchRelatedVideos = videoId => ({
  type: FETCH_RELATED_MEDIA,
  videoId
});

export const fetchBookmarked = () => ({
  type: FETCH_BOOKMARKED
});

export const gotRelatedVideos = (videoId, relatedVideos) => ({
  type: FETCH_RELATED_MEDIA_SUCCESS,
  videoId,
  relatedVideos
});

export const fetchRecommended = () => ({
  type: FETCH_RECOMMENDED
});

export const fetchRecentlyAdded = () => ({
  type: FETCH_RECENTLY_ADDED
});

export const gotRecentlyAdded = media => ({
  type: FETCH_RECENTLY_ADDED_SUCCESS,
  media
});

export const gotRecommended = media => ({
  type: FETCH_RECOMMENDED_SUCCESS,
  media
});

// selectors

const getMedias = state => state.medias;

const getUserMedias = state => getMedias(state).userMedia;

const getRelatedMedias = state => getMedias(state).relatedMedias;

export const getUserMedia = state => key =>
  getUserMedias(state) ? getUserMedias(state)[key] : null;

export const getSuggestedResumeTimeAndPercentage = state => key => {
  if (getUserMedias(state) && getUserMedias(state)[key]) {
    const { progressPercentage } = getUserMedias(state)[key];
    if (progressPercentage > 0) {
      const media = getMedias(state)[key];
      if (!media) return {progressPercentage};
      const progressInSeconds = getUserMedias(state)[key].progressInSeconds;
      const timeLeft = secondsToHoursAndMinutes(
        media.duration - progressInSeconds
      );
      return {
        progressInSeconds,
        progressCaption: media
          ? progressPercentage > 95 || timeLeft === '0min'
            ? secondsToHoursAndMinutes(media.duration)
            : `${timeLeft} of ${secondsToHoursAndMinutes(media.duration)} left`
          : null,
        progressPercentage
      };
    }
  }

  return {
    progressInSeconds: 0,
    progressPercentage: 0
  };
};

export const getIsMediaBookmarked = state => key =>
  getUserMedia(state)(key) && getUserMedia(state)(key).isBookmarked;

export const getMediaRating = state => key =>
  getUserMedia(state)(key) && getUserMedia(state)(key).rating;

export const getFetchingMedia = state => getMedias(state).fetching;

export const getFetchingBookmarked = state =>
  getMedias(state).fetchingBookmarked;

export const getFetchingRelated = state => getMedias(state).fetchingRelated;

export const getFetchingEpisodes = state => getMedias(state).fetchingSeries;

export const getMediaByKey = state => key => getMedias(state)[key];

export const getRelatedVideosForId = state => id =>
  getRelatedMedias(state)[`media_${id}`] &&
  getRelatedMedias(state)[`media_${id}`].map(getMediaByKey(state));

export const getFetchingError = state => getMedias(state).error;

export const getRecommended = state =>
  getMedias(state).userRecommended &&
  getMedias(state).userRecommended.map(getMediaByKey(state));

export const getRecentlyAdded = state =>
  getMedias(state).recentlyAdded &&
  getMedias(state).recentlyAdded.map(getMediaByKey(state));

export const bookmarkedMedia = state =>
  getMedias(state).bookmarked &&
  getMedias(state).bookmarked.map(getMediaByKey(state));

export const historyMedia = state =>
  getMedias(state).history &&
  getMedias(state).history.map(getMediaByKey(state));

export const currentlyWatchingMedia = state =>
  getMedias(state).watching &&
  getMedias(state).watching.map(getMediaByKey(state));

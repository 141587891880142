import React from 'react';
import { Route } from 'react-router';

import Text from 'components/Text';
import Button from 'components/Button';

import styles from './styles.module.css';

export default ({ style, showButton }) => (
  <Route
    children={({ history }) => (
      <div
        className={styles.preview}
        style={{ ...style, opacity: 1, visibility: 'visible' }}
      >
        <Text large  style={{ opacity: showButton ? 1 : 0 }}>This is a 2 min. preview</Text>
        <Button
          style={{ opacity: showButton ? 1 : 0 }}
          onClick={() => history.replace('/login')}
        >
          <Text large noMargin>
            Log in for full episode
          </Text>
        </Button>
      </div>
    )}
  />
);

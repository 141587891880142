import React from 'react';

import { dNav } from 'config';
import { withConfig } from 'hoc';

import styles from './styles.module.css';

class Modal extends React.Component {
  componentDidMount() {
    if (dNav) dNav.focusRoot = this.root;

    this.prevFocus = document.activeElement;
    this.prevFocus && this.prevFocus.blur();
  }

  componentWillUnmount() {
    if (dNav) dNav.focusRoot = null;

    // the following adds a delay to focus prev focused element since it is collapsed on
    // focus-broken devices.
    if (this.props.config.focusRootIsBroken) {
      setTimeout(() => this.prevFocus && this.prevFocus.focus(), 200);
    } else {
      this.prevFocus && this.prevFocus.focus();
    }
  }

  render() {
    const { id, children, onClick, config } = this.props;

    return (
      <div
        id={`modal-${id || ''}`}
        onClick={onClick}
        className={styles.root + ' ' + styles.startFadein}
      >
        <div
          ref={node => (this.root = node)}
          className={styles.window}
          style={
            config.isPresto && {
              margin: '10% auto'
            }
          }
        >
          {children}
        </div>
      </div>
    );
  }
}

export default withConfig(Modal);

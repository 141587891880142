import React, { useState, useRef, useEffect } from 'react';
import Modal from 'components/Modal';
import TTS from 'components/TTSFocusableDescription';
import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import Keyboard from 'components/Keyboard';
import { getConfig } from 'config';
import { session } from 'utils/tokenManager';
import request from 'utils/request';

import styles from './styles.module.css';

export default ({ onClose }) => {
  const config = getConfig();
  const [step, setStep] = useState(0);
  const realEmail = useRef();
  const submitButton = useRef();
  const [email, setEmail] = useState('');
  const [showKeyboard, setShowKeyboard] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    realEmail.current = email;
  }, [email]);

  const onKeyDown = e => {
    if (config.isReturnKey(e.keyCode) && !showKeyboard) {
      if (step === 1) setStep(0);
      else onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  });

  const submitEmail = async () => {
    const response = await request(
      `${process.env.REACT_APP_API_ROOT}/vizio/freeviewing`,
      {
        skipResponseHandling: true,
        method: 'POST',
        body: { email }
      }
    );

    const data = await response.json();

    if (data.error) {
      let message;
      if (data.error.message) {
        message = data.error.message;
        if (message === `The email has already been taken.`)
          message += ` You can log in the app via the "Log In" button.`;

        setErrorMessage(message);
      } else
        setErrorMessage(
          `An error happened. Please check the email you entered.`
        );
    } else {
      session.set(data.sessionToken);
      config.hasFreeAccess = true;
      setStep(2);
    }
  };

  return (
    <Modal id="free-month">
      {step === 0 && (
        <>
          <TTS focus>
            <Text title>Free Month March!</Text>
            <Text large>
              Enjoy free and unlimited viewing on your VIZIO SmartCast TV for
              all of March 2020. Just enter your email address and start
              watching.
            </Text>
          </TTS>
          <Button
            className={styles.modalButton}
            focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
            onClick={() => setStep(1)}
          >
            I want the Free Preview!
          </Button>
          <div style={{ width: '.5em', display: 'inline-block' }} />
          <Button className={styles.modalButton} onClick={onClose}>
            I already have an account
          </Button>
        </>
      )}
      {step === 1 && (
        <>
          <TTS focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}>
            <Text title>Enter your email address below:</Text>
          </TTS>
          <Input
            initialFocus
            type="text"
            width="80%"
            value={email}
            onChange={e => {
              setErrorMessage();
              setEmail(e.target.value);
            }}
            keyDown={e => {
              if (
                e.keyCode === config.keyCodes.enter &&
                config.needsVirtualKeyboard
              ) {
                setShowKeyboard(true);
              }
            }}
          />
          <div style={{ minHeight: `3rem` }}>
            <Text style={{ color: 'red' }}>{errorMessage}</Text>
          </div>
          <Button
            onLoad={ref => (submitButton.current = ref)}
            className={styles.modalButton}
            onClick={() => submitEmail()}
          >
            Submit
          </Button>
          {showKeyboard && (
            <Keyboard
              onClose={() => {
                setShowKeyboard(null);
                submitButton.current.focus();
              }}
              onEnter={() => {
                setShowKeyboard(null);
                submitButton.current.focus();
                submitEmail();
              }}
              onErase={() => {
                if (email.length === 0) {
                  setShowKeyboard(null);
                  submitButton.current.focus();
                } else {
                  const newInputValue = email.slice(0, email.length - 1);
                  setEmail(newInputValue);
                }
              }}
              onKeyStroke={letter => {
                const newInputValue = realEmail.current + letter;
                setEmail(newInputValue);
              }}
            />
          )}
        </>
      )}
      {step === 2 && (
        <>
          <Text title>All done!</Text>
          <Text>You can now watch for free until March 31, 2020.</Text>
          <Button
            focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
            className={styles.modalButton}
            onClick={() => onClose(true)}
          >
            Watch now
          </Button>
        </>
      )}
    </Modal>
  );
};

import React from 'react';

const Pause = props => (
  <svg width={21} height={21} viewBox="0 0 21 21" {...props}>
    <rect x={10.34} y={1} width={5.28} height={19.49} rx={1.5} />
    <rect x={1} y={1} width={5.28} height={19.49} rx={1.5} />
  </svg>
);

export default Pause;

import React from 'react';

const Logo = props => (
  <svg viewBox="0 0 216 216" {...props}>
    <ellipse ry={70} rx={70} cy={110} cx={110} fill="#fff" />
    <path
      fill="#fba60b"
      d="M108 19.4c-48.9 0-88.6 39.7-88.6 88.6s39.7 88.6 88.6 88.6c48.9 0 88.6-39.7 88.6-88.6S156.9 19.4 108 19.4zm-.1 147c-4.7 0-8.5-3.8-8.5-8.5s3.8-8.5 8.5-8.5 8.5 3.8 8.5 8.5c-.1 4.7-3.9 8.5-8.5 8.5zm23.3-57.9c0 9.3-6.5 17.3-15.5 20.1v15.7h-15.5v-15.7c-9-2.8-15.3-10.8-15.3-20.1V58.6h15.3v49.9c0 3.9 3.6 7.1 7.8 7.1 4.3 0 7.7-3.1 7.7-7.1V58.6h15.5v49.9z"
    />
  </svg>
);

export default Logo;

import { takeLatest, call, put } from 'redux-saga/effects';

import imageQueryBuilder from 'utils/imageQueryBuilder';

import {
  FETCH_CATEGORY_MEDIAS,
  FETCH_CATEGORIES,
  FETCH_FULL_CATEGORY,
  gotCategories,
  gotCategoryMedia,
  failedFetch,
  gotFeaturedItem
} from './reducer';
import request from 'utils/request';

export const categoriesUrl = `${process.env.REACT_APP_API_ROOT}/v1/categories`;
export const userCategoriesUrl = `${
  process.env.REACT_APP_API_ROOT
}/v1/media/user_categories`;
export const categoryMediaUrl = `${
  process.env.REACT_APP_API_ROOT
}/v1/media?filterBy=category&limit=12&collections=true&${imageQueryBuilder()}`;
export const browseFeatured = `${
  process.env.REACT_APP_API_ROOT
}/v2/featured/browse`;
export const fullCategoryUrl = id =>
  `${process.env.REACT_APP_API_ROOT}/v1/categories/${id}/browse`;

export function* fetchCategoriesData() {
  try {
    const data = yield call(request, categoriesUrl);
    const categories = data.data;
    yield put(gotCategories(categories));
  } catch (e) {
    console.error('error in fetchCategoriesData', e);
  }
}

export function* fetchFullMainCategory(action) {
  try {
    const data = yield call(request, fullCategoryUrl(action.categoryId));
    const subcategories = data.data;
    for (const subcat of subcategories) {
      yield put(gotCategoryMedia(subcat.id, 1, subcat.media.data, false));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* fetchCategoryMedia(action) {
  try {
    const termQuery = `&term=${action.category.name}`;
    const page = action.page;
    const pageQuery = `&page=${page}`;

    const result = yield call(
      request,
      categoryMediaUrl + termQuery + pageQuery
    );

    const medias = result.data;
    const isLastPage =
      result.paginator.total_pages === result.paginator.current_page;
    yield put(gotCategoryMedia(action.category.id, page, medias, isLastPage));
  } catch (e) {
    yield put(failedFetch(e.message));
  }
}

export function* fetchFeaturedItems() {
  try {
    const data = yield call(request, browseFeatured);
    yield put(gotFeaturedItem(data[0]));
  } catch (e) {
    yield put(failedFetch(e.message));
  }
}

export function* getCategoriesData() {
  yield takeLatest(FETCH_CATEGORIES, fetchCategoriesData);
}

export function* getCategoryMedia() {
  yield takeLatest(FETCH_CATEGORY_MEDIAS, fetchCategoryMedia);
}

export function* getFullCategory() {
  yield takeLatest(FETCH_FULL_CATEGORY, fetchFullMainCategory);
}

export default [
  getCategoriesData,
  getCategoryMedia,
  getFullCategory,
  fetchFeaturedItems
];

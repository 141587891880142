import React from 'react';
import styles from './styles.module.css';

export default ({ topLabel, style, seriesTitle, title, upNext }) => {
  return (
    <div className={styles.topDarken} style={style}>
      <div className={styles.topLeft}>
        {topLabel && <>{topLabel} </>}
        {seriesTitle && (
          <div className={styles.seriesTitle}> {seriesTitle}</div>
        )}
        <div className={styles.title}>{title}</div>
        {upNext && (
          <>
            Up next:
            <br />
            {upNext.seriesTitle && (
              <div className={styles.upNext}>{upNext.seriesTitle}</div>
            )}
            <div className={styles.upNext}>{upNext.title}</div>
          </>
        )}
      </div>
    </div>
  );
};

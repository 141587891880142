import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import logger from 'utils/logger';
import packageJson from '../../../package.json';
import { getUserData } from 'reducers/Profile/reducer';
import { withConfig, withAuth } from 'hoc';
import Spinner from 'components/Spinner';
import request from 'utils/request';
import { say } from 'utils/speechSynthesis';
import { fireEvent } from 'utils/analytics';
import braze from 'utils/braze';
import { searchAnalyticsConversionEvent } from 'utils/algolia';
import ConvivaIntegration from 'utils/conviva';

import {
  getSelectedCc,
  updateCcSettings,
  initSubtitlesOptions,
  getPlayerCcSettings,
} from 'reducers/PlayerSettings/reducer';

import { updateProgress } from 'reducers/Medias/reducer';

import Overlay from './Overlay';
import VTT from './VTT';
import ClosedCaptionsMenu from './ClosedCaptionsMenu';
import styles from './styles.module.css';

import { withRouter } from 'react-router';
import AudioTracksMenu from './AudioTracksMenu';
import { mapConnectionType } from 'utils/mapper';

export const CONTROLS_FADEOUT_TIME = 10000;

const secondsToFullSpeech = secondsIn => {
  const hours = ~~(secondsIn / 60 / 60);
  const minutes = ~~((secondsIn - hours * 60 * 60) / 60);
  const seconds = ~~(secondsIn - hours * 60 * 60 - minutes * 60);

  let speech = 'resuming at ';
  if (hours > 0) speech += `${hours} hours`;
  if (minutes > 0) speech += ` ${minutes} minutes`;
  if (seconds > 0) speech += ` ${seconds} seconds`;

  return speech;
};

const loadScript = src => {
  return new Promise(success => {
    let scriptTag = document.createElement('script');

    scriptTag.onload = success;
    scriptTag.setAttribute('src', src);
    scriptTag.setAttribute('type', 'text/javascript');
    scriptTag.setAttribute('async', 'false');
    document.head && document.head.appendChild(scriptTag);
  });
};

export class Player extends React.Component {
  state = {
    lastBitrateReported: null,
    show: false,
    ccIndex: 0,
    focusedButton: null,
    bufferingCount: 0,
    progressEventsSent: [],
    audioTracks: [],
    selectedAudioTrack: null,
    totalBufferingDuration: 0,
    totalPlaybackDuration: 0,
    showAudioOptions: null,
  };

  get log() {
    return logger.extend(`player:${this.playerTech || 'html'}`);
  }

  get analyticsConfig() {
    const { videoId, encoding, isLogged, config, seriesTitle, title } =
      this.props;

    if (!isLogged) return null;

    return {
      key: '11c43a63-c6fd-46b8-ab2a-c6e931b4943b',
      videoId,
      userId: this.props.userData && this.props.userData.referralId,
      title: seriesTitle ? `${seriesTitle} / ${title}` : title,
      cdnProvider: encoding.playback.cdn,
      customData1: isLogged ? 'logged' : 'guest',
      customData2: config.name,
    };
  }

  componentWillUnmount() {
    this.sendProgressToApi();
    this.convivaVideoAnalytics &&
      this.convivaVideoAnalytics.reportPlaybackEnded();
    this.timeLeftInterval && clearInterval(this.timeLeftInterval);
    this.bufferingCheckInterval && clearInterval(this.bufferingCheckInterval);
    clearTimeout(this.saveInfoTimeout);
    clearTimeout(this.controlsTimeout);
    clearTimeout(this.attachMouseClickEventListener);
    clearTimeout(this.onCanPlayTimeout);
    clearInterval(this.timerUpdate);
    clearInterval(this.progressEventInterval);
    clearInterval(this.performanceUpdateInterval);
    clearInterval(this.bitrateReportInterval);
  }

  async componentDidMount() {
    const {
      closedCaptions,
      initSubtitlesOptions,
      config,
      updateCcSettings,
      onTimeLeft,
      timeToShowNext,
    } = this.props;
    try {
      this.initializeConviva();
    } catch (e) {
      console.error('error while initializing conviva', e);
    }

    if (this.playerTech === 'dash.js' && !window.dashjs)
      // do not upgrade to V4.0.*. It introduces core api changes which breaks captions
      await loadScript(
        `https://cdn.curiositystream.com/assets/dashjs/v3.2.2/dash.all.min.js`
      );

    // if (this.playerTech === 'hls.js')
    //   await loadScript(`https://cdn.jsdelivr.net/npm/hls.js@latest`);

    if (this.playerTech === 'shaka' && !window.shaka) {
      await loadScript(
        'https://cdn.jsdelivr.net/npm/shaka-player@3.0.6/dist/shaka-player.compiled.debug.min.js'
      );
      await window.shaka.polyfill.installAll();
    }

    if (this.playerTech === 'samsung')
      import(/* webpackChunkName: "SamsungPlayer" */ './SamsungPlayer').then(
        module => {
          this.setState({ playerComponent: module.default });
        }
      );
    else if (this.playerTech === 'tizen')
      import(/* webpackChunkName: "TizenPlayer" */ './TizenPlayer').then(
        module => {
          this.setState({ playerComponent: module.default });
        }
      );
    else if (this.playerTech === 'vizio')
      import(/* webpackChunkName: "VizioPlayer" */ './VizioPlayer').then(
        module => {
          this.setState({ playerComponent: module.default });
        }
      );
    else if (this.playerTech === 'dash.js')
      import(/* webpackChunkName: "DashPlayer" */ './DashPlayer').then(
        module => {
          this.setState({ playerComponent: module.default });
        }
      );
    else if (this.playerTech === 'hls.js')
      import(/* webpackChunkName: "HlsPlayer" */ './HlsPlayer').then(module => {
        this.setState({ playerComponent: module.default });
      });
    else if (this.playerTech === 'shaka')
      import(/* webpackChunkName: "ShakaPlayer" */ './ShakaPlayer').then(
        module => {
          this.setState({ playerComponent: module.default });
        }
      );

    this.timeLeftInterval = setInterval(() => {
      const currentPlayerTime = this.player?.time ?? this.player.currentTime;
      if (onTimeLeft && currentPlayerTime)
        if (
          this.player &&
          this.state.duration - currentPlayerTime - 1 <= timeToShowNext
        ) {
          // time is crashing Vizio TVs
          onTimeLeft(this.state.duration - currentPlayerTime - 1);
        } else {
          onTimeLeft(null);
        }
    }, 1000);

    this.bufferingCheckInterval = setInterval(() => {
      const videoSeemsToHaveStartedPlaying =
        this.videoCurrentTime - this.state.bufferingStartTime > 1;

      console.log(this.videoCurrentTime, this.state.bufferingStartTime);

      if (this.state.buffering && videoSeemsToHaveStartedPlaying) {
        this.setState({
          buffering: false,
          bufferingStartTime: null,
        });
      }

      if (this.state.buffering && !videoSeemsToHaveStartedPlaying) {
        // this.player.currentTime += (Math.random() - 0.5) / 10;
        this.play();
      }
    }, 1000);

    if (config.onNetworkChanged) {
      config.onNetworkChanged(result => {
        clearTimeout(this.networkPopupTimer);
        this.networkPopupTimer = setTimeout(() => {
          // we might want to add a check on this.isPaused to know if we should
          // resume playback or not
          if (this.state.show) {
            if (!this.state.isOnline && result.online) this.play();
            if (this.state.isOnline && !result.online) this.pause();
          }

          this.setState({
            isOnline: result.online,
          });
        }, 5000);
      });
    }

    window.addEventListener('keydown', this.keyDown);
    window.addEventListener('onbeforeunload', this.closePlayer);
    this.attachMouseClickEventListener = setTimeout(
      () => window.addEventListener('click', this.mouseClick),
      1000
    );

    const cc = [{ language: 'off' }].concat(closedCaptions || []);

    initSubtitlesOptions(cc);

    if (config.onClosedCaptionsChange) {
      // syncs cc toggle with environment
      config.onClosedCaptionsChange(enabled => {
        updateCcSettings('Subtitles', null, enabled ? 1 : 0);
      });
    }

    const timestamp = Date.now();

    this.setState({ initTimestamp: timestamp });

    if (this.isOnNowUx) {
      this.showControl('gotoshow');
    }
  }

  get playerTech() {
    const shakaUsers = [
      `djme11@yahoo.com`, // devin e.
      `rokudev+test123@curiositystream.com`, // cs
      `matthew.langlois.usa@gmail.com`,
      `Robertssmith2018@gmail.com`,
      `shastina@cot.net`,
      `truman@tystevens.org`,
      `Oldgreyoak@aol.com`,
      `real.jon.kraus@gmail.com`,
    ];

    return shakaUsers.includes(this.props?.userData?.email)
      ? 'shaka'
      : this.props.config.player;
  }

  get jumpDuration() {
    return this.state.duration < 30 * 60 ? 10 : 30;
  }

  get videoCurrentTime() {
    if (!this.player) {
      return 0;
    }

    const currentTime = this.state.bufferedTimePos || this.player.currentTime;

    return currentTime;
  }

  sendProgressEvent = () => {
    const { duration, progressEventsSent } = this.state;

    const progressPercentage = (this.videoCurrentTime / duration) * 100;

    const timePlayed =
      this.videoCurrentTime -
      (this.props.resumeTime ? this.props.resumeTime.progressInSeconds : 0);

    if (
      duration > 150 &&
      timePlayed >= 120 &&
      !progressEventsSent.includes('video_playback_view_2min')
    ) {
      progressEventsSent.push('video_playback_view_2min');

      // the following will be sent if the playback results from a search
      searchAnalyticsConversionEvent({
        videoId: this.props.videoId,
        eventName: 'watch_conversion_2min_smart_tv',
      });
    }

    if (
      duration <= 150 &&
      progressPercentage >= 70 &&
      !progressEventsSent.includes('watch_conversion_short')
    ) {
      progressEventsSent.push('watch_conversion_short');

      // the following will be sent if the playback results from a search
      searchAnalyticsConversionEvent({
        videoId: this.props.videoId,
        eventName: 'watch_conversion_short_smart_tv',
      });
    }
  };

  sendProgressToApi = () => {
    const currentTime = this.videoCurrentTime;

    if (this.props.isLogged || this.props.config.hasFreeAccess) {
      try {
        const progressPercentage = (currentTime / this.props.duration) * 100;
        const body = {
          media_id: this.props.videoId,
          progress_in_seconds: currentTime,
          progressPercentage,
        };

        // sends progress to api for further resume function
        request(`${process.env.REACT_APP_API_ROOT}/v1/user_media`, {
          method: 'POST',
          body,
        });

        if (this.props.isLogged)
          this.props.updateProgress({
            videoId: this.props.videoId,
            progress: currentTime,
            progressPercentage,
          });
      } catch (e) {
        //not a biggie
        console.error(e);
      }
    }
  };

  componentDidCatch(error, info) {
    try {
      this.convivaVideoAnalytics &&
        this.convivaVideoAnalytics.reportPlaybackFailed(
          `${error} ${JSON.stringify(info)}`
        );
      this.fireEvent('video_playback_crash', {
        media_id: this.props.videoId,
        error,
        info,
      });
      this.sendProgressToApi();
    } catch (e) {
      // sending this bug to the land of the forgotten
    }
  }

  mouseClick = e => {
    this.showControl(this.state.focusedButton || 'playpauseButton');
  };

  closePlayer = videoDidEndByItself => {
    clearInterval(this.timerUpdate);

    window.removeEventListener('keydown', this.keyDown);
    window.removeEventListener('click', this.mouseClick);
    window.removeEventListener('onbeforeunload', this.closePlayer);

    clearTimeout(this.attachMouseClickEventListener);
    clearTimeout(this.saveInfoTimeout);
    clearTimeout(this.controlsTimeout);
    clearInterval(this.progressEventInterval);
    clearInterval(this.performanceUpdateInterval);
    clearInterval(this.bitrateReportInterval);

    this.props.onClosePlayer && this.props.onClosePlayer(videoDidEndByItself);
  };

  keyDown = e => {
    this.log('keydown', e.keyCode);

    const { config } = this.props;
    const { getIsTTSEnabled } = config;

    if (e.keyCode === config.keyCodes.exit) return;

    this.setState({
      keyCode: e.keyCode,
    });

    if (
      config.muteNonMappedKeys &&
      !Object.values(config.keyCodes).includes(e.keyCode)
    )
      return;

    if (config.isReturnKey(e.keyCode)) {
      this.state.showCcOptions
        ? this.setState({ showCcOptions: null })
        : this.state.showAudioOptions
        ? this.setState({ showAudioOptions: null })
        : this.state.focusedButton !== null
        ? this.showControl(null)
        : this.state.buffering && config.name === 'tizen'
        ? console.this.log('oh')
        : this.closePlayer();
      return;
    }

    if (!this.state.show) return;

    if (e.keyCode === config.keyCodes.closedCaptions) {
      this.toggleCc();
      return;
    }

    if (this.player && this.videoCurrentTime) {
      if (
        e.keyCode === config.keyCodes.playpause &&
        config.playPauseIsAlsoEnter &&
        !this.state.showCcOptions &&
        !document.getElementById(`modal-exit`)
      ) {
        if (this.isPaused) {
          this.play();
        } else {
          this.pause();
        }
      } else if (e.keyCode === config.keyCodes.rewind) {
        this.jumpBack();
        this.showControl('scrubber');
      } else if (e.keyCode === config.keyCodes.fastForward) {
        this.jumpForward();
        this.showControl('scrubber');
      } else if (
        e.keyCode === config.keyCodes.playpause &&
        !config.playPauseIsAlsoEnter
      ) {
        if (this.isPaused) {
          this.play();
          this.showControl('playpauseButton');
        } else {
          this.pause();
          this.showControl('playpauseButton');
        }
      } else if (e.keyCode === config.keyCodes.play) {
        this.play();
        this.showControl('playpauseButton');
      } else if (e.keyCode === config.keyCodes.pause) {
        this.pause();
        this.showControl('playpauseButton');
      } else if (e.keyCode === config.keyCodes.stop) {
        if (getIsTTSEnabled()) {
          say('stop');
          setTimeout(() => {
            this.closePlayer();
          }, 350);
        } else {
          this.closePlayer();
        }
      } else {
        if (!this.state.focusedButton) {
          this.showControl('playpauseButton');
        } else {
          this.showControl('addTime');
        }
      }
    }
  };

  play = () => {
    if (this.state.paused && this.props.config.getIsTTSEnabled()) {
      setTimeout(() => {
        const speech = secondsToFullSpeech(this.videoCurrentTime);
        say(speech);
      }, 500);
    }

    this.player.play();
  };

  pause = () => {
    if (this.isOnNowUx) return;
    this.player.pause();
  };

  showControl = (buttonToFocus = true) => {
    const { hasBrokenTTSSupport, getIsTTSEnabled } = this.props.config;

    if (!this.player) return;

    if (hasBrokenTTSSupport && getIsTTSEnabled()) {
      // needed so TTS says the focused button
      this.setState({ focusedButton: 'playpauseButton' });
    }

    if (buttonToFocus === 'addTime') {
      // needed when user seeks progressbar
      clearTimeout(this.controlsTimeout);
      this.controlsTimeout = setTimeout(() => {
        this.setState({ focusedButton: null });
        clearInterval(this.timerUpdate);
      }, CONTROLS_FADEOUT_TIME);

      return;
    }

    this.setState({ focusedButton: buttonToFocus });

    this.setState({
      currentTime: this.videoCurrentTime,
      duration: this.isFunction(this.player.duration)
        ? this.player.duration()
        : this.player.duration,
    });

    this.timerUpdate = setInterval(() => {
      if (this.player) {
        this.setState({
          currentTime: this.videoCurrentTime,
          duration: this.isFunction(this.player.duration)
            ? this.player.duration()
            : this.player.duration,
        });
      }
    }, 1000);

    clearTimeout(this.controlsTimeout);
    this.controlsTimeout = setTimeout(() => {
      this.setState({ focusedButton: null });
      clearInterval(this.timerUpdate);
    }, CONTROLS_FADEOUT_TIME);
  };

  isFunction = functionToCheck => {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === '[object Function]'
    );
  };

  get isPaused() {
    return this.state.paused;
  }

  initializeConviva = () => {
    const { config, encoding, videoId, seriesTitle, title } = this.props;

    if (
      !this.convivaVideoAnalytics &&
      (this.playerTech === 'dash.js' || this.playerTech === 'shaka')
    ) {
      new ConvivaIntegration();
      this.convivaVideoAnalytics =
        window.Conviva.Analytics.buildVideoAnalytics();

      const encodingUrl =
        config.forceManifest_TEST_ONLY ||
        encoding.master_playlist_url ||
        encoding.file_url;

      const contentInfo = {
        [window.Conviva.Constants.STREAM_URL]: encodingUrl,
        [window.Conviva.Constants.PLAYER_NAME]: 'smart-tv',
        'c3.app.version': packageJson.version,
        contentLength: `${this.props.duration}`,
        [window.Conviva.Constants.ASSET_NAME]: `[${videoId}] ${
          seriesTitle ? `${seriesTitle} / ${title}` : title
        }`,
        [window.Conviva.Constants.IS_LIVE]:
          window.Conviva.Constants.StreamType.VOD,
        [window.Conviva.Constants.VIEWER_ID]:
          (this.props.userData && this.props.userData.externalId) || 'NA',
        [window.Conviva.Constants.DEFAULT_RESOURCE]: encoding.playback.cdn,
        [window.Conviva.Constants.DURATION]: this.props.duration,
        category: this.props.category,
        'c3.cm.seriesName': this.props.seriesTitle,
        'c3.cm.showTitle': this.props.title,
        'c3.cm.assetId':
          this.props.duration === Infinity ? 'OnNow' : `${this.props.mediaId}`,
        'c3.cm.contentType':
          this.props.duration === Infinity
            ? window.Conviva.Constants.StreamType.LIVE
            : window.Conviva.Constants.StreamType.VOD,
        mediaType: this.props.mediaType,
        cancelledStatus: this.props.isCancelled ? 'Yes' : 'No',
        trialStatus: this.props.isLogged ? 'Subscription' : 'Trial',
        encodingType: this.props.encoding.type,
        // annual plans have _annual postfix
        planTerm: this.props.userPlan?.split('_')[1] ? 'Annual' : 'Monthly',
      };
      const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;
      if (connection) {
        window.Conviva.Analytics.reportDeviceMetric(
          window.Conviva.Constants.Network.CONNECTION_TYPE,
          mapConnectionType(connection.type)
        );
      }
      this.convivaVideoAnalytics.reportPlaybackRequested(contentInfo);
      this.convivaVideoAnalytics.setContentInfo(contentInfo);

      this.convivaVideoAnalytics.setPlayerInfo({
        [window.Conviva.Constants.FRAMEWORK_NAME]: config.player,
        // [window.Conviva.Constants.FRAMEWORK_VERSION]: '3.1.1',
      });
    }
  };

  jumpForward = () => {
    if (this.isOnNowUx) return;
    if (this.state.buffering) return;
    say('fast forward');
    this.moveTimeCursor(this.jumpDuration);
  };

  jumpBack = () => {
    if (this.isOnNowUx) return;
    if (this.state.buffering) return;
    say('rewind');
    this.moveTimeCursor(-this.jumpDuration);
  };

  moveTimeCursor = increment => {
    this.showControl('scrubber');
    clearInterval(this.bufferedTimePosTimeout);
    const bufferedTimePos = Math.max(
      0.01,
      Math.min(this.state.duration, this.videoCurrentTime + increment)
    );
    this.setState({ bufferedTimePos });
    this.bufferedTimePosTimeout = setInterval(
      () => {
        this.jumpToTime(this.state.bufferedTimePos);
        this.setState({ bufferedTimePos: null });
        clearInterval(this.bufferedTimePosTimeout);
      },
      this.props.config.performanceIndex > 5000 ? 3000 : 1000
    );
  };

  jumpToPercent = percent => {
    if (this.isOnNowUx) return;
    const newPos = (this.state.duration * percent) / 100;
    this.jumpToTime(newPos);
  };

  jumpToTime = (destination, force) => {
    if (!force && this.isOnNowUx) return;
    this.setState({ seeking: true }, () => {
      this.convivaVideoAnalytics &&
        this.convivaVideoAnalytics.reportPlaybackMetric(
          window.Conviva.Constants.Playback.SEEK_STARTED
        );
    });
    if (destination >= this.state.duration) {
      this.closePlayer();
    } else {
      if (this.player) {
        try {
          const rangedTimePos = Math.max(0.01, Math.floor(destination));
          this.player.currentTime = rangedTimePos;
        } catch (e) {
          this.setState({ debug: ['error', ...e.stack.split('\n')] });
        }
      }
    }
  };

  onLoadedMetadata = () => {
    const { resumeTime, videoId } = this.props;

    if (resumeTime && this.player) {
      setTimeout(() => {
        if (this.player) {
          this.player.currentTime = resumeTime.progressInSeconds;
        }
      }, 15);
    }
  };

  toggleCc = () => {
    const newCcState =
      !this.props.selectedCc || this.props.selectedCc.label === 'off' ? 1 : 0;

    fireEvent({
      category: 'media_player',
      action: newCcState === 1 ? 'subtitles_enable' : 'subtitles_disable',
      value: this.props.videoId,
    });

    this.props.updateCcSettings('Subtitles', null, newCcState);
  };

  onWaiting = () => {
    this.convivaVideoAnalytics &&
      this.convivaVideoAnalytics.reportPlaybackMetric(
        window.Conviva.Constants.Playback.PLAYER_STATE,
        window.Conviva.Constants.PlayerState.BUFFERING
      );

    if (this.state.seeking) {
      fireEvent({
        category: 'media_player',
        action: 'seek',
        value: this.props.videoId,
      });
    }
    this.log('buffering');

    this.setState({
      buffering: true,
      bufferingStartTime: this.videoCurrentTime,
      bufferingCount: this.state.bufferingCount + 1,
      bufferStartTs: Date.now(),
    });

    clearTimeout(this.controlsTimeout);
  };

  onCanPlay = () => {
    // when buffer is jittery on some TVs
    this.onCanPlayTimeout = setTimeout(() => {
      if (this.state.seeking) {
        if (this.props.config.getIsTTSEnabled()) {
          const speech = secondsToFullSpeech(this.videoCurrentTime);
          say(speech);
        }

        this.convivaVideoAnalytics &&
          this.convivaVideoAnalytics.reportPlaybackMetric(
            window.Conviva.Constants.Playback.SEEK_ENDED
          );
        this.convivaVideoAnalytics &&
          this.convivaVideoAnalytics.reportPlaybackMetric(
            window.Conviva.Constants.Playback.PLAYER_STATE,
            window.Conviva.Constants.PlayerState.PLAYING
          );
      }
      this.setState({
        buffering: false,
        seeking: false,
        show: true,
      });
    }, 250);

    if (this.state.bufferStartTs) {
      this.setState({
        totalBufferingDuration:
          this.state.totalBufferingDuration +
          Date.now() -
          this.state.bufferStartTs,
        bufferStartTs: null,
      });

      this.convivaVideoAnalytics &&
        this.convivaVideoAnalytics.reportPlaybackMetric(
          window.Conviva.Constants.Playback.PLAYER_STATE,
          window.Conviva.Constants.PlayerState.PLAYING
        );
    } else {
      if (this.state.playerInitTimeStamp) {
        const initialBufferingDuration =
          Date.now() - this.state.playerInitTimeStamp;
        this.setState({ initialBufferingDuration });
      }
    }

    this.controlsTimeout = setTimeout(() => {
      this.setState({ focusedButton: null });
      clearInterval(this.timerUpdate);
    }, CONTROLS_FADEOUT_TIME);
  };

  fireEvent = (event, payload) => {
    if (!this.state.lastLogSent !== event) {
      this.setState({ lastLogSent: event });
      braze.logCustomEvent(event, payload);
      fireEvent({
        category: 'video_playback',
        action: event.replace('video_playback_', ''),
        label: this.props.isLogged ? 'preview' : 'content', // do not fix until we update the DB
        property: this.props.videoId,
        value: payload.timestamp,
      });
    }
  };

  onPause = () => {
    this.setState({
      paused: true,
      totalPlaybackDuration: this.currentPlayTime,
    });

    this.convivaVideoAnalytics &&
      this.convivaVideoAnalytics.reportPlaybackMetric(
        window.Conviva.Constants.Playback.PLAYER_STATE,
        window.Conviva.Constants.PlayerState.PAUSED
      );

    clearInterval(this.bitrateReportInterval);
  };

  onPlay = () => {
    // using the timeout prevents race condition leading to stale loading icon
    // when buffer is jittery on some TVs
    setTimeout(() => {
      this.setState({
        playStartTimestamp: Date.now(),
        paused: false,
        buffering: false,
      });
    }, 250);

    this.convivaVideoAnalytics &&
      this.convivaVideoAnalytics.reportPlaybackMetric(
        window.Conviva.Constants.Playback.PLAYER_STATE,
        window.Conviva.Constants.PlayerState.PLAYING
      );

    if (!this.progressEventInterval)
      this.progressEventInterval = setInterval(this.sendProgressEvent, 2000);
    if (!this.performanceUpdateInterval)
      this.performanceUpdateInterval = setInterval(() => {
        this.sendProgressToApi();
      }, 30000);

    if (this.props.onStartedPlaying)
      setTimeout(() => this.props.onStartedPlaying(), 5000);
  };

  get currentPlayTime() {
    const { totalPlaybackDuration, playStartTimestamp, paused } = this.state;
    if (paused) return totalPlaybackDuration;
    else
      return Math.round(
        totalPlaybackDuration + Date.now() - (playStartTimestamp || Date.now())
      );
  }

  showCCOptions = () => {
    this.setState({ showCcOptions: true });
  };

  get isOnNowUx() {
    return this.props.location.pathname === '/onnow';
  }

  updateAudioData = (audioTracks, selectedAudioTrack) => {
    this.setState({
      audioTracks,
      selectedAudioTrack,
    });
  };

  showAudioOptions = () => {
    this.setState({
      showAudioOptions: true,
    });
  };

  render() {
    const {
      config,
      title,
      seriesTitle,
      resumeTime,
      selectedCc,
      ccSettings,
      minimized,
      minimizedStyle,
      isLogged,
      encoding,
      onEnded,
      hide,
      intl,
    } = this.props;

    const {
      show,
      focusedButton,
      duration,
      showCcOptions,
      showAudioOptions,
      playerComponent,
      audioTracks,
      selectedAudioTrack,
    } = this.state;

    if ((!config.hasBufferSpinnerBug && !encoding) || !this.state.initTimestamp)
      return <Spinner label="Loading..." hideLabel />;

    const encodingUrl =
      config.forceManifest_TEST_ONLY ||
      encoding.master_playlist_url ||
      encoding.file_url;

    const closedCaptions = [{ language: 'off' }].concat(
      this.props.closedCaptions || []
    );

    const translateOutStyle = shift => {
      if (config.performanceIndex < 3000 && !config.isPresto)
        return {
          pointerEvents: !focusedButton ? 'none' : null,
          opacity: focusedButton ? 1 : 0,
          [config.supportedCssTransform]: focusedButton
            ? 'translateY(0)'
            : `translateY(${shift}%)`,
          transition: '300ms',
        };
      else {
        return {
          visibility: focusedButton ? 'visible' : 'collapse',
        };
      }
    };

    const Player = playerComponent;

    return (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: ccSettings,
          }}
        />
        {!show && !config.hasBufferSpinnerBug && (
          <Spinner label="Loading..." hideLabel />
        )}
        <div
          key={encodingUrl}
          id="player"
          style={{
            opacity: show ? 1 : 0,
            transition: '200ms',
            background: 'black',
            height: '100%',
            width: '100%',
          }}
        >
          {playerComponent ? (
            <Player
              autoPlay
              loaded={(node, playerInitTimeStamp) => {
                this.player = node;

                this.player.convivaVideoAnalytics = this.convivaVideoAnalytics;

                if (this.player && typeof this.player.on === 'function') {
                  this.player.on('error', e => {
                    this.convivaVideoAnalytics &&
                      this.convivaVideoAnalytics.reportPlaybackFailed(
                        e.error.message
                      );
                    clearInterval(this.bitrateReportInterval);
                  });
                } else {
                  this.player.onerror = e => {
                    this.convivaVideoAnalytics &&
                      this.convivaVideoAnalytics.reportPlaybackFailed(
                        e.error.message
                      );
                    clearInterval(this.bitrateReportInterval);
                  };
                }

                if (!this.state.playerStartupDuration) {
                  const playerStartupDuration =
                    Date.now() - playerInitTimeStamp;
                  this.setState({ playerStartupDuration, playerInitTimeStamp });
                }

                if (this.player.getQualityFor)
                  this.bitrateReportInterval = setInterval(() => {
                    // get current quality index
                    const currentQualityIndex =
                      this.player.getQualityFor('video');

                    // playervar.getBitrateInfoListFor('video') -- returns array of all qualities
                    const currentQualityObject =
                      this.player.getBitrateInfoListFor('video')[
                        currentQualityIndex
                      ];

                    const bitrate =
                      (currentQualityObject && currentQualityObject.bitrate) ||
                      null;

                    this.convivaVideoAnalytics &&
                      this.convivaVideoAnalytics.reportPlaybackMetric(
                        window.Conviva.Constants.Playback.BITRATE,
                        bitrate / 1000
                      );
                  }, 1000);

                if (this.playerTech === 'shaka') {
                  //report bitrate and framerate
                  setTimeout(() => {
                    const framerate = window.player.current
                      ?.getVariantTracks()
                      .find(track => track.active)?.frameRate;

                    if (framerate)
                      this.convivaVideoAnalytics.reportPlaybackMetric(
                        window.Conviva.Constants.Playback.RENDERED_FRAMERATE,
                        framerate
                      );
                  }, 1000);

                  this.bitrateReportInterval = setInterval(() => {
                    const bitrateToReport = window.player.current
                      ?.getVariantTracks()
                      .find(track => track.active)?.videoBandwidth;

                    if (
                      bitrateToReport &&
                      this.state.lastBitrateReported !== bitrateToReport
                    ) {
                      this.setState(
                        {
                          lastBitrateReported: bitrateToReport,
                        },
                        () => {
                          this.convivaVideoAnalytics.reportPlaybackMetric(
                            window.Conviva.Constants.Playback.BITRATE,
                            bitrateToReport
                          );
                        }
                      );
                    }
                  }, 1000);
                }
              }}
              onEnded={() => (onEnded ? onEnded() : this.closePlayer(true))}
              onWaiting={this.onWaiting}
              onLoadedMetadata={this.onLoadedMetadata}
              onCanPlay={this.onCanPlay}
              resumeTime={resumeTime?.progressInSeconds}
              onPause={this.onPause}
              onPlay={this.onPlay}
              encoding={encodingUrl}
              analyticsConfig={this.analyticsConfig}
              updateAudioData={this.updateAudioData}
              // convivaAnalytics={this?.convivaVideoAnalytics}
            />
          ) : (
            <video
              id="html-player"
              ref={node => {
                this.player = node;
              }}
              className={styles.video}
              style={minimized ? minimizedStyle : undefined}
              onCanPlay={() => {
                if (!this.state.playerStartupDuration) {
                  const playerStartupDuration =
                    Date.now() - this.state.initTimestamp;
                  this.setState({ playerStartupDuration });
                }

                this.onCanPlay();
              }}
              onLoadedMetadata={this.onLoadedMetadata}
              autoPlay
              crossOrigin="anonymous"
              onWaiting={this.onWaiting}
              onPause={this.onPause}
              onPlay={this.onPlay}
              preload="auto"
              onEnded={() => this.closePlayer(true)}
            >
              <source
                src={encodingUrl}
                type={
                  encodingUrl.includes('.mp4') ? null : config.videoMimeType
                }
              />
              {/* deprecated {config.hasNativeVTTSupport && selectedCc && selectedCc.value && (
                <track
                  key={selectedCc.value}
                  default
                  kind="subtitles"
                  src={selectedCc.value}
                  srcLang="en"
                />
              )} */}
            </video>
          )}
          {this.player && !hide && show && !minimized && (
            <Overlay>
              {showAudioOptions ? (
                <AudioTracksMenu
                  audioTracks={audioTracks}
                  selectedAudioTrack={selectedAudioTrack}
                  updateCurrentTrack={track => {
                    this.setState({
                      selectedAudioTrack: track,
                    });
                    config?.player === 'shaka'
                      ? window.player.current.setCurrentTrack(track)
                      : this.player.setCurrentTrack(track);
                  }}
                  onClose={() => this.setState({ showAudioOptions: null })}
                />
              ) : showCcOptions ? (
                <ClosedCaptionsMenu
                  onClose={() => this.setState({ showCcOptions: null })}
                />
              ) : (
                <>
                  {show && (this.isPaused || this.state.buffering) && (
                    <div className={styles.darken} />
                  )}
                  {show && this.state.buffering && !config.hasBufferSpinnerBug && (
                    <Spinner
                      label="Buffering..."
                      style={{
                        position: 'fixed',
                        top: '45%',
                        right: '45%',
                        width: '10%',
                        height: '10%',
                        zIndex: 999999999,
                      }}
                      noLabel
                    />
                  )}
                  {!isLogged && !config.isPresto && !config.hasFreeAccess && (
                    <Overlay.Preview
                      style={translateOutStyle(190)}
                      showButton={focusedButton !== null}
                    />
                  )}
                  <Overlay.Top
                    topLabel={
                      this.isOnNowUx
                        ? null
                        : intl.formatMessage({
                            id: 'smarttv:legacy_currently_watching',
                          })
                    }
                    style={translateOutStyle(-10)}
                    seriesTitle={seriesTitle}
                    title={title}
                    upNext={this.props.upNext}
                  />
                  <Overlay.Bottom
                    onClickGoTo={
                      this.isOnNowUx
                        ? () =>
                            this.props.history.replace(
                              `/media/${this.props.videoId}`
                            )
                        : null
                    }
                    style={translateOutStyle(10)}
                    currentTime={this.videoCurrentTime}
                    duration={duration}
                    closedCaptions={closedCaptions}
                    audioTracks={audioTracks}
                    focusedButton={focusedButton}
                    selectedCc={selectedCc}
                    selectedAudioTrack={selectedAudioTrack}
                    onAudioTracksClick={this.showAudioOptions}
                    onSubtitlesClick={() => {
                      if (config.hasNativeVTTSupport) {
                        this.toggleCc();
                      } else {
                        this.showCCOptions();
                      }
                    }}
                  >
                    <Overlay.Controls
                      canControl={!this.isOnNowUx}
                      buffering={this.state.buffering}
                      jumpDirect={percent => this.jumpToPercent(percent)}
                      onPlay={() => this.play()}
                      isHidden={focusedButton === null}
                      onPause={() => this.player.pause()}
                      playing={!this.isPaused}
                      focusedButton={focusedButton}
                      jumpBack={this.jumpBack}
                      jumpForward={this.jumpForward}
                      progress={this.videoCurrentTime / duration}
                    />
                  </Overlay.Bottom>
                  {!config.hasNativeVTTSupport &&
                    !minimized &&
                    selectedCc &&
                    selectedCc.value &&
                    this.player && (
                      <VTT
                        onLog={vttLog => this.setState({ vttLog })}
                        options={ccSettings}
                        videoElement={this.player}
                        vttUrl={selectedCc.value}
                      />
                    )}
                </>
              )}
            </Overlay>
          )}
        </div>
      </>
    );
  }
}

Player.defaultProps = {
  timeToShowNext: 15,
};

// Player.propTypes = {
//   videoId: PropTypes.number.isRequired,
//   onClosePlayer: PropTypes.func.isRequired,
//   resumeTime: PropTypes.object,
//   seriesTitle: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   closedCaptions: PropTypes.array.isRequired,
//   encoding: PropTypes.object.isRequired
// };

const mapStateToProps = state => {
  return {
    userData: getUserData(state),
    selectedCc: getSelectedCc(state),
    ccSettings: getPlayerCcSettings(state),
  };
};

const mapDispatchToProps = {
  updateCcSettings,
  initSubtitlesOptions,
  updateProgress,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfig,
  withAuth,
  withRouter,
  injectIntl
)(Player);

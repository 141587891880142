import React from 'react';

import { withConfig } from 'hoc';
import domDelay from 'utils/domDelay';

import { dNav } from 'config';

import Card from 'components/Card';
import Text from 'components/Text';
import styles from './styles.module.css';

class Carousel extends React.Component {
  state = {
    currentPage: 0,
    inFirstRender: true,
    actualArray: []
  };

  get startIndex() {
    return this.state.currentPage * this.props.cardsToDisplay;
  }

  componentDidMount() {
    const { media, cardsToDisplay, cardSpacing, peekPadding } = this.props;

    const ratio = cardsToDisplay === 1 ? 9 / 2 : 5.2 / 3;

    const cardsWidth =
      (window.innerWidth *
        (100 - peekPadding * 2 - cardSpacing * 2 * (cardsToDisplay + 1))) /
      100 /
      cardsToDisplay;

    this.processMediaList(media);

    this.setState({
      cardHeight: cardsWidth / ratio,
      rangeToLoad:
        cardsToDisplay === 1
          ? {
              min: this.startIndex - 2,
              max: this.startIndex + 2
            }
          : {
              min: this.startIndex - 1,
              max: this.startIndex + this.props.cardsToDisplay
            }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.media.map(m => m.key).join('') !==
      prevProps.media.map(m => m.key).join('') &&
      this.props.media.length > 0
    )
      this.processMediaList(this.props.media);
  }

  processMediaList = media => {
    const { cardsToDisplay } = this.props;

    if (media.length <= cardsToDisplay) this.setState({ blockEdgeFocus: true });

    if (media.length < cardsToDisplay || media.length % cardsToDisplay === 0) {
      this.setState(() => ({ actualArray: media }));
    } else {
      // repeats children so array.length is a multiple of cardstodisplay
      let result = [...media];
      while (result.length % cardsToDisplay !== 0) {
        result = [...result, result[(result.length % cardsToDisplay) - 1]];
      }
      this.setState(() => ({ actualArray: result, everythingWasLoaded: true }));
    }
  };

  get cardWidth() {
    return (
      (window.innerWidth * (100 - this.props.peekPadding * 2)) /
      this.props.cardsToDisplay /
      100
    );
  }

  onFocusCard = (index, options = {}) => {
    const { config, cardsToDisplay, wrapAround } = this.props;
    const { currentPage, actualArray } = this.state;
    const { performanceIndex } = config;
    const additionalDelay = performanceIndex < 3500 ? 400 : 550;

    this.setState({ inFirstRender: false });

    const rightEdgeIndex = cardsToDisplay * (currentPage + 1) - 1;

    if (cardsToDisplay === 1 && index === this.startIndex) {
      domDelay(() =>
        this.setState({
          rangeToLoad: {
            min: this.startIndex - (config.isFastDOMRenderer ? 1 : 2),
            max: this.startIndex + (config.isFastDOMRenderer ? 1 : 2)
          }
        })
      );
    } else {
      if (index === 0 && !wrapAround) return;

      if (index === rightEdgeIndex)
        domDelay(() =>
          this.setState({
            rangeToLoad: {
              min: this.startIndex - 1,
              max: this.startIndex + cardsToDisplay * 2
            }
          })
        );

      if (index === this.startIndex)
        domDelay(() =>
          this.setState({
            rangeToLoad: {
              min: this.startIndex - cardsToDisplay - 1,
              max: this.startIndex + cardsToDisplay
            }
          })
        );
    }

    if (options.preventAnimation) return;

    if (index >= this.startIndex + cardsToDisplay) {
      dNav.enable(false);
      this.setState({ currentPage: currentPage + 1, isAnimating: true });

      // the following raises an event when focus goes within two pages before the last one
      // so the parent component has a chance to fetch more media.
      if (
        this.props.onShouldLoadMore &&
        currentPage === actualArray.length / cardsToDisplay - 3 &&
        !this.state.everythingWasLoaded
      )
        this.props.onShouldLoadMore();

      setTimeout(() => {
        this.setState({ isAnimating: false }, () => {
          this.onFocusCard(index, { preventAnimation: true });
          dNav.enable(true);
        });
      }, this.realAnimationTime + additionalDelay);
    }
    if (index < this.startIndex) {
      dNav.enable(false);
      this.setState(
        {
          currentPage: this.state.currentPage - 1,
          isAnimating: true
        },
        () => {
          setTimeout(() => {
            this.setState({ isAnimating: false }, () => {
              this.onFocusCard(index, { preventAnimation: true });
              dNav.enable(true);
            });
          }, this.realAnimationTime + additionalDelay);
        }
      );
    }
  };

  shouldComponentUpdate(_, nextState) {
    if (nextState.rangeToLoad && !this.state.rangeToLoad) return true;
    if (nextState.rangeToLoad.min !== this.state.rangeToLoad.min) return true;
    if (nextState.rangeToLoad.max !== this.state.rangeToLoad.max) return true;
    if (!this.state.isAnimating && nextState.isAnimating) return true;
    if (this.state.mouseOver || nextState.mouseOver) return true;
    return false;
  }

  get realAnimationTime() {
    const { performanceIndex } = this.props.config;
    return performanceIndex < 3500 ? 325 : 500;
  }

  renderCard = (media, index, array) => {
    const { navigateTo, progressPercentage } = media;
    const {
      config,
      cardSpacing,
      wrapAround,
      peekPadding,
      cardsToDisplay,
      header,
      analyticsData,
      analyticsMediaId,
      focus
    } = this.props;

    const { rangeToLoad, blockEdgeFocus } = this.state;

    if (config.lowMemoryDevice && (index < 0 || index > cardsToDisplay - 1))
      return null;

    let actualIndex = index;

    //TODO: re-implement with modulo operator when I am not in a food coma
    if (index > rangeToLoad.max) {
      while (actualIndex > rangeToLoad.max) {
        actualIndex = actualIndex - array.length;
      }
    } else if (index < (!wrapAround ? 0 : rangeToLoad.min)) {
      while (actualIndex < (!wrapAround ? 0 : rangeToLoad.min)) {
        actualIndex = actualIndex + array.length;
      }
    }

    if (actualIndex < 0 && !wrapAround) return null;

    if (actualIndex < rangeToLoad.min || actualIndex > rangeToLoad.max)
      return null;

    const peekPixels = (window.innerWidth * peekPadding) / 100;

    const translate =
      (actualIndex - this.startIndex) * this.cardWidth + peekPixels;

    const blockFocus = !wrapAround
      ? index === 0 && array.length === 1
        ? 'both'
        : index === 0
        ? 'left'
        : index === array.length - 1
        ? 'right'
        : blockEdgeFocus
        ? array.length === 1
          ? 'both'
          : index === 0
          ? 'left'
          : index === array.length - 1
          ? 'right'
          : null
        : null
      : null;

    return (
      <div
        key={media.key + actualIndex}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transition: `${config.supportedCssTransform_raw} ${
            this.realAnimationTime
          }ms ease-in-out`,
          [config.supportedCssTransform]: `translateX(${translate}px)`
        }}
      >
        <Card
          analyticsData={analyticsData && analyticsData(
            analyticsMediaId && analyticsMediaId(media) || `${media.isSeries ? 'series' : 'media'}_${media.id}`,
            index
          )}
          blockFocus={blockFocus}
          progressPercentage={progressPercentage}
          alt={`${header || ''} ${media.title}`}
          focus={index === 0 && this.state.inFirstRender ? focus : null}
          onFocus={() => this.onFocusCard(actualIndex)}
          onKeyDown={e => {
            if (e.keyCode === config.keyCodes.enter && this.props.onSelect) {
              this.props.onSelect(media, actualIndex);
            }
          }}
          navigateTo={navigateTo}
          height={this.state.cardHeight}
          style={{
            width: this.cardWidth,
            padding: `0 ${(cardSpacing * window.innerWidth) / 100}px`
          }}
          imgUrl={media.imageUrl}
        />
      </div>
    );
  };

  handleMouseEnter = e => this.setState({ mouseOver: true });

  handleMouseLeave = e => this.setState({ mouseOver: false });

  handleSideClick = factor => {
    const { isAnimating } = this.state;

    if (isAnimating) return;

    this.onFocusCard(this.startIndex + factor);
  };

  render() {
    const {
      wrapAround,
      peekPadding,
      cardsToDisplay,
      header,
      cardSpacing,
      media
    } = this.props;

    if (media.length === 0) return null;

    const { currentPage, actualArray, blockEdgeFocus, mouseOver } = this.state;

    const showLeftPageButton = wrapAround || currentPage > 0;

    const showRightPageButton =
      wrapAround || (currentPage + 1) * cardsToDisplay < media.length;

    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={{
          position: 'relative'
        }}
      >
        {!blockEdgeFocus && mouseOver && (
          <>
            {showLeftPageButton && (
              <div
                className={styles.sideButton}
                onMouseEnter={() => this.setState({ buttonHover: 'left' })}
                onMouseLeave={() => this.setState({ buttonHover: null })}
                onClick={() => this.handleSideClick(-1)}
                style={{
                  height: this.state.cardHeight,
                  left: 0,
                  width: peekPadding + '%',
                  background:
                    'linear-gradient(to left, transparent, rgba(251, 166, 11, 0.8))',
                  opacity: this.state.buttonHover === 'left' ? 1 : 0
                }}
              />
            )}
            {showRightPageButton && (
              <div
                className={styles.sideButton}
                onMouseEnter={() => this.setState({ buttonHover: 'right' })}
                onMouseLeave={() => this.setState({ buttonHover: null })}
                onClick={() => this.handleSideClick(cardsToDisplay)}
                style={{
                  height: this.state.cardHeight,
                  right: 0,
                  width: peekPadding + '%',
                  background:
                    'linear-gradient(to right, transparent, rgba(251, 166, 11, 0.8))',
                  opacity: this.state.buttonHover === 'right' ? 1 : 0
                }}
              />
            )}
          </>
        )}
        {header && (
          <Text
            thin={false}
            style={{
              fontSize: '1.05em',
              marginLeft: `${peekPadding + cardSpacing / 2}%`,
              marginBottom: '0.5em'
            }}
          >
            {header}
          </Text>
        )}

        <div
          data-cy={
            header ? `carousel-${header.replace(' ', '-').toLowerCase()}` : null
          }
          style={{
            position: 'relative',
            height: this.state.cardHeight,
            width: '1000%',
            margin: '1% 0'
          }}
        >
          {actualArray.map(this.renderCard)}
        </div>
      </div>
    );
  }
}

Carousel.defaultProps = {
  wrapAround: false, // not really compatible with pagination
  cardsToDisplay: 4,
  peekPadding: 8, //percent
  cardSpacing: 0.35 //percent
};

export default withConfig(Carousel);

import React from 'react';
import chunk from 'lodash.chunk';

import ScrollArea from 'components/ScrollArea';
import Card from 'components/Card';
import Text from 'components/Text';

import { withConfig } from 'hoc';

export class Grid extends React.Component {
  state = { focus: true };

  gotSize = e => {
    this.setState({ viewWidth: e.width });
  };

  render() {
    const {
      media,
      title,
      focusFirstItem,
      config,
      rowSize,
      collectionId,
      noMargin,
      analyticsData
    } = this.props;

    const cardWidth = 100 / rowSize - rowSize * 0.3;
    const cardHeight =
      (cardWidth / 100) * this.state.viewWidth * (noMargin ? 0.6 : 0.5);

    const mediaToDisplay = config.lowMemoryDevice
      ? chunk(media, rowSize).slice(0, 3)
      : chunk(media, rowSize);

    return (
      <>
        {title && (
          <Text
            title
            style={{
              marginLeft: '8.13%',
              fontSize: '1.2em'
            }}
            data-cy="grid-title"
          >
            {title}
          </Text>
        )}
        <ScrollArea
          id={title}
          noShadow={noMargin}
          onSizeSet={this.gotSize}
          paddingTop={1}
          style={
            noMargin
              ? null
              : {
                  width: '84%',
                  marginLeft: '8%'
                }
          }
        >
          {this.state.viewWidth &&
            mediaToDisplay.map((segment, segmentIndex) => {
              const mediacards = segment.map((m, i) => (
                <Card
                  analyticsData={analyticsData && analyticsData(
                    `${m.obj_type === 'episode' ? 'media' : 'series'}_${m.id}`,
                    4 * segmentIndex + i
                  )}
                  progressPercentage={m.progressPercentage}
                  focus={focusFirstItem && segmentIndex === 0 && i === 0}
                  navigateTo={`/${m.isSeries ? 'series' : 'media'}/${m.id}${collectionId ? `?playlist=${collectionId}` : ''}`}
                  key={m.id}
                  alt={m.title}
                  height={cardHeight}
                  style={{
                    width: `${cardWidth}%`,
                    margin: '0.1% 0.4%'
                  }}
                  imgUrl={m.imageUrl}
                  onKeyDown={e =>
                    e.keyCode === config.keyCodes.enter &&
                    this.props.onSelect &&
                    this.props.onSelect(m, media.map(a => a.key).indexOf(m.key))
                  }
                />
              ));
              return <div key={`s${segmentIndex}`}>{mediacards}</div>;
            })}
        </ScrollArea>
      </>
    );
  }
}

Grid.defaultProps = {
  rowSize: 4
};

export default withConfig(Grid);

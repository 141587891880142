import React from 'react';

const styles = {
  // fontWeight
  thin: 'lighter',
  strong: 'bold',

  // fontSizes
  title: '1.6em',
  large: '1.2em',
  small: '0.8em'
};

export default ({
  thin = true,
  title,
  small,
  style,
  as,
  strong,
  large,
  shadow,
  id,
  noMargin,
  children,
  ...props
}) => {
  const Wrapper = as || 'p';
  return (
    <Wrapper
      data-cy={id}
      style={{
        textShadow: shadow ? '0px 0.2em 0.2em black' : undefined,
        margin: noMargin ? 0 : undefined,
        fontWeight: strong ? styles.strong : thin ? styles.thin : undefined,
        fontSize: title
          ? styles.title
          : large
            ? styles.large
            : small
              ? styles.small
              : '1em',
        lineHeight: '1.5em',
        ...style
      }}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

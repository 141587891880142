import logger from 'utils/logger';
import { fireEvent } from 'utils/analytics';
import { session } from 'utils/tokenManager';

let log = logger.extend('config');

export const detectEnvironment = agentString => {
  let result = { platform: 'web' };

  log(`agentString:`, agentString);

  let origin = window.location.origin;
  if (!origin) {
    origin = window.location.protocol + '//' + window.location.hostname;
  }
  if (origin.includes('tv-vewd')) {
    result.platform = 'vewd';
  } else if (origin.includes('tv-zeasn')) {
    result.platform = 'zeasn';
  } else if (
    agentString.includes('SmartHub') &&
    agentString.includes('SMART-TV')
  ) {
    result.platform = 'samsung';
  } else if (agentString.includes('Web0S') && agentString.includes('SmartTV')) {
    result.platform = 'lg';
  } else if (agentString.includes('Tizen')) {
    result.platform = 'tizen';
  } else if (agentString.includes('Enseo')) {
    result.platform = 'enseo';
  } else if (origin.includes('tv-altice')) {
    result.platform = 'altice';
  } else if (agentString.includes('Layer3')) {
    result.platform = 'layer3tv';
  } else if (
    (agentString.includes('WebView') || agentString.includes('MSAppHost')) &&
    agentString.includes('Edge')
  ) {
    result.platform = 'xbox';
  } else if (agentString.includes('Sony')) {
    result.platform = 'sony';
  } else if (
    agentString.includes('CrKey') ||
    agentString.includes('VIZIO') ||
    agentString.includes('Conjure') ||
    agentString.includes('SmartCast')
  ) {
    result.platform = 'vizio';
  } else if (agentString.includes('HbbTV')) {
    result.platform = 'ericsson';
  } else if (agentString.includes('TiVo')) {
    result.platform = 'tivo';
    const providers = [
      'ABB',
      'Armstrong',
      'Blueridge',
      'Buckeye',
      'CableOnda',
      'Cogeco',
      'Entouch',
      'Evolution',
      'GCI',
      'Grande',
      'NCTC',
      'Mediacom',
      'Midco',
      'RCN',
      'SECV',
      'SECTV',
      'Suddenlink',
      'Wave/Astound',
      'WoW',
    ];
    result.provider = providers.find(p =>
      agentString.toLowerCase().includes(p.toLowerCase())
    );
  }

  log(`detected ${result.platform} ${result.provider}`);
  return result;
};

const { platform, provider } = detectEnvironment(navigator.userAgent);

log = logger.extend(platform);

export const parseConfig = platformName =>
  require(`./devices/${platformName}.json`);

let config = parseConfig(platform);

if (platform === 'web') config.signUpLink = `curiositystream.com`;
else
  config.signUpLink = `curiositystream.com/${
    provider || platform.replace('tizen', 'samsung')
  }`;

const IS_PRODUCTION = process.env.NODE_ENV !== 'development';

config.getIsTTSEnabled = () => false;
config.getIsUhdCapable = () => false;
config.getScreenHeight = () => window.screen.height;

config.onNetworkChanged = cb => {
  window.addEventListener('offline', e => cb({ online: false }));
  window.addEventListener('online', e => cb({ online: true }));
};

if (config.globalKeyCodeObject) {
  config.keyCodes = config.keyCodes.reduce(
    (prev, _, i, array) => ({
      ...prev,
      [array[i].key]: window[config.globalKeyCodeObject][array[i].value],
    }),
    {}
  );
}

let scriptsToLoad = [].concat(
  IS_PRODUCTION && config.productionScripts
    ? config.productionScripts
    : config.scripts
);

const loadScript = (src, index, array) => {
  let scriptTag = document.createElement('script');

  scriptTag.onload = () =>
    index === array.length - 1 ? continueInit(config.name) : null;
  scriptTag.setAttribute('src', src);
  scriptTag.setAttribute('type', 'text/javascript');
  document.head && document.head.appendChild(scriptTag);
};

export const continueInit = configName => {
  config.hasFreeAccess = config.freeAccess && config.freeAccess.unconditional;

  if (config.hasFreeAccess) log(`hasFreeAccess`);

  config.isFreeMonth =
    process.env.REACT_APP_FORCE_FREE_MONTH ||
    (config.freeAccess &&
      new Date().getDate() >= config.freeAccess.day &&
      new Date().getMonth() === config.freeAccess.month - 1 && // months are zero-based
      new Date().getFullYear() === config.freeAccess.year);

  const KEEP_FREE_FEBRUARY_VIZIO_USERS =
    config.freeAccess && new Date().getMonth() === 1; // todo remove after march 1st 2020

  if (config.isFreeMonth || KEEP_FREE_FEBRUARY_VIZIO_USERS) {
    log(`isFreeMonth`);
    config.hasFreeAccess = !!session.get();
  } else session.remove();

  if (navigator.userAgent.includes('Funai-Model18')) {
    config.lowMemoryDevice = true;
  }

  if (navigator.userAgent.includes('Presto')) {
    // Presto engine can be used by the following:
    // - tivo
    // - vewd

    // Adds flag
    config.isPresto = true;

    // Presto devices usually have very low resources
    config.lowMemoryDevice = configName === 'tivo';
  }

  if (configName === 'tivo' || configName === 'vewd') {
    const series4tivoDevices = ['TCD74', 'TCD75', 'A90', 'A92', 'A93'];
    const series5tivoDevices = ['TCD846', 'TCD848', 'TCD840', 'D12', 'D18'];

    const isSeries4 = series4tivoDevices.some(
      a => navigator.userAgent.indexOf(a) > -1
    );

    const isSeries5 = series5tivoDevices.some(
      a => navigator.userAgent.indexOf(a) > -1
    );

    config.lowMemoryDevice =
      isSeries4 || isSeries5 || (config.isPresto && configName === 'vewd');

    // if (config.lowMemoryDevice)
    config.encodingsParams.encodingsMaxHeight = 720;

    config.getIsUhdCapable = () => !isSeries4 && !isSeries5;

    config.exitApp = () => window.close();

    if (navigator.userAgent.toLowerCase().includes('hisense'))
      config.player = null;

    finishLoading();
  }

  if (configName === 'ericsson') {
    config.getIsUhdCapable = () => true;

    finishLoading();
  }

  if (configName === 'zeasn') {
    finishLoading();
  }

  if (configName === 'enseo') {
    config.exitApp = () => window.close();

    finishLoading();
  }

  if (configName === 'web') {
    config.getIsUhdCapable = () => true;
    config.exitApp = () => console.log('exit');

    finishLoading();
  }

  if (configName === 'samsung') {
    // http://developer.samsung.com/tv/develop/guides/fundamentals/application-resolution
    config.getScreenHeight = () => window.screen.height.toString();

    let uhd = false;
    try {
      uhd = window.webapis.productinfo.isUdPanelSupported();
    } catch (e) {}

    config.getIsUhdCapable = () => uhd;

    const networkPlugin = document.getElementById('pluginObjectNetwork');

    const checkConnection = () => {
      var gatewayStatus = 0,
        // Get active connection type - wired or wireless.
        // $FlowFixMe
        currentInterface = networkPlugin.GetActiveType();
      // If no active connection.
      if (currentInterface === -1) {
        return false;
      }
      // Check Gateway connection of current interface.
      // $FlowFixMe
      gatewayStatus = networkPlugin.CheckGateway(currentInterface);
      // If not connected or error.
      if (gatewayStatus !== 1) {
        return false;
      }
      // Everything went OK.
      return true;
    };

    config.getIsTTSEnabled = () =>
      window.webapis._plugin('RECOG', 'IsTTSEngineEnabled');

    let isCurrentlyOnline = true;
    config.onNetworkChanged = cb => {
      setInterval(() => {
        const online = checkConnection();
        if (isCurrentlyOnline !== online) {
          isCurrentlyOnline = online;
          cb({ online });
        }
      }, 500);
    };

    finishLoading();
  }

  if (configName === 'layer3tv') {
    config.getIsUhdCapable = () => true;

    finishLoading();
  }

  if (configName === 'altice') {
    config.getIsUhdCapable = () => true;
    fetch(`https://api.curiositystream.com/organizations/providers`)
      .then(req => req.json())
      .then(res => {
        const detectedProvider = res.data.providers.find(a => a.ip_match);

        if (
          detectedProvider?.name === `Optimum` ||
          detectedProvider?.name === `Suddenlink`
        )
          config.hasFreeAccess = detectedProvider;

        finishLoading();
      });
  }

  if (configName.includes('vizio')) {
    document.addEventListener('VIZIO_LIBRARY_DID_LOAD', async e => {
      window.VIZIO.getModelYear(year =>
        window.VIZIO.getFirmwareVersion(version => {
          log('version', version);
          console.log('vizio version', version);

          config.device = {
            model: window.VIZIO.deviceModel,
            version: year,
            os: 'vizio',
            osVersion: version,
          };

          config.getIsUhdCapable = () =>
            window.VIZIO.getDevicePlaybackQualities().includes('UHD');

          finishLoading(version);
        })
      );

      config.getIsTTSEnabled = () => Boolean(window.VIZIO.Chromevox.ttsEnabled);

      config.onClosedCaptionsChange = window.VIZIO.setClosedCaptionHandler;

      config.exitApp = () => window.VIZIO.exitApplication();
    });
  }

  if (configName === 'lg') {
    config.exitApp = () => window.webOS.platformBack(); // shows menu

    setTimeout(() => {
      try {
        window.webOS.deviceInfo(info => {
          console.log('LG device info', info);
          config.device = {
            ...info,
            model: info.model || info.modelNameAscii,
            version: info.sdkVersion,
            os: 'web0s',
            osVersion: info.version,
          };
          /*
            example response on the small one
            
            modelName: "24LF4820-BU"
            modelNameAscii: "24LF4820-BU"
            screenHeight: 1080
            screenWidth: 1920
            sdkVersion: "2.1.0"
            uhd: false
            version: "03.21.30"
            versionDot: 30
            versionMajor: 3
            versionMinor: 21
          */

          config.hasBufferSpinnerBug = info.versionMajor <= 3;

          config.getScreenHeight = () => info.screenHeight;

          const uhd =
            typeof info.uhd === 'boolean' ? info.uhd : info.uhd === 'true';

          config.getIsUhdCapable = () => uhd;
        });
      } catch (e) {
        // no worries!
        log('error', e);
      } finally {
        finishLoading();
      }
    }, 200);
  }

  if (configName !== 'xbox') {
    // inits navigation directions with directionalNavigation
    setTimeout(() => {
      window.TVJS.DirectionalNavigation.keyCodeMap.up.push(config.keyCodes.up);
      window.TVJS.DirectionalNavigation.keyCodeMap.down.push(
        config.keyCodes.down
      );
      window.TVJS.DirectionalNavigation.keyCodeMap.left.push(
        config.keyCodes.left
      );
      window.TVJS.DirectionalNavigation.keyCodeMap.right.push(
        config.keyCodes.right
      );
    }, 500);
  }

  if (configName === 'xbox') {
    // https://social.msdn.microsoft.com/Forums/azure/en-US/c4e1fc60-b216-4a4b-875b-1d71b9699060/uwphtml-problem-playing-4k-hls-streams-in-tvjs?forum=wpdevelop
    config.getIsUhdCapable = () => true;

    finishLoading();
  }

  if (configName === 'tizen') {
    config.exitApp = () =>
      window.tizen.application.getCurrentApplication().exit();

    config.getIsTTSEnabled = () =>
      window.webapis.tvinfo.getMenuValue(
        window.webapis.tvinfo.TvInfoMenuKey.VOICE_GUIDE_KEY
      );

    // http://developer.samsung.com/tv/develop/tutorials/app-fundamentals/multitasking
    document.addEventListener('visibilitychange', function () {
      try {
        if (document.hidden) {
          window.webapis.avplay.suspend();
        } else {
          window.webapis.avplay.restore();
        }
      } catch (e) {
        // smoke bomb
      }
    });

    let uhd = false;
    try {
      uhd = window.webapis.productinfo.isUdPanelSupported();
    } catch (e) {}

    config.getIsUhdCapable = () => uhd;

    window.tizen.systeminfo.getPropertyValue('DISPLAY', function (result) {
      config.getScreenHeight = () => result.resolutionHeight;
      log('resolutionHeight', result.resolutionHeight);
    });

    window.tizen.tvinputdevice.registerKey('MediaPlay');
    window.tizen.tvinputdevice.registerKey('MediaPlayPause');
    window.tizen.tvinputdevice.registerKey('MediaPause');
    window.tizen.tvinputdevice.registerKey('MediaStop');
    window.tizen.tvinputdevice.registerKey('MediaRewind');
    window.tizen.tvinputdevice.registerKey('MediaFastForward');
    window.tizen.tvinputdevice.registerKey('0');
    window.tizen.tvinputdevice.registerKey('1');
    window.tizen.tvinputdevice.registerKey('2');
    window.tizen.tvinputdevice.registerKey('3');
    window.tizen.tvinputdevice.registerKey('4');
    window.tizen.tvinputdevice.registerKey('5');
    window.tizen.tvinputdevice.registerKey('6');
    window.tizen.tvinputdevice.registerKey('7');
    window.tizen.tvinputdevice.registerKey('8');
    window.tizen.tvinputdevice.registerKey('9');

    // http://developer.samsung.com/tv/develop/legacy-platform-library/tec00128/index
    // setInterval(() => {
    //   window.tizen.systeminfo.getPropertyValue('NETWORK', a => this.setState({a: a}));
    // }, 500)

    config.onNetworkChanged = cb => {
      window.webapis.network.addNetworkStateChangeListener(value => {
        if (
          value === window.webapis.network.NetworkState.GATEWAY_DISCONNECTED
        ) {
          cb({ online: false });
        } else if (
          value === window.webapis.network.NetworkState.GATEWAY_CONNECTED
        ) {
          cb({ online: true });
        }
      });
    };

    setTimeout(
      () => (config.getIsOnline = window.webapis.network.isConnectedToGateway),
      200
    );

    finishLoading();
  }
};

if (scriptsToLoad && scriptsToLoad.length) scriptsToLoad.forEach(loadScript);
else continueInit(config.name);

config.isReturnKey = keyCode => {
  if (Array.isArray(config.keyCodes.return)) {
    return config.keyCodes.return.indexOf(keyCode) > -1;
  }

  return keyCode === config.keyCodes.return;
};

//#region environment performance analysis

// dom loading time

document.onreadystatechange = function () {
  if (document.readyState === 'interactive') {
    try {
      const t = window.performance.timing;
      config.performanceIndex = t.domInteractive - t.domLoading;

      // // the following seems to be less impacted by network, and would reflect better cpu perf.
      // // it would need `document.readyState === 'interactive'` as trigger.
      // const interactive = t.domInteractive - t.domLoading;
      // const complete = t.domComplete - t.domLoading;
      // config.performanceIndex = complete - interactive;
    } catch (e) {
      console.error('error while checking performance', e);
      // assume the worst!
      config.performanceIndex = 10000;
    }
    if (config.performanceIndex < 0) {
      config.performanceIndex = 10000;
    }

    fireEvent({
      category: `init-${config.name}`,
      action: 'performanceIndex',
      label: config.performanceIndex,
    });

    config.onPerformanceSet && config.onPerformanceSet();
  }
};

// css transform support

try {
  const getSupportedTransform = () => {
    var prefixes = 'transform WebkitTransform MozTransform msTransform'.split(
      ' '
    );
    var div = document.createElement('div');
    for (var i = 0; i < prefixes.length; i++) {
      // $FlowFixMe
      if (div && div.style[prefixes[i]] !== undefined) {
        return prefixes[i];
      }
    }
    return false;
  };

  const cssTransform = getSupportedTransform();
  if (cssTransform) {
    config.supportedCssTransform = cssTransform;

    if (cssTransform === 'WebkitTransform') {
      config.supportedCssTransform_raw = '-webkit-transform';
    } else if (cssTransform === 'MozTransform') {
      config.supportedCssTransform_raw = '-moz-transform';
    } else {
      config.supportedCssTransform_raw = config.supportedCssTransform;
    }
  }
} catch (e) {
  console.error('error while detecting CSS features', e);
}

//#endregion

//#region directionalNav

// returns appropriate directionalNavigation depending on wether the app
// is running on xbox or anywhere else. TVJS.DirectionalNavigation and
// WinJS.UI.XYFocus are essentially the same api with few differences
!window.WinJS && require('utils/directionalNavigation.js');

let nav = window.WinJS
  ? window.WinJS.UI.XYFocus
  : window.TVJS
  ? window.TVJS.DirectionalNavigation
  : {};

// WARNING: the following may be leaky!

const blockOnFocusChanging = e => {
  e.preventDefault();
};

const letOnFocusChanging = e => {
  return;
};

nav.enable = value => {
  if (window.WinJS) {
    window.WinJS.UI.XYFocus.removeEventListener(
      'focuschanging',
      blockOnFocusChanging
    );
    window.WinJS.UI.XYFocus.removeEventListener(
      'focuschanging',
      letOnFocusChanging
    );

    if (window.WinJS) {
      if (value)
        window.WinJS.UI.XYFocus.addEventListener(
          'focuschanging',
          letOnFocusChanging
        );
      else
        window.WinJS.UI.XYFocus.addEventListener(
          'focuschanging',
          blockOnFocusChanging
        );
    }
  }

  if (window.TVJS) window.TVJS.DirectionalNavigation.enabled = value;
};

export const dNav = nav;

//#endregion

// fireEvent({
//   category: 'device',
//   action: 'detection',
//   label: 'brand',
//   property: platform
// });

// fireEvent({
//   category: 'device',
//   action: 'detection',
//   label: 'isLowMemoryDevice',
//   property: config.lowMemoryDevice
// });

// fireEvent({
//   category: 'device',
//   action: 'detection',
//   label: 'isUhdCapable',
//   property: config.getIsUhdCapable()
// });

// fireEvent({
//   category: 'device',
//   action: 'detection',
//   label: 'resolution',
//   property: `${config.getScreenHeight()}p`
// });

let _finishedLoadingCallback;
let _isLoadingFinished;

// gotta wait that much to display an accurate information
function finishLoading(version) {
  fireEvent({
    category: `init-${config.name}`,
    action: `version`,
    label: version,
  });

  setTimeout(async () => {
    const uhd = config.getIsUhdCapable();

    //http://cconcolato.github.io/media-mime-support/#other_video_codecs
    //https://github.com/cconcolato/media-mime-support
    const video = document.createElement('video');

    let supports = {
      h265:
        video.canPlayType('application/dash+xml; codecs="hvc1.1.6.L153.90"')
          .length > 0,
      dash: video.canPlayType('application/dash+xml').length > 0,
    };

    if (config.encodingsParams) {
      config.encodingsParams = {
        ...config.encodingsParams,
        encodingsStandard: config.forceH264
          ? 'h264'
          : config.forceH265
          ? 'h265'
          : (supports.h265 || config.forceH265OnUhd) && uhd
          ? 'h265'
          : 'h264',
      };

      if (
        !uhd &&
        (!config.encodingsParams.encodingsMaxHeight ||
          config.encodingsParams.encodingsMaxHeight > 1080)
      )
        config.encodingsParams.encodingsMaxHeight = 1080;
    }

    const loadScript = src => {
      return new Promise(success => {
        let scriptTag = document.createElement('script');

        scriptTag.onload = success;
        scriptTag.setAttribute('src', src);
        scriptTag.setAttribute('type', 'text/javascript');
        scriptTag.setAttribute('async', 'false');
        document.head && document.head.appendChild(scriptTag);
      });
    };

    await loadScript(`/static/js/conviva-core-sdk.js`);

    const clue = config.h265 ? 'c' : uhd ? 'b' : 'a';

    config.version = `${process.env.REACT_APP_VERSION}${clue} (${config.name})`;

    _isLoadingFinished = true;
    _finishedLoadingCallback && _finishedLoadingCallback();
  }, 100);
}

config.onFinishedLoading = cb => {
  _finishedLoadingCallback = cb;
  if (_isLoadingFinished) cb();
};

export const getConfig = () => config;

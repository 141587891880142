// @flow

import React from 'react';

import { withConfig } from 'hoc';

import styles from './styles.module.css';

class Progress extends React.Component {
  state = {
    seeking: false,
    hidden: false,
    focused: false
  };

  //the following can be removed when VIZIO supports raising events from dom elements
  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  //the following can be removed when VIZIO supports raising events from dom elements
  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate(prevProps) {
    // this is set so progressbar does not seem like catching up slowly when appearing
    if (prevProps.isHidden !== this.props.isHidden) {
      setTimeout(() => this.setState({ hidden: this.props.isHidden }), 200);
    }
  }

  onFocus = () => this.setState({ focused: true });

  onBlur = () => this.setState({ focused: false });

  onKeyDown = e => {
    const { config, jumpBack, jumpForward } = this.props;
    const { focused } = this.state;

    if (focused && e.keyCode === config.keyCodes.left && jumpBack) {
      this.fasterAnimation(jumpBack);
    } else if (focused && e.keyCode === config.keyCodes.right && jumpForward) {
      this.fasterAnimation(jumpForward);
    }
  };

  fasterAnimation = (action, params) => {
    clearTimeout(this.seekTimeout);
    this.setState({ seeking: true });
    action(params);
    this.seekTimeout = setTimeout(() => this.setState({ seeking: false }), 200);
  };

  onClickedPosition = e => {
    if (this.props.onClickedPosition) {
      this.fasterAnimation(
        this.props.onClickedPosition,
        (100 * (e.clientX - this.elt.getBoundingClientRect().left)) /
          this.elt.getBoundingClientRect().width
      );
    }
  };

  render() {
    const {
      style,
      className,
      config,
      color,
      value,
      focusable,
      focus,
      ariaLabel
    } = this.props;

    const { seeking, focused, hidden } = this.state;

    const boundValue = Math.max(Math.min(100, value * 100), 0);

    return (
      <div
        id="progressbar"
        aria-label={ariaLabel}
        //the following can be uncommented when VIZIO supports raising events from dom elements
        //onKeyDown={this.onKeyDown}
        className={className}
        style={{
          ...style,
          zIndex: 999999,
          opacity: focusable ? (focused ? 1 : 0.75) : 1
        }}
        ref={node => (this.elt = node)}
        tabIndex={focusable ? 1 : null}
        focus={focus}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onClick={this.onClickedPosition}
      >
        <div className={styles.fill + ' ' + styles.backgroundTrack} />
        <div
          className={styles.fill}
          style={{
            background: color || '#fba60b',
            transition: !hidden && `${seeking ? '50ms' : '1s'} linear`,
            width: `${boundValue}%`
          }}
        />
        {focused && !config.isPresto && (
          <div
            className={styles.currentPos}
            style={{
              transition: !hidden && `${seeking ? '50ms' : '1s'} linear`,
              left: `calc(-0.5em + ${boundValue}%)`
            }}
          />
        )}
      </div>
    );
  }
}

export default withConfig(Progress);

import React from 'react';
import styles from './styles.module.css';

export default ({ icon, children, style }) => {
  return (
    <div className={styles.section} style={style}>
      {/* <div style={{ display: 'inline-block', padding: '0 0.7em 0 0' }}>
        {icon}
      </div> */}
      <div style={{ display: 'inline-block' }}>{children}</div>
    </div>
  );
};

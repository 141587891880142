import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles.module.css';

import { withConfig } from 'hoc';
import TTSFocusableDescription from 'components/TTSFocusableDescription';
import Text from 'components/Text';
import WithPlans from 'components/WithPlans';
import { fireEvent } from 'utils/analytics';
import Footer from 'components/Footer';

import Settings from './Settings';
import Bookmarks from './Bookmarks';
import History from './History';
import SideButton from './SideButton';
import Section from './Section';

import { getIsLoading, fetchUser, getUserData } from 'reducers/Profile/reducer';

export const showProdLink = process.env.NODE_ENV === 'development';

class Profile extends React.Component {
  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);

    // refreshes info and settings i.e. kids modes
    this.props.fetchUser();

    if (
      document.activeElement.localName === 'body' &&
      document.getElementById('log-out')
    ) {
      // happens when dismissing logout modal
      document.getElementById('log-out').focus();
    }

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'account (profile)',
    });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = e => {
    const { history, config } = this.props;

    if (config.isReturnKey(e.keyCode)) {
      history.goBack();
    }
  };

  render() {
    const { isLoading, userData, history, config, intl } = this.props;

    if (isLoading) return null;

    if (!userData) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: '/profile' },
          }}
        />
      );
    }

    return (
      <div style={{ padding: '1% 8%' }} id="profile-page">
        <div className={styles.leftMenu}>
          <SideButton
            active={window.location.hash === ''}
            label={intl.formatMessage({
              id: 'smarttv:account_sidenav_account_button',
            })}
            onClick={() => (window.location.hash = '')}
          />
          <br />
          <SideButton
            active={window.location.hash === '#Watchlist'}
            label={intl.formatMessage({
              id: 'smarttv:account_sidenav_watchlist_button',
            })}
            onClick={() => (window.location.hash = 'Watchlist')}
          />
          <br />
          <SideButton
            active={window.location.hash === '#History'}
            label={intl.formatMessage({
              id: 'smarttv:account_sidenav_history_button',
            })}
            onClick={() => (window.location.hash = 'History')}
          />
          <br />
          <SideButton
            active={window.location.hash === '#Settings'}
            label={intl.formatMessage({
              id: 'smarttv:account_sidenav_settings_button',
            })}
            onClick={() => (window.location.hash = 'Settings')}
          />
          <br />
          <SideButton
            label={intl.formatMessage({
              id: 'smarttv:account_sidenav_logout_button',
            })}
            onClick={() => history.push('/logout')}
          />
        </div>
        <div className={styles.rightPane}>
          {window.location.hash === '#Watchlist' && <Bookmarks />}
          {window.location.hash === '#History' && <History />}
          {window.location.hash === '' && (
            <>
              <Footer />
              <TTSFocusableDescription>
                <Section>
                  <Text strong noMargin>
                    <FormattedMessage id="smarttv:account_account_name_label" />
                  </Text>
                  <Text>
                    {userData.firstName} {userData.lastName}
                  </Text>
                  <Text strong noMargin>
                    <FormattedMessage id="smarttv:account_account_email_label" />
                  </Text>
                  <Text>{userData.email}</Text>
                </Section>
                {/* {config.appStoreName && (
                  <Section>
                    <Text strong>
                      Like the app? Thank you for rating it on the{' '}
                      {config.appStoreName} store!
                    </Text>
                  </Section>
                )} */}
              </TTSFocusableDescription>
              {/* {!config.hideTerms && (
                <Button
                  style={{ marginLeft: '1em' }}
                  id="terms"
                  onClick={() => history.push('/terms')}
                >
                  Terms of use
                </Button>
              )} */}
            </>
          )}
          {window.location.hash === '#Settings' && (
            <WithPlans planId={userData.stripePlan}>
              {({ plan }) => (
                <Settings has4kPlan={plan && plan.encoding === '4k'} />
              )}
            </WithPlans>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userData: getUserData(state),
  isLoading: getIsLoading(state),
});

export default withConfig(
  connect(mapStateToProps, { fetchUser })(injectIntl(Profile))
);

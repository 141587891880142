import logger from 'utils/logger';
const log = logger.extend('analytics');

export const setAnonymous = value => {
  if (value) {
    log('setAnonymous', value, { nonInteraction: true });
    window.ga &&
      window.ga('set', 'anonymizeIp', value, { nonInteraction: true });
  }
};

export const setUserIdForTrackers = userId => {
  log('setUserIdForTrackers', userId, { nonInteraction: true });

  // https://support.google.com/analytics/answer/3123666
  window.ga && window.ga('set', 'userId', userId, { nonInteraction: true });

  // https://github.com/snowplow/snowplow/wiki/1-General-parameters-for-the-Javascript-tracker#set-user-id
  window.snowplow && window.snowplow('setUserId', userId);
};

export const pushPageView = pathname => {
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
  window.ga && window.ga('set', 'page', pathname || '/');

  // https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiBasicConfiguration#_gat.GA_Tracker_._trackPageview
  window.ga && window.ga('send', 'pageview');

  // https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#trackPageView
  window.snowplow && window.snowplow('trackPageView');
};

let _firstPageViewSent = false;

export const fireEvent = (
  { category, action, label, property, value, skipGA },
  options
) => {
  log(
    'fireEvent',
    { category, action, label, property, value },
    {
      ...options,
      nonInteraction: !_firstPageViewSent
    }
  );

  if (!skipGA)
    // https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiEventTracking#_gat.GA_EventTracker_._trackEvent
    window.ga &&
      window.ga('send', 'event', category, action, label, value, {
        ...options,
        nonInteraction: !_firstPageViewSent
      });

  if (category === 'page' && action === 'view') _firstPageViewSent = true;

  // https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#custom-structured-events
  window.snowplow &&
    window.snowplow(
      'trackStructEvent',
      category,
      action,
      label,
      property,
      value
    );
};

import React from 'react';
import { withConfig } from 'hoc';

import styles from './styles.module.css';

class TTSFocusableDescription extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { id, className, focus, children, config, style } = this.props;
    const isFocusable = config.focusTextWhenTTS && config.getIsTTSEnabled();

    return (
      <div
        data-cy={id}
        style={style}
        className={className + ' ' + styles.main}
        tabIndex={isFocusable ? 1 : -1}
        ref={node => {
          console.log(node && focus && isFocusable)
          if (node && focus && isFocusable) {
            setTimeout(() => {
              node.focus();
            }, 25);
          }
        }}
      >
        {children}
      </div>
    );
  }
}

export default withConfig(TTSFocusableDescription);

import React from 'react';
import Button from 'components/Button';
import TTSFocusableDescription from 'components/TTSFocusableDescription';
import styles from './styles.module.css';
import { toTitleCase } from "utils/formatters";

export const AudioTracksMenu = props => {
  const renderRightOptions = () => {
    const { audioTracks, selectedAudioTrack, updateCurrentTrack } = props;
    return audioTracks.map((track, index) => (
      <Button
        key={index}
        noTransition
        className={styles.optionButton}
        active={track.lang === selectedAudioTrack.lang}
        onClick={() => {
          updateCurrentTrack(track);
        }}
      >
        {toTitleCase(track.lang)}
      </Button>
    ));
  };

  const { onClose } = props;

  return (
    <div className={styles.fullBleed}>
      <div className={styles.main}>
        <div className={styles.left}>
          <Button
            focus
            className={styles.optionButton}
            noTransition
            borderPosition="Right"
            active
          >
            Language
          </Button>
        </div>
        <div className={styles.right}>{renderRightOptions()}</div>
        <TTSFocusableDescription
          className={styles.bottomRight}
          onClick={onClose}
        >
          Press the back button on your remote to hide this menu
        </TTSFocusableDescription>
      </div>
    </div>
  );
};

export default AudioTracksMenu;

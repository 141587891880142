import debug from 'debug';

import { addLog } from 'reducers/Log/reducer';

const root = debug('cstv');

let _store;

const init = store => (_store = store);

const getStore = () => _store;

const extend = name => {
  const logger = root.extend(name);
  return (text, ...params) => {
    try {
      getStore() &&
        getStore().dispatch(
          addLog({
            ts: Date.now(),
            namespace: logger.namespace.replace('cstv:', ''),
            text,
            params
          })
        );
    } catch (e) {
      logger(e);
    }

    logger(text, ...params);
  };
};

export default { init, extend };

import appboy from 'appboy-web-sdk';
import logger from 'utils/logger';

const log = logger.extend('braze');

const STAGING_BRAZE_TOKEN = '18802432-55b2-4c77-bd63-2ed235c29399';
const PROD_BRAZE_TOKEN = 'ff7435d4-58c8-4ac3-94e9-afd25698f2b8';

const BRAZE_TOKEN =
  process.env.NODE_ENV === 'production'
    ? PROD_BRAZE_TOKEN
    : STAGING_BRAZE_TOKEN;

const initBrazeLib = () => {
  log('init and open session');

  appboy.initialize(BRAZE_TOKEN, {
    baseUrl: 'https://cursa.iad-03.braze.com/api/v3'
  });

  appboy.openSession();
};

const logCustomEvent = (eventName, payload) => {
  log('logCustomEvent', eventName, payload);
  appboy.logCustomEvent(eventName, payload);
};

const setUser = user => {
  log('setUser');
  if (user.external_id) {
    appboy.changeUser(user.external_id);
  } else {
    log(`no externalId found for user ${user.email}`);
  }
};

export default { initBrazeLib, logCustomEvent, setUser };

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { intlLocale, intlMessages } from 'utils/intl';

import App from 'components/App';

const Root = ({ store }) => (
  <React.StrictMode>
    <IntlProvider locale={intlLocale} messages={intlMessages}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Provider store={store}>
          <Route component={App} />
        </Provider>
      </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>
);

export default Root;

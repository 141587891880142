import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Grid from 'components/Grid';
import Text from 'components/Text';
import { fireEvent } from 'utils/analytics';
import Spinner from 'components/Spinner';

import {
  getFetchingBookmarked,
  fetchBookmarked,
  bookmarkedMedia,
} from 'reducers/Medias/reducer';

class Bookmarks extends React.Component {
  componentDidMount() {
    this.props.fetchBookmarked();

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'watchlist',
    });
  }

  render() {
    if (this.props.isFetching) return <Spinner />;

    if (!this.props.bookmarkedMedia || this.props.bookmarkedMedia.length === 0)
      return (
        <>
          <FormattedMessage id="smarttv:search_no_results_found" />{' '}
          <FormattedMessage id="smarttv:account_sidenav_watchlist_button" />
        </>
      );

    return (
      <Grid
        media={this.props.bookmarkedMedia.slice(0, 24)}
        rowSize={3}
        noMargin
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: getFetchingBookmarked(state),
  bookmarkedMedia: bookmarkedMedia(state),
});

const mapDispatchToProps = {
  fetchBookmarked,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookmarks);

import React from 'react';
import raw from 'raw.macro';
import ReactMarkdown from 'react-markdown';

import TextView from './';

const privacy = raw('./privacy.md');

export default () => {
  return (
    <TextView>
      <ReactMarkdown source={privacy} />
    </TextView>
  );
};

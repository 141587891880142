import { takeLatest, call, put } from 'redux-saga/effects';

import imageQueryBuilder from 'utils/imageQueryBuilder';

import {
  gotVideo,
  gotRelatedVideos,
  failedVideo,
  gotBookmarked,
  gotHistory,
  gotWatching,
  updateRating,
  FETCH_VIDEO,
  FETCH_RELATED_MEDIA,
  CLEARVOTE_MEDIA,
  UPVOTE_MEDIA,
  DOWNVOTE_MEDIA,
  BOOKMARK_MEDIA,
  FETCH_HISTORY,
  FETCH_WATCHING,
  FETCH_BOOKMARKED,
  FETCH_RECENTLY_ADDED,
  gotRecentlyAdded,
  FETCH_RECOMMENDED,
  gotRecommended,
} from './reducer';

import { fireEvent } from 'utils/analytics';
import braze from 'utils/braze';
import request from 'utils/request';
import qpb from 'utils/queryParamsBuilder';
import { getConfig } from 'config';

export function* getVideoData(action) {
  try {
    let { encodingsParams } = getConfig();

    const limitToHd = localStorage.getItem('limitToHD') === 'true';
    if (limitToHd)
      encodingsParams.encodingsMaxHeight = Math.min(
        encodingsParams.encodingsMaxHeight,
        1080
      );

    const paramsObject = {
      forceCDN: action.forceCDN,
      ...encodingsParams,
    };

    const paramsString = qpb(paramsObject);

    const data = yield call(
      request,
      `${process.env.REACT_APP_API_ROOT}/v1/media/${action.videoId}${paramsString}`
    );
    const video = data.data;
    yield put(gotVideo(video));
  } catch (e) {
    yield put(failedVideo(e));
  }
}

export function* fetchRelatedMedias(action) {
  try {
    const data = yield call(
      request,
      //&collections=true
      `${process.env.REACT_APP_API_ROOT}/v1/media?filterBy=recommended&term=${
        action.videoId
      }&${imageQueryBuilder()}`
    );
    const media = data.data;
    yield put(gotRelatedVideos(action.videoId, media));
  } catch (e) {
    console.error(e);
  }
}

export function* postBookmarkMedia(action) {
  try {
    const isSeries = !action.mediaKey.includes('media');
    const apiType = isSeries ? 'collection' : 'media';
    const id = action.mediaKey.substr(action.mediaKey.indexOf('_') + 1);
    yield call(
      request,
      `${process.env.REACT_APP_API_ROOT}/v1/user_${apiType}?${apiType}_id=${id}&is_bookmarked=${action.bookmark}`,
      { method: 'POST' }
    );

    const eventName = action.bookmark ? 'bookmark' : 'unbookmark';

    isSeries
      ? braze.logCustomEvent(`user_series_${eventName}`, {
          series_id: id,
        })
      : braze.logCustomEvent(`user_media_${eventName}`, {
          media_id: id,
        });

    fireEvent({
      category: `user_${isSeries ? 'series' : 'media'}`,
      action: eventName,
      label: action.mediaTitle,
      value: id,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* postClearvoteMedia(action) {
  try {
    const updatedMedia = yield call(
      request,
      `${process.env.REACT_APP_API_ROOT}/v1/user_media?media_id=${action.videoId}&rating=0`,
      { method: 'POST' }
    );
    yield put(
      updateRating({
        videoId: action.videoId,
        ratingCount: updatedMedia.message.media.num_ratings,
        ratingPercentage: updatedMedia.message.media.rating_percentage,
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* postUpvoteMedia(action) {
  try {
    const updatedMedia = yield call(
      request,
      `${process.env.REACT_APP_API_ROOT}/v1/user_media?media_id=${action.videoId}&rating=5`,
      { method: 'POST' }
    );
    yield put(
      updateRating({
        videoId: action.videoId,
        ratingCount: updatedMedia.message.media.num_ratings,
        ratingPercentage: updatedMedia.message.media.rating_percentage,
      })
    );

    braze.logCustomEvent('user_media_thumbs_up', {
      media_id: action.videoId,
    });

    fireEvent({
      category: `user_media`,
      action: 'thumbs_up',
      label: action.title,
      value: action.videoId,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* postDownvoteMedia(action) {
  try {
    const updatedMedia = yield call(
      request,
      `${process.env.REACT_APP_API_ROOT}/v1/user_media?media_id=${action.videoId}&rating=1`,
      { method: 'POST' }
    );
    yield put(
      updateRating({
        videoId: action.videoId,
        ratingCount: updatedMedia.message.media.num_ratings,
        ratingPercentage: updatedMedia.message.media.rating_percentage,
      })
    );

    braze.logCustomEvent('user_media_thumbs_down', {
      media_id: action.videoId,
    });

    fireEvent({
      category: `user_media`,
      action: 'thumbs_down',
      label: action.title,
      value: action.videoId,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* getBookmarked() {
  try {
    let allMedia = [];
    let page = 1;
    let doneFetching = false;
    while (!doneFetching) {
      const media = yield call(
        request,
        `${
          process.env.REACT_APP_API_ROOT
        }/v1/media?filterBy=bookmarked&collections=true&limit=20&page=${page}&${imageQueryBuilder()}`
      );
      allMedia = [...allMedia, ...media.data];
      page += 1;
      doneFetching =
        media.paginator.current_page >= media.paginator.total_pages;
    }
    yield put(gotBookmarked(allMedia));
  } catch (e) {
    yield put(failedVideo(e.message));
  }
}

export function* fetchHistory() {
  try {
    let allMedia = [];
    let page = 1;
    const media = yield call(
      request,
      `${
        process.env.REACT_APP_API_ROOT
      }/v1/media?filterBy=history&collections=true&limit=20&page=${page}&${imageQueryBuilder()}`
    );
    allMedia = [...allMedia, ...media.data];
    yield put(gotHistory(allMedia));
  } catch (e) {
    yield put(failedVideo(e.message));
  }
}

export function* fetchWatching() {
  try {
    let allMedia = [];
    let page = 1;
    let doneFetching = false;
    while (!doneFetching) {
      const media = yield call(
        request,
        `${
          process.env.REACT_APP_API_ROOT
        }/v1/media?filterBy=watching&collections=true&limit=20&page=${page}&${imageQueryBuilder()}`
      );
      allMedia = [...allMedia, ...media.data];
      page += 1;
      doneFetching =
        media.paginator.current_page >= media.paginator.total_pages;
    }
    yield put(gotWatching(allMedia));
  } catch (e) {
    yield put(failedVideo(e.message));
  }
}

export function* fetchRecommendedVideos(action) {
  try {
    const data = yield call(
      request,
      `${
        process.env.REACT_APP_API_ROOT
      }/v1/media?filterBy=user-recommended&collections=true&${imageQueryBuilder()}`
    );
    const media = data.data;
    yield put(gotRecommended(media));
  } catch (e) {
    console.error(e);
  }
}

export function* fetchRecentlyAdded(action) {
  try {
    const data = yield call(
      request,
      `${
        process.env.REACT_APP_API_ROOT
      }/v1/media?collections=true&${imageQueryBuilder()}`
    );
    const media = data.data;
    yield put(gotRecentlyAdded(media));
  } catch (e) {
    console.error(e);
  }
}

export function* getVideo() {
  yield takeLatest(FETCH_VIDEO, getVideoData);
}

export function* getRelatedVideos() {
  yield takeLatest(FETCH_RELATED_MEDIA, fetchRelatedMedias);
}

export function* clearvoteMedia() {
  yield takeLatest(CLEARVOTE_MEDIA, postClearvoteMedia);
}

export function* upvoteMedia() {
  yield takeLatest(UPVOTE_MEDIA, postUpvoteMedia);
}

export function* downvoteMedia() {
  yield takeLatest(DOWNVOTE_MEDIA, postDownvoteMedia);
}

export function* bookmarkMedia() {
  yield takeLatest(BOOKMARK_MEDIA, postBookmarkMedia);
}

export function* fetchBookmarkedMedia() {
  yield takeLatest(FETCH_BOOKMARKED, getBookmarked);
}

export function* getHistory() {
  yield takeLatest(FETCH_HISTORY, fetchHistory);
}

export function* getWatching() {
  yield takeLatest(FETCH_WATCHING, fetchWatching);
}

export function* getRecommendedVideos() {
  yield takeLatest(FETCH_RECOMMENDED, fetchRecommendedVideos);
}

export function* getRecentlyAdded() {
  yield takeLatest(FETCH_RECENTLY_ADDED, fetchRecentlyAdded);
}

export default [
  getVideo,
  getRelatedVideos,
  getWatching,
  clearvoteMedia,
  upvoteMedia,
  downvoteMedia,
  bookmarkMedia,
  getHistory,
  fetchBookmarkedMedia,
  getRecommendedVideos,
  getRecentlyAdded,
];

import React from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import Text from 'components/Text';
import ScrollArea from 'components/ScrollArea';
import GroupCarousel from 'components/GroupCarousel';

import braze from 'utils/braze';
import { fireEvent } from 'utils/analytics';

import {
  fetchFullCategory,
  getCategoryById,
  getIsFetching,
  getMediaForCategoryId
} from 'reducers/Categories/reducer';

import { fetchGroupPage } from 'reducers/Home/reducer';

import styles from './styles.module.css';

class Browse extends React.Component {
  state = {
    media: [],
    viewedMediaCards: new Set([])

  };

  categoryNeedsLoad(category) {
    return (
      !category ||
      !category.subcategories ||
      category.subcategories.filter(
        sc =>
          this.props.getCategoryMedia(sc.id) &&
          this.props.getCategoryMedia(sc.id).length > 0
      ).length === 0
    );
  }

  componentDidMount() {
    braze.logCustomEvent(`page_view_category`, {
      category_id: this.props.match.params.id
    });
    fireEvent({
      category: 'page',
      action: 'view',
      label: 'category',
      value: this.props.match.params.id
    });

    if (
      this.props.selectedCategory &&
      this.categoryNeedsLoad(this.props.selectedCategory)
    ) {
      this.props.fetchFullCategory(this.props.selectedCategory.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        viewedMediaCards: new Set([])
      });
      braze.logCustomEvent(`page_view_category`, {
        category_id: this.props.match.params.id
      });
      fireEvent({
        category: 'page',
        action: 'view',
        label: 'category',
        value: this.props.match.params.id
      });
    }

    if (
      this.props.selectedCategory &&
      this.categoryNeedsLoad(this.props.selectedCategory) &&
      !prevProps.isFetchingSubcategories
    ) {
      prevProps.fetchFullCategory(this.props.selectedCategory.id);
    }
  }

  addViewedCard = (id) => {
    this.setState({
      viewedMediaCards: this.state.viewedMediaCards.add(id),
    });
  };

  ignoreEventCheck = (mediaId) => {
    this.state.viewedMediaCards.has(mediaId)
  };

  renderSubcategory = (sc, index) => {
    const media = this.props.getCategoryMedia(sc.id);

    if (media.length > 0) {
      return (
        <GroupCarousel
          focus={index === 0}
          key={sc.id}
          group={sc}
          media={media}
          fetchMoreAction={this.props.fetchGroupPage}
          analyticsData={(mediaId, index) => {
            return {
              category: 'category_page_thumbnail_impression',
              label: sc.name,
              property: mediaId,
              value: index,
              analyticsCallback: this.addViewedCard,
              ignoreEventCheck: this.ignoreEventCheck
            }
          }}
        />
      );
    }
  };

  render() {
    const { isFetchingSubcategories, selectedCategory } = this.props;

    if (!selectedCategory) return null;

    return (
      <>
        <Text title className={styles.categoryName} id="category-name">
          {selectedCategory.label}
        </Text>
        {isFetchingSubcategories ? (
          <Spinner />
        ) : (
          <ScrollArea>
            {selectedCategory.subcategories.map(this.renderSubcategory)}
          </ScrollArea>
        )}
      </>
    );
  }
}

// Browse.propTypes = {
//   params: PropTypes.object,
//   fetchFullCategory: PropTypes.func.isRequired
// };

const mapStateToProps = (state, { match }) => ({
  selectedCategory: getCategoryById(state)(match.params.id),
  isFetchingSubcategories: getIsFetching(state),
  getCategoryMedia: getMediaForCategoryId(state)
});

const mapDispatchToProps = {
  fetchFullCategory,
  fetchGroupPage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Browse);

/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import styles from './styles.module.css';

class ImgFader extends React.Component {
  state = {
    loadedImagesUrls: [],
    prevIndex: -1,
    currentIndex: 0
  };

  startBackgroundCycle = () => {
    const { loadedImagesUrls } = this.state;

    clearInterval(this.cycleBgnd);

    this.setState({ prevIndex: 0 }); //fades the first image in

    if (loadedImagesUrls.length > 1)
      this.cycleBgnd = setInterval(() => {
        if (this.props.freeze) return;

        const newIndex =
          (this.state.currentIndex + 1) % loadedImagesUrls.length;
        this.setState({
          prevIndex: this.state.currentIndex,
          currentIndex: newIndex,
          fading: false
        });

        this.transition = setTimeout(
          () =>
            this.setState({
              fading: true
            }),
          this.props.interval - this.props.fadeTime
        );
      }, this.props.interval);
  };

  preloadImages = urls => {
    urls.forEach((url, index, array) => {
      this[`imageLoader${index}`] = new Image();
      try {
        this[`imageLoader${index}`].onload = e => {
          const newUrls = [...this.state.loadedImagesUrls, url];
          this.setState({
            loadedImagesUrls: newUrls
          });
          this[`imageLoader${index}`] = null;

          if (newUrls.length === array.length) {
            this.startBackgroundCycle();
          }
        };
        this[`imageLoader${index}`].src = url;
      } catch (e) {
        const newUrls = [...this.state.loadedImagesUrls, url];
        this.setState({
          loadedImagesUrls: newUrls
        });
        this[`imageLoader${index}`] = null;

        if (newUrls.length === array.length) {
          this.startBackgroundCycle();
        }
      }
    });
  };

  componentDidMount() {
    this.preloadImages(this.props.src);
  }

  componentWillUnmount() {
    clearInterval(this.cycleBgnd);
    clearTimeout(this.transition);

    for (let i = 0; i < this.props.src.length; i++) {
      try {
        if (!!this[`imageLoader${i}`]) this[`imageLoader${i}`].onload = null;
      } catch (e) {
        console.log(e);
      }
    }
  }

  render() {
    const { style, className, fadeTime } = this.props;
    const { fading, loadedImagesUrls, currentIndex, prevIndex } = this.state;

    return (
      <div className={className} style={style}>
        <img
          className={styles.fill}
          src={loadedImagesUrls[prevIndex]}
          style={{
            transition: `${fadeTime}ms ease-in-out`,
            opacity: prevIndex === -1 ? 0 : 1
          }}
        />
        <img
          className={styles.fill}
          src={loadedImagesUrls[currentIndex]}
          style={{
            transition: fading ? `${fadeTime}ms ease-in-out` : null,
            opacity: fading ? 1 : 0
          }}
        />
      </div>
    );
  }
}

ImgFader.defaultProps = {
  interval: 5000,
  fadeTime: 1500
};

export default ImgFader;

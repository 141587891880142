import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  getActivationCode,
  getAQrBase64,
  fetchActivationCode,
  checkAuthStatus,
  getIsLoading,
  getAuthStatusError,
} from 'reducers/AuthManager/reducer';
import TTS from 'components/TTSFocusableDescription';
import { withConfig } from 'hoc';

const LoginWithLink = ({ intl, config, ...props }) => {
  const [clientIdentifier, setClientIdentifier] = useState('');

  useEffect(() => {
    fetchActivationCode();
  }, []);

  useEffect(() => {
    if (props.authStatusError) {
      fetchActivationCode();
    }
  }, [props.authStatusError]);

  const fetchActivationCode = () => {
    const clientIdentifier = uuidv4();
    setClientIdentifier(clientIdentifier);
    props.fetchActivationCode(clientIdentifier);
  };

  useEffect(() => {
    let interval;
    if (props.activationCode) {
      interval = setInterval(() => {
        props.checkAuthStatus({
          clientIdentifier,
          deviceLinkCode: props.activationCode,
        });
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [props.activationCode]);

  return props.loading ? null : (
    <div className={styles.activateBlock}>
      <div className={styles.flexBlock}>
        <TTS focus={config.getIsTTSEnabled()} className={styles.goto}>
          <FormattedMessage id="smarttv:signin_general_header2" />
        </TTS>
      </div>
      <div className={styles.flexBlock}>
        <div className={styles.codeHolder}>
          <TTS className={styles.goto}>
            <FormattedMessage
              id="smarttv:signin_another_device_section1_step1"
              values={{
                signInUrl: (
                  <div aria-label="curiosity dot tv slash activate">
                    curiosity.tv/activate
                  </div>
                ),
              }}
            />
          </TTS>
          <br />
          <TTS>
            <FormattedMessage id="smarttv:signin_another_device_section1_step2" />
            {props.activationCode && (
              <div
                className={styles.code}
                aria-label={props.activationCode.split('').join(', ')}
              >
                {props.activationCode}
              </div>
            )}
          </TTS>
        </div>
        <div className={styles.dividerContainer}>
          <div className={styles.divider} />
          <span>
            <FormattedMessage id="smarttv:signin_another_device_divider_label" />
          </span>
          <div className={styles.divider} />
        </div>
        <TTS className={styles.qrHolder}>
          <FormattedMessage id="smarttv:signin_another_device_section2_qr_code_header" />
          <div className={styles.qrImage}>
            <img
              src={`data:image/svg+xml;base64,${props.qrBase64}`}
              alt={intl.formatMessage({
                id: 'smarttv:signin_another_device_section2_qr_code_alt_text',
              })}
            />
          </div>
        </TTS>
      </div>
    </div>
  );
};

LoginWithLink.defaultProps = {
  activationCode: '',
};

const mapStateToProps = state => ({
  activationCode: getActivationCode(state),
  qrBase64: getAQrBase64(state),
  loading: getIsLoading(state),
  authStatusError: getAuthStatusError(state),
});

const mapDispatchToProps = {
  fetchActivationCode,
  checkAuthStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withConfig(injectIntl(LoginWithLink)));

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import Spinner from 'components/Spinner';
import Text from 'components/Text';
import ScrollArea from 'components/ScrollArea';
import Button from 'components/Button';
import ImgFader from 'components/ImgFader';
import Progress from 'components/Progress';
import TTSFocusableDescription from 'components/TTSFocusableDescription';
import { withAuth, withConfig } from 'hoc';
import { getActiveAutoplay } from 'reducers/PlayerSettings/reducer';
import { getGroups } from 'reducers/Home/reducer';
import Player from 'components/Player';
import MediaProgressComponent from 'components/MediaProgress';
import { fireEvent } from 'utils/analytics';
import {detailsPageSecondsToHoursAndMinutes}from 'utils/formatters'

import * as Icons from 'components/Icons';

import braze from 'utils/braze';

import UpNext from './UpNext';
import EpisodeButton from './EpisodeButton';
import Related from './Related';
import styles from './styles.module.css';

import {
  fetchVideo,
  getFetchingMedia,
  getMediaByKey,
  getSuggestedResumeTimeAndPercentage,
  getUserMedia,
  getIsMediaBookmarked,
  bookmarkMedia,
  getMediaRating,
  upvoteMedia,
  downvoteMedia,
  clearvoteMedia,
} from 'reducers/Medias/reducer';
import { getCollections } from 'reducers/Collections/reducer';

import {
  fetchSeries,
  getFetching,
  getSeriesById,
  getSeriesVideosForId,
} from 'reducers/Series/reducer';
import { getUserData } from 'reducers/Profile/reducer';

class Media extends React.Component {
  state = {};

  get hasFullVideos() {
    return this.props.isLogged || this.props.config.hasFreeAccess;
  }

  handleBackPress = () => {
    if (this.props.wasHomePageLoaded) this.props.history.goBack();
    else {
      this.props.history.replace('/');
    }
  };

  renderMediaUI = () => {
    const {
      media,
      seriesEpisodes,
      history,
      loadingMedia,
      rating,
      isLogged,
      bookmarkMedia,
      isBookmarked,
      getResumeTime,
      config,
      isFetchingSeriesInfo,
      intl,
    } = this.props;

    const { focusedIndex } = this.state;

    const progress = media ? getResumeTime(media.key) : {};

    let { quality } = media;
    // if (quality.toLowerCase() === '4k') {
    //   if (!config.getIsUhdCapable()) quality = '4K on compatible devices';
    //   if (!this.hasFullVideos) quality = '4K for logged in users';
    //   // if (isLimitedToHd) quality = '4K, limited to HD in the settings';
    // }

    const meta = `${
      media.isSeries
        ? intl.formatMessage(
            {
              id: 'smarttv:common_media_episode_count_text_and_aria_label',
            },
            { n: media.videosCount }
          )
        : detailsPageSecondsToHoursAndMinutes(media.duration)
    } , ${quality}`;

    const showButtons =
      !loadingMedia && !isFetchingSeriesInfo && media.isFullyFetched;

    const encoding = !seriesEpisodes && media.encodings && media.encodings[0];
    return (
      <div
        id="media-page"
        className={
          (this.state.sideStills ? '' : styles.darkGradient) +
          ' ' +
          styles.fullBleed
        }
      >
        <div
          className={styles.left}
          style={{
            opacity: showButtons ? 1 : 0,
          }}
        >
          <TTSFocusableDescription focus>
            <Text className={styles.mediaCaption} title id="title-label">
              {media.title}
            </Text>
            {(!media.isSeries &&
              progress?.progressPercentage <= 95 &&
              progress?.progressPercentage > 0) ||
              (progress?.progressPercentage <= 95 &&
                progress?.progressPercentage > 0 &&
                media.seriesId) ? (
              <>
                <div className={styles.progressBlock}>
                  <div className={styles.progressCaption}>
                    {/* {progress.progressCaption} */}
                  </div>
                  <div className={styles.rangeHolder}>
                    <MediaProgressComponent
                      className={styles.range}
                      progressPercentage={progress.progressPercentage}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </TTSFocusableDescription>
          <TTSFocusableDescription>
            <Text id="desc-label">{media.description}</Text>
          </TTSFocusableDescription>
          <TTSFocusableDescription key={media.closedCaptions.length}>
            <>
              <Text small>{meta}</Text>
              {/* {!media.isSeries && media.closedCaptions.length === 0 && (
                <Text small>No subtitles available for this program.</Text>
              )} */}
            </>
          </TTSFocusableDescription>
        </div>
        {showButtons && (
          <div className={styles.bottomLeftButtons}>
            {encoding ? (
              <>
                <Button
                  id="play-btn"
                  focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
                  onClick={this.onClickPlay}
                >
                  <div className={styles.buttonWithIcon} data-cy="play">
                    <Icons.Play />
                    {intl.formatMessage({
                      id: this.hasFullVideos
                        ? 'smarttv:common_media_watch_button'
                        : 'smarttv:common_media_watch_preview_button',
                    })}
                  </div>
                </Button>
              </>
            ) : (
              !media.isSeries && (
                <Button disabled>
                  <Icons.Play />
                  {intl.formatMessage({
                    id: this.hasFullVideos
                      ? 'smarttv:common_media_watch_button'
                      : 'smarttv:common_media_watch_preview_button',
                  })}
                </Button>
              )
            )}
            {isLogged ? (
              <>
                <div key="yo">
                  {/* the this.state.focusBookmarkButton and key are needed to trigger TTS */}
                  <Button
                    id={isBookmarked ? 'unbookmark-btn' : 'bookmark-btn'}
                    key={isBookmarked ? 'a' : 'b'}
                    focus={this.state.focusBookmarkButton}
                    onClick={() => {
                      bookmarkMedia(media.key, media.title, !isBookmarked);
                      this.setState({ focusBookmarkButton: true });
                      setTimeout(() => {
                        //so bookmark button is not focused on next media page displayed
                        this.setState({ focusBookmarkButton: false });
                      }, 100);
                    }}
                  >
                    <div className={styles.buttonWithIcon}>
                      {isBookmarked ? <Icons.Check /> : <Icons.Plus />}
                      {intl.formatMessage(
                        { id: 'smarttv:common_media_bookmark_button' },
                        { isBookmarked }
                      )}
                    </div>
                  </Button>
                </div>
                {!seriesEpisodes && (
                  <div key="yo2">
                    {/* the this.state.focusLikeButton and key are needed to trigger TTS */}
                    <Button
                      id={
                        rating === 1
                          ? `disliked-btn`
                          : rating === 5
                            ? `liked-btn`
                            : `like-btn`
                      }
                      focus={this.state.focusLikeButton}
                      key={rating === 1 ? 'x' : rating === 5 ? 'y' : 'z'}
                      onClick={() => {
                        this.rateMedia();
                        this.setState({ focusLikeButton: true });
                        setTimeout(() => {
                          //so like button is not focused on next media page displayed
                          this.setState({ focusLikeButton: false });
                        }, 100);
                      }}
                    >
                      <div className={styles.buttonWithIcon}>
                        {rating === 1 ? (
                          <Icons.ThumbsDown />
                        ) : rating === 5 ? (
                          <Icons.ThumbsUp />
                        ) : (
                          <Icons.Question />
                        )}
                        {intl.formatMessage(
                          { id: 'smarttv:common_media_rate_button' },
                          { rating }
                        )}
                      </div>
                    </Button>
                  </div>
                )}
              </>
            ) : (
              !media.isSeries &&
              !this.hasFullVideos && (
                <Button
                  id="login-btn"
                  focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
                  onClick={() =>
                    history.push('/login?redirect=media_' + media.id)
                  }
                >
                  <div className={styles.buttonWithIcon}>
                    <FormattedMessage id="smarttv:common_sign_in_button" />
                  </div>
                </Button>
              )
            )}
            <Button
              id="back-btn"
              blockDown
              onClick={this.handleBackPress}
              focusDownOverride="#last-episode"
              focus={!media.isSeries && !encoding}
            >
              <div className={styles.buttonWithIcon}>
                <Icons.Back />
                <FormattedMessage id="smarttv:legacy_go_back" />
              </div>
            </Button>
          </div>
        )}
        {seriesEpisodes && (
          <ScrollArea
            noShadow
            noLazyLoading
            paddingTop={15}
            className={styles.right}
          >
            {seriesEpisodes.map((ep, index, array) => (
              <EpisodeButton
                key={index}
                ep={ep}
                index={index + 1}
                onClick={() =>
                  this.setState({
                    lastViewedIdx: index,
                  })
                }
                isFocused={focusedIndex === index}
                analyticsData={{
                  category: 'series_page_thumbnail_impression',
                  label: media.id,
                  property: `media_${ep.id}`,
                  value: index,
                }}
              />
            ))}
          </ScrollArea>
        )}

        {this.getKeyframes(media) && !media.isSeries && config.lowMemoryDevice && (
          <div className={styles.sideImages}>
            {this.getKeyframes(media)
              .slice(0, 2)
              .map(still => (
                <ImgFader
                  key={still}
                  src={[still]}
                  style={{
                    position: 'relative',
                    height: '50%',
                    marginBottom: '5%',
                  }}
                />
              ))}
          </div>
        )}
      </div>
    );
  };

  rateMedia = () => {
    const { rating, media } = this.props;

    if (rating === 0 || rating === null) {
      this.props.upvoteMedia(media.id, media.title);
    } else if (rating === 5) {
      this.props.downvoteMedia(media.id, media.title);
    } else {
      this.props.clearvoteMedia(media.id);
    }
  };

  getKeyframes = media => {
    let keyframes = [];
    if (!media) {
      keyframes = [];
    } else if (media.keyframes && media.keyframes.length) {
      keyframes = media.keyframes;
    } else {
      keyframes = media.keyframeImageUrl ? [media.keyframeImageUrl] : [];
    }
    return keyframes;
  };

  componentDidUpdate(prevProps) {
    const { media, seriesEpisodes, getResumeTime } = this.props;
    const that = this;
    if (
      (!prevProps.media && this.props.media) ||
      (!prevProps.media?.isSeries && this.props.media?.isSeries)
    ) {
      prevProps.onFinishedLoading();
      if (media.isSeries) {
        setTimeout(() => {
          try {
            const lastViewedIdx =
              this.state.lastViewedIdx ||
              seriesEpisodes.reverse().findIndex(el => {
                const progress = getResumeTime(el.key);
                return (
                  progress.progressPercentage > 0 &&
                  progress.progressPercentage < 95
                );
              });
            if (lastViewedIdx > -1) {
              const focusedIndex = this.state.lastViewedIdx
                ? lastViewedIdx
                : seriesEpisodes.length - lastViewedIdx - 1;
              that.setState({
                focusedIndex,
                lastViewedIdx: null,
              });
              document
                .getElementById(
                  `episode-${seriesEpisodes.length - lastViewedIdx}-btn`
                )
                .focus();
            }
          } catch (e) {
            // this seems to happen all the time @ValentinePolessky
          }
        }, 100);
      }
    }

    //the following happens when navigating from video back to its
    // series after initial deep link to playback
    if (prevProps.media && !this.props.media) {
      this.props.fetchSeries(this.props.match.params.id);
    }

    if (
      prevProps.media &&
      this.props.media &&
      prevProps.media.id !== this.props.media.id &&
      !this.props.media.isSeries
    ) {
      this.props.fetchVideo(this.props.match.params.id);
    }
  }

  componentDidMount() {
    const { media, match, fetchVideo, seriesEpisodes, fetchSeries, config } =
      this.props;

    if (!media) {
      // navigated from featured carousel
      if (match.params.type === 'series') {
        fetchSeries(match.params.id, null, config.forceCDN);
      } else {
        fetchVideo(match.params.id, config.forceCDN);
      }
    } else {
      if (media.isSeries && !seriesEpisodes) {
        fetchSeries(media.id, null, null, config.forceCDN);
      } else if (
        !media.isSeries &&
        (!media.encodings || media.encodings.length === 0 || !media.isFullyFetched)
      ) {
        fetchVideo(media.id, config.forceCDN);
      }
    }

    fireEvent({
      category: 'page',
      action: 'view',
      label: match.params.type.replace('media', 'video'),
      value: match.params.id,
    });
  }

  onClickPlay = () => {
    const progress = this.props.getResumeTime(this.props.media.key);
    if (progress.progressPercentage >= 95) {
      this.startPlayingFromBeginning();
    } else if (
      progress.progressPercentage > 0 &&
      progress.progressPercentage < 95
    ) {
      this.props.history.push(
        `/media/${this.props.media.id}/resume${this.props.playlistId ? `?playlist=${this.props.playlistId}` : ''
        }`
      );
    } else {
      this.startPlaying();
    }
  };

  startPlaying = replace => {
    replace
      ? this.props.history.replace(
        `/media/${this.props.media.id}/play${this.props.playlistId ? `?playlist=${this.props.playlistId}` : ''
        }`
      )
      : this.props.history.push(
        `/media/${this.props.media.id}/play${this.props.playlistId ? `?playlist=${this.props.playlistId}` : ''
        }`
      );
  };

  startPlayingFromBeginning = () => {
    this.props.history.replace(
      `/media/${this.props.media.id}/play/fromStart${this.props.playlistId ? `?playlist=${this.props.playlistId}` : ''
      }`
    );
  };

  closePlayer = () => {
    const { media, history, isActiveAutoplay } = this.props;
    const isLongMedia = media?.duration > 900;

    if (media) {
      braze.logCustomEvent('video_playback_stop', {
        media_id: media.id,
      });

      if (
        !(!isLongMedia && isActiveAutoplay) ||
        !this.nextVideo ||
        !this.hasFullVideos
      ) {
        if (this.hasFullVideos) {
          history.goBack();
          // this.renderMediaUI();
        } else {
          history.replace(`/media/${media.id}/trial`);
        }
      }
    }
  };

  gotRelated = relatedMedia => {
    this.setState({ relatedMedia });
  };

  // This is a check to see if current media is part of a collection
  // or an Array of Media Objects
  isMediaInCollection = (collection, mediaId) => {
    return !!collection
      ? collection.findIndex(m => m.id === mediaId) > -1
      : false;
  };

  // This is a check to see if Media is in a playlist either as an episode
  // or a level deeper as part of a series/collection in a playlist
  isMediaInPlaylist = (video, mediaId) => {
    return !!video
      ? video.objType === 'collection'
        ? this.isMediaInCollection(video.media, mediaId)
        : video.id === mediaId
      : false;
  };

  get hasSeries() {
    const { media, series } = this.props;

    return media && !!media.seriesId && series;
  }

  get seriesIndex() {
    const { series = [], media, loadingMedia } = this.props;

    if (loadingMedia) {
      return -1;
    }

    return series.findIndex(v => v.id === media.id);
  }

  get hasPlaylist() {
    const { playlistId } = this.props;

    return !!playlistId;
  }

  get playlistIndex() {
    const { playlistVideos, media } = this.props;

    if (this.loading || !media) {
      return -1;
    }

    return playlistVideos.findIndex(v => this.isMediaInPlaylist(v, media.id));
  }

  get isLastInSeries() {
    return this.seriesIndex === this.props.series?.length - 1;
  }

  get nextVideo() {
    const { series, playlistVideos } = this.props;
    const { relatedMedia } = this.state;

    if (
      this.hasPlaylist &&
      this.playlistIndex > -1 &&
      this.playlistIndex < playlistVideos.length
    ) {
      const nextIndex = this.playlistIndex + 1;

      return playlistVideos[nextIndex];
    }
    if (this.hasSeries) {
      if (!this.isLastInSeries) {
        return series[this.seriesIndex + 1];
      }
    }
    if (relatedMedia && relatedMedia[0]) {
      return relatedMedia[0];
    }
  }

  render() {
    const {
      location,
      getResumeTime,
      config,
      media,
      history,
      seriesMeta,
      userData,
      isActiveAutoplay,
      playlistId,
    } = this.props;

    const { showProgress, relatedMedia, secondsLeft, overrideEncoding } =
      this.state;

    const isLongMedia = media?.duration > 900;

    const timeToShowNext = isLongMedia ? 30 : 0;

    const showUpNext =
      this.hasFullVideos &&
      isActiveAutoplay &&
      typeof secondsLeft === 'number' &&
      secondsLeft < timeToShowNext &&
      config.useUpNextCurtain &&
      this.nextVideo;

    if (!media) {
      return <Spinner />;
    }

    const encoding =
      overrideEncoding || (media.encodings && media.encodings[0]);

    const showResumeInfoInButtons =
      config.hasBrokenTTSSupport && config.getIsTTSEnabled();

    return (
      <>
        {config.useUpNextCurtain && (
          <Related mediaId={media.id} onRelatedLoaded={this.gotRelated} />
        )}
        {!config.lowMemoryDevice && this.getKeyframes(media).length && (
          <ImgFader
            style={{
              opacity: media.isSeries ? 0.7 : 1,
              pointerEvents: 'none',
              zIndex: showUpNext ? '100' : '0',
            }}
            className={styles.fullBleed}
            src={this.getKeyframes(media)}
            freeze={location.pathname.includes('/play')}
          />
        )}
        <Route path={`/series/${media.id}`} exact render={this.renderMediaUI} />
        <Route path={`/media/${media.id}`} exact render={this.renderMediaUI} />
        <Route
          path={`/media/${media.id}/trial`}
          exact
          render={() => {
            return (
              <div className={styles.fullBleed + ' ' + styles.curtain}>
                <TTSFocusableDescription focus style={{ marginBottom: '24px' }}>
                  <Text title thin>
                    <FormattedMessage
                      id="smarttv:legacy_share_message"
                      values={{
                        title: <strong>{media.title}</strong>,
                        company: <strong>{config.signUpLink}</strong>,
                      }}
                    />
                  </Text>
                  {/* <WithPlans>
                    {({ startingPrice }) => (
                      <Text
                        thin
                        style={{
                          margin: '2em 0'
                        }}
                      >
                        Enjoy your first <strong>7 days free</strong> with plans
                        starting at ${startingPrice}/month.
                      </Text>
                    )}
                  </WithPlans> */}
                </TTSFocusableDescription>
                <Button
                  className={styles.wideButton}
                  id="go-back-btn"
                  focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
                  onClick={this.handleBackPress}
                >
                  <FormattedMessage id="smarttv:legacy_go_back" />
                </Button>
                <br />
                <Button
                  className={styles.wideButton}
                  id="log-in-btn"
                  onClick={() =>
                    history.push('/login?redirect=media_' + media.id)
                  }
                >
                  <FormattedMessage id="smarttv:common_sign_in_button" />
                </Button>
              </div>
            );
          }}
        />
        <Route
          path={`/media/${media.id}/resume`}
          exact
          render={() => {
            const progress = getResumeTime(media.key);

            let timeLeft = 0;

            if (
              progress.progressPercentage > 0 &&
              progress.progressPercentage < 95
            ) {
              if (progress.progressInSeconds > 0)
                timeLeft = Math.round(
                  (media.duration - progress.progressInSeconds) / 60
                );
              else
                timeLeft = Math.round(
                  ((media.duration / 100) *
                    (100 - progress.progressPercentage)) /
                  100
                );
            }

            return (
              <div
                className={styles.fullBleed}
                style={{
                  backgroundColor: 'rgba(11, 17, 32, 0.5)',
                }}
              >
                <div style={{ textAlign: 'center', paddingTop: '20%' }}>
                  <Button
                    id="play-resume-btn"
                    focus
                    onFocus={() => this.setState({ showProgress: true })}
                    onClick={() => this.startPlaying(true)}
                  >
                    {/* TODO: Consider adding the time remaining back. */}
                    {/* {`Resume watching${
                      showResumeInfoInButtons
                        ? ` (${timeLeft} minutes left)`
                        : ''
                    }`} */}
                    <FormattedMessage id="smarttv:common_media_resume_button" />
                  </Button>
                  <br />
                  <br />
                  <Button
                    id="play-from-beginning-btn"
                    onFocus={() => this.setState({ showProgress: null })}
                    onClick={this.startPlayingFromBeginning}
                  >
                    <FormattedMessage id="smarttv:legacy_play_from_beginning" />
                    {showResumeInfoInButtons && ` (${media.badgeContent})`}
                  </Button>
                  {!showResumeInfoInButtons && (
                    <Progress
                      className={styles.resumeProgress}
                      value={
                        showProgress
                          ? progress.progressInSeconds / media.duration
                          : 0
                      }
                    />
                  )}
                  {!showResumeInfoInButtons && (
                    <Text className={styles.resumeText}>
                      {/* TODO: minutes left */}
                      {/* {showProgress
                        ? `${timeLeft} minutes left`
                        : media.badgeContent} */}
                    </Text>
                  )}
                </div>
              </div>
            );
          }}
        />
        <Route
          path={`/media/${media.id}/play`}
          children={({ match }) => {
            // let filteredEncodings = getCompatibleEncodings({
            //   encodings: media.encodings,
            //   uhd: config.getIsUhdCapable(),
            //   maxBitrate: config.maxBitrate,
            //   config
            // });

            return (
              <>
                {match && showUpNext && (
                  <UpNext
                    ignoreCounter={!isLongMedia}
                    currentMedia={media}
                    nextMedia={this.nextVideo}
                    secondsLeft={secondsLeft}
                    onWatchNow={() => {
                      history.replace(
                        `/media/${this.nextVideo.id}/play/fromStart${playlistId ? `?playlist=${playlistId}` : ''
                        }`
                      );
                      this.setState({ secondsLeft: null });
                    }}
                    onDismiss={() => {
                      history.replace(`/media/${media.id}`);
                      this.setState({ secondsLeft: null });
                    }}
                  />
                )}
                <div
                  className={styles.fullBleed}
                  style={{
                    zIndex: config.name === 'vizio' ? 7 : null,
                    position: 'absolute',
                    backgroundColor: showUpNext
                      ? 'rgba(0, 0, 0, 0)'
                      : 'rgba(0, 0, 0, 1)',
                    transition: '500ms',
                    opacity: match ? 1 : 0,
                    pointerEvents: 'none',
                  }}
                />
                {match && (
                  <Player
                    minimized={showUpNext}
                    minimizedStyle={{
                      height: '25%',
                    }}
                    timeToShowNext={timeToShowNext}
                    onTimeLeft={secondsLeft => this.setState({ secondsLeft })}
                    onAlmostOver={secondsLeft => this.setState({ secondsLeft })}
                    videoId={media.id}
                    mediaType={
                      media.key.split('_')[0] === 'media'
                        ? 'Content'
                        : media.key.split('_')[0]
                    }
                    duration={media.duration}
                    mediaId={media.id}
                    category={media.primaryCategory}
                    subcategory={media.tags[0]}
                    isCancelled={userData ? userData.isCancelled : null}
                    userPlan={userData ? userData.stripePlan : null}
                    title={`${media.title}`}
                    closedCaptions={media.closedCaptions}
                    seriesTitle={seriesMeta ? seriesMeta.title : null}
                    resumeTime={
                      location.pathname.includes('/play/fromStart')
                        ? null
                        : getResumeTime(media.key)
                    }
                    onClosePlayer={this.closePlayer}
                    encoding={encoding}
                    onEnded={
                      this.hasFullVideos
                        ? isActiveAutoplay &&
                          config.useUpNextCurtain &&
                          this.nextVideo &&
                          isLongMedia
                          ? () => {
                            history.push(
                              `/media/${this.nextVideo.id}/play/fromStart${playlistId ? `?playlist=${playlistId}` : ''
                              }`
                            );
                            this.setState({ secondsLeft: null });
                          }
                          : this.isLastInSeries
                            ? () => {
                              history.push(`/series/${media.seriesId}`);
                              this.setState({ secondsLeft: null });
                            }
                            : null
                        : null
                    }
                    media={media}
                  />
                )}
              </>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state, { match, location }) => {
  const realType = match.params.type === 'series' ? 'collection' : 'media';
  const key = `${realType}_${match.params.id}`;
  const media = getMediaByKey(state)(key);
  const defaultPlaylistVideos = [];
  const playlistParam = location.search.replace('?playlist=', '');

  const playlistId =
    playlistParam != null && !isNaN(parseInt(playlistParam))
      ? String(playlistParam)
      : '';

  const collections = getCollections(state);

  const playlist = collections.find(collection => collection.id == playlistId);

  let playlistVideos = defaultPlaylistVideos;

  if (playlist != null) {
    playlistVideos = playlist.videosKeys.map(key => getMediaByKey(state)(key));
  }
  return {
    userData: getUserData(state),
    wasHomePageLoaded: !!getGroups(state),
    getResumeTime: getSuggestedResumeTimeAndPercentage(state),
    userMedia: getUserMedia(state)(key),
    loadingMedia: getFetchingMedia(state),
    isBookmarked: getIsMediaBookmarked(state)(key),
    rating: getMediaRating(state)(key),
    media,
    seriesMeta:
      media && media.seriesId ? getSeriesById(state)(media.seriesId) : null,
    seriesEpisodes: getSeriesVideosForId(state)(match.params.id),
    series: getSeriesVideosForId(state)(media ? media.seriesId : ''),
    isFetchingSeriesInfo: getFetching(state),
    isActiveAutoplay: getActiveAutoplay(state),
    playlistVideos,
    playlistId,
  };
};

const mapDispatchToProps = {
  fetchVideo,
  fetchSeries,
  bookmarkMedia,
  upvoteMedia,
  downvoteMedia,
  clearvoteMedia,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuth,
  withConfig,
  injectIntl
)(Media);

import React from 'react';

import Text from 'components/Text';
import Button from 'components/Button';

import styles from './styles.module.css';

export default class Controls extends React.Component {
  componentDidMount() {
    this.timer = setInterval(() => this.forceUpdate(), 800);
  }

  componentWillUnmount() {
    this.timer && clearInterval(this.timer);
  }

  render() {
    const {
      currentMedia,
      nextMedia,
      secondsLeft,
      onWatchNow,
      onDismiss,
      ignoreCounter,
      onBack,
    } = this.props;

    return (
      <div className={styles.root} data-cy="up-next">
        <div className={styles.left}>
          <Text large>YOU JUST WATCHED</Text>
          <Text large>{currentMedia.title}</Text>
        </div>
        <div className={styles.right}>
          <Text large>
            UP NEXT{' '}
            {ignoreCounter ? null : (
              <>
                IN{' '}
                <span className={styles.highlight}>
                  {Math.trunc(secondsLeft)} SECONDS
                </span>
              </>
            )}
          </Text>
          <Text title>{nextMedia.title}</Text>
          <Text>{nextMedia.description}</Text>
          <Text
            small
          >{`Available in ${nextMedia.quality}. Produced by ${nextMedia.producer} (${nextMedia.yearProduced}).`}</Text>
          <div className={styles.buttons}>
            <Button focus block className={styles.button} onClick={onWatchNow}>
              Play now
            </Button>
            <Button block className={styles.button} onClick={onDismiss}>
              Dismiss
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

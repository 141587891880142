const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// constants
const ADD_LOG = 'cs.com/Log/ADD_LOG';

// reducer

const defaultState = {
  logs: [],
  namespaces: {},
};

const logs = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_LOG:
      // filtering logic if needed
      // if (action.log.namespace !== 'on-now')
      //   return {
      //     ...state,
      //     logs: state.logs.slice(0, 5).map((l, i) => ({ ...l, ts: l.ts + i })),
      //   };

      if (!state.namespaces[action.log.namespace])
        state.namespaces[action.log.namespace] = { color: getRandomColor() };

      const log = {
        ...action.log,
        color: state.namespaces[action.log.namespace].color,
      };
      return {
        ...state,
        logs: [log, ...state.logs]
          .slice(0, 5)
          .map((l, i) => ({ ...l, ts: l.ts + i })),
      };
    default:
      return state;
  }
};

export default logs;

// actions creators

export const addLog = (log) => ({
  type: ADD_LOG,
  log,
});

// selectors

export const getLogs = (state) => state.log.logs;

import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { logOut } from 'reducers/AuthManager/reducer';
import { getUserData } from 'reducers/Profile/reducer';
import {
  setActiveAutoplay,
  getActiveAutoplay,
} from 'reducers/PlayerSettings/reducer';

import Button from 'components/Button';
import TTSFocusableDescription from 'components/TTSFocusableDescription';
import { fireEvent } from 'utils/analytics';

import { withConfig } from 'hoc';
import Text from 'components/Text';

import Section from './Section';

import * as Icons from 'components/Icons';

export class Settings extends React.Component {
  state = {
    limitToHd: localStorage.getItem('limitToHD') === 'true',
  };

  componentDidMount() {
    fireEvent({
      category: 'page',
      action: 'view',
      label: 'kids_mode',
    });
  }

  render() {
    const { has4kPlan, config } = this.props;

    const showQualitySwitch = has4kPlan && config.getIsUhdCapable();

    const switchLimitToHd = limitToHd => {
      localStorage.setItem('limitToHD', limitToHd);
      this.setState({ limitToHd });
    };

    return (
      <>
        <Section icon={<Icons.User />}>
          <TTSFocusableDescription>
            <Text>
              <FormattedMessage
                id="smarttv:account_settings_kidsmode_content"
                values={{ status: true, br: ' ' }}
              />
            </Text>
          </TTSFocusableDescription>
        </Section>
        {/* <Section style={{ paddingBottom: '1em' }}>
          <TTSFocusableDescription>
            <Text strong>Autoplay</Text>
            <Text>
              Auto Play: Automatically starts playing the next video after a
              video has finished playing.
            </Text>
          </TTSFocusableDescription>
          <Button
            active={activeAutoplay}
            onClick={() => setActiveAutoplay(true)}
            dataCy="autoplay-on"
          >
            On
          </Button>{' '}
          <Button
            active={!activeAutoplay}
            onClick={() => setActiveAutoplay(false)}
            dataCy="autoplay-off"
          >
            Off
          </Button>
        </Section> */}
        {showQualitySwitch && (
          <Section style={{ paddingBottom: '1em' }}>
            <TTSFocusableDescription>
              <Text strong>Playback quality</Text>
              <Text>
                This TV is compatible with our 4K content. However, you can
                force to lower to HD quality if buffering occurs too often.
              </Text>
            </TTSFocusableDescription>
            <Button
              active={!this.state.limitToHd}
              onClick={() => switchLimitToHd(false)}
            >
              Play 4K content when available
            </Button>{' '}
            <Button
              active={this.state.limitToHd}
              onClick={() => switchLimitToHd(true)}
            >
              Limit playback to HD
            </Button>
          </Section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, { props }) => ({
  userData: getUserData(state),
  activeAutoplay: getActiveAutoplay(state),
});

const mapDispatchToProps = {
  logOut,
  setActiveAutoplay,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withConfig(Settings));

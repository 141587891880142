import React from 'react';
import { connect } from 'react-redux';
import { isDoneWithAutoSignin } from 'reducers/AuthManager/reducer';

import { auth } from 'utils/tokenManager';
/**
 * adds auth status props to the wrapped component.
 */
const withAuth = ComposedComponent => {
  const WithAuthContainer = props => <ComposedComponent {...props} />;

  WithAuthContainer.displayName = `withAuth(${ComposedComponent.displayName})`;

  const mapStateToProps = (state, { params }) => ({
    isLogged: !!auth.get(),
    isDoneWithAutoSignin: isDoneWithAutoSignin(state)
  });

  return connect(mapStateToProps)(WithAuthContainer);
};

export default withAuth;

import { mapFeatured, mapCategory, mapMedia } from 'utils/mapper';
import { getMediaByKey, getSuggestedResumeTimeAndPercentage } from '../Medias/reducer';
import { FETCH_UNTYPED_GROUP_SUCCESS } from 'reducers/Home/reducer';

export const FETCH_FEATURED_ITEM = 'cs.com/Categories/FETCH_FEATURED_ITEMS';
export const FETCH_FEATURED_ITEM_SUCCESS =
  'cs.com/Categories/FETCH_FEATURED_ITEMS_SUCCESS';

export const FETCH_FULL_CATEGORY = 'cs.com/Categories/FETCH_FULL_CATEGORY';
export const FETCH_FULL_CATEGORY_SUCCESS =
  'cs.com/Categories/FETCH_FULL_CATEGORY_SUCCESS';

export const FETCH_CATEGORIES = 'cs.com/Categories/FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS =
  'cs.com/Categories/FETCH_CATEGORIES_SUCCESS';

export const UPDATE_USER_CATEGORIES =
  'cs.com/Categories/UPDATE_USER_CATEGORIES';

export const FETCH_USER_CATEGORIES = 'cs.com/Categories/FETCH_USER_CATEGORIES';
export const FETCH_USER_CATEGORIES_SUCCESS =
  'cs.com/Categories/FETCH_USER_CATEGORIES_SUCCESS';

export const FETCH_CATEGORY_MEDIAS = 'cs.com/Categories/FETCH_CATEGORY_MEDIAS';
export const FETCH_CATEGORY_MEDIAS_SUCCESS =
  'cs.com/Categories/FETCH_CATEGORY_MEDIAS_SUCCESS';

export const FETCH_FAILURE = 'cs.com/Categories/FETCH_FAILURE';

// reducer

export const defaultState = {
  mainCategoriesIds: [],
  categoriesIdsCompleted: []
};

const categories = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
    case FETCH_USER_CATEGORIES:
    case FETCH_FULL_CATEGORY:
    case FETCH_CATEGORY_MEDIAS:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case FETCH_FEATURED_ITEM_SUCCESS:
      return {
        ...state,
        featuredMedia: action.media
      };

    case FETCH_USER_CATEGORIES_SUCCESS:
      return {
        ...state,
        userCategories: action.userCategories
      };

    case FETCH_CATEGORIES_SUCCESS:
      const mainCategoriesIds = [];
      //
      // const categories = action.categories.reduce((prev, category) => {
      //   return {...prev, [category.id]: category}
      // }, {})

      const categories = {};
      for (const cat of action.categories) {
        mainCategoriesIds.push(cat.id);
        categories[cat.id] = mapCategory(cat);
        categories[cat.id].subcategories = [];
        for (const subcategory of cat.subcategories || []) {
          const subcat = mapCategory(subcategory);
          subcat.parentId = cat.id;
          categories[cat.id].subcategories.push(subcat);
          categories[subcategory.id] = subcat;
        }
      }
      return {
        ...state,
        mainCategoriesIds,
        isFetching: false,
        ...categories
      };

    case FETCH_UNTYPED_GROUP_SUCCESS:
    case FETCH_CATEGORY_MEDIAS_SUCCESS:
    case FETCH_FULL_CATEGORY_SUCCESS:
      if (!action.categoryId) return state;

      const newState = {
        ...state,
        isFetching: false
      };
      // adds new media ids to category key
      const newMediaKeys = action.media.map(mapMedia).map(m => m.key) || [];
      const catMediaKeys = newState[action.categoryId].mediasKeys || [];
      newState[action.categoryId].mediasKeys = [
        ...catMediaKeys,
        ...newMediaKeys
      ];

      // tells if last page reached
      if (action.isLastPage) {
        newState.categoriesIdsCompleted.push(action.categoryId);
      }

      return newState;

    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default categories;

// actions creators

export const fetchCategories = () => ({
  type: FETCH_CATEGORIES
});

export const gotCategories = categories => ({
  type: FETCH_CATEGORIES_SUCCESS,
  categories
});

export const fetchFullCategory = categoryId => ({
  type: FETCH_FULL_CATEGORY,
  categoryId
});

export const gotUserCategories = userCategories => ({
  type: FETCH_USER_CATEGORIES_SUCCESS,
  userCategories
});

export const failedFetch = error => ({
  type: FETCH_FAILURE,
  error
});

export const fetchCategoryMedia = (category, page = 1) => ({
  type: FETCH_CATEGORY_MEDIAS,
  category,
  page
});

export const fetchFeaturedItems = () => ({
  type: FETCH_FEATURED_ITEM
});

export const gotCategoryMedia = (categoryId, page, media, isLastPage) => ({
  type: FETCH_CATEGORY_MEDIAS_SUCCESS,
  categoryId,
  page,
  media,
  isLastPage
});

export const gotFeaturedItem = featuredItem => ({
  type: FETCH_FEATURED_ITEM_SUCCESS,
  media: mapFeatured(featuredItem)
});

// selectors

const getCategoriesState = state => state.categories;

export const getCategoryById = state => categoryId =>
  getCategoriesState(state)[categoryId];

export const getMediaForCategoryId = state => categoryId =>
  getCategoryById(state)(categoryId) &&
  (getCategoryById(state)(categoryId).mediasKeys || []).map(group => {
    const media = getMediaByKey(state)(group);
    return {
      ...media,
      ...(getSuggestedResumeTimeAndPercentage(state)(media.key) || {}),
    };
  });

export const getMainCategories = state =>
  getCategoriesState(state).mainCategoriesIds &&
  getCategoriesState(state).mainCategoriesIds.map(getCategoryById(state));

export const getUserCategories = state =>
  getCategoriesState(state).userCategories;

export const getIsFetching = state => !!getCategoriesState(state).isFetching;

export const getFeaturedMedia = state =>
  getCategoriesState(state).featuredMedia;

export const getCanCategoryFetchMore = state => catId =>
  getCategoriesState(state).categoriesIdsCompleted.indexOf(
    Number.parseInt(catId, 10)
  ) === -1;

export const getParentCategory = state => categoryId => {
  const category = getCategoryById(state)(categoryId);
  if (category && category.parentId) {
    return getCategoryById(state)(category.parentId);
  }
  return category;
};

export const getFetchingError = state => getCategoriesState(state).error;

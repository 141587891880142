import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getPlayerCcSettingProperties,
  getPlayerCcSettingByLabel,
  getSelectedLabel,
  updateCcSettings
} from 'reducers/PlayerSettings/reducer';

import Button from 'components/Button';
import TTSFocusableDescription from 'components/TTSFocusableDescription';

import { withConfig } from 'hoc';

import styles from './styles.module.css';

export class ClosedCaptionsMenu extends React.Component {
  state = {};

  componentDidMount() {
    if (this.props.config.onlyToggleClosedCaptionsWithRemote) {
      this.setState({ focusedOptionGroup: 'Font Family' });
    } else {
      this.setState({ focusedOptionGroup: 'Subtitles' });
    }
  }

  renderLeftButton = (setting, index, array) => (
    <Button
      key={index}
      focus={index === 0}
      className={styles.optionButton}
      noTransition
      borderPosition="Right"
      active={this.state.focusedOptionGroup === setting}
      onClick={() => {
        this.setState({ focusedOptionGroup: setting });
      }}
    >
      {setting}
    </Button>
  );

  renderRightOptions = () => {
    const { focusedOptionGroup } = this.state;
    if (focusedOptionGroup) {
      const options = this.props.ccSettingForLabel(focusedOptionGroup);
      if (!options) {
        return null;
      }
      const selectedLabel = this.props.getSelectedLabelForOption(
        focusedOptionGroup
      );
      return (
        <>
          {options.options.map((option, index) => (
            <Button
              key={index}
              noTransition
              className={styles.optionButton}
              active={option.label === selectedLabel}
              onClick={() => {
                this.props.updateCcSettings(focusedOptionGroup, option, index);
              }}
            >
              {option.label}
            </Button>
          ))}
        </>
      );
    }
  };

  render() {
    const { ccSettingsProperties, onClose } = this.props;

    return (
      <div className={styles.fullBleed}>
        <div className={styles.main}>
          <div className={styles.left}>
            {ccSettingsProperties
              .filter(label => {
                return (
                  !this.props.config.onlyToggleClosedCaptionsWithRemote ||
                  label !== 'Subtitles'
                );
              })
              .map(this.renderLeftButton)}
          </div>
          <div className={styles.right}>{this.renderRightOptions()}</div>
          <TTSFocusableDescription
            className={styles.bottomRight}
            onClick={onClose}
          >
            Press the back button on your remote to hide this menu
          </TTSFocusableDescription>
        </div>
        <div className={styles.example + ' subtitleDiv'}>
          <div>
            <div>
              <div>example subtitles</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { match }) => {
  return {
    getSelectedLabelForOption: getSelectedLabel(state),
    ccSettingsProperties: getPlayerCcSettingProperties(state),
    ccSettingForLabel: getPlayerCcSettingByLabel(state)
  };
};

const mapDispatchToProps = {
  updateCcSettings
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withConfig
)(ClosedCaptionsMenu);

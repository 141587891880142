import React from 'react';

import Button from 'components/Button';
import Text from 'components/Text';

const SideButton = ({ label, active, onClick }) => (
  <Button
    id={label.replace(' ', '-').toLowerCase()}
    borderPosition="Right"
    style={{
      marginBottom: '0.6em'
    }}
    active={active}
    onClick={onClick}
  >
    <Text noMargin thin={false}>
      {label}
    </Text>
  </Button>
);

export default SideButton;

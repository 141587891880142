import React from 'react';
import { connect } from 'react-redux';

import {
  getDisplayedInputLabel,
  showInputView,
  hideInputView,
  getDisplayedInputIsPassword,
  getValueForId,
  updateValue
} from 'reducers/TextInput/reducer';

class WithInputView extends React.Component {
  state = {};

  componentDidUpdate(prevProps) {
    if (!prevProps.displayedLabel && this.props.displayedLabel) {
      this.setState({ show: this.props.displayedLabel });
    }
    if (prevProps.displayedLabel && !this.props.displayedLabel) {
      this.setState({ show: null });
    }
  }

  render() {
    const {
      label,
      showInputView,
      hideInputView,
      isPassword,
      getValueForId,
      updateValue,
      displayedLabel
    } = this.props;

    const { show } = this.state;

    const value = getValueForId(label || displayedLabel);

    return this.props.children({
      show,
      label,
      displayedLabel,
      showInputView,
      isPassword,
      hideInputView,
      updateValue,
      value
    });
  }
}

const mapStateToProps = state => ({
  displayedLabel: getDisplayedInputLabel(state),
  isPassword: getDisplayedInputIsPassword(state),
  getValueForId: getValueForId(state)
});

const mapDispatchToProps = {
  showInputView,
  hideInputView,
  updateValue
};

export default connect(mapStateToProps, mapDispatchToProps)(WithInputView);

import logger from 'utils/logger';

let log = logger.extend('focus');

let cache = {};

const saveLastFocus = key => {
  log(`saveLastFocus`);

  if (!document.activeElement) return;

  cache[key] = {
    lastFocusedElement: document.activeElement,
    lastRect: document.activeElement.getBoundingClientRect()
  };
};

const restoreFocus = key => {
  log(`restoreFocus`);

  const { lastRect, lastFocusedElement } = cache[key] || {};

  if (lastFocusedElement && lastFocusedElement.id.startsWith('navlink-'))
    return;

  setTimeout(() => {
    try {
      if (
        lastFocusedElement &&
        lastFocusedElement.id &&
        document.getElementById(lastFocusedElement.id)
      ) {
        document.getElementById(lastFocusedElement.id).focus();
      } else if (
        // if a focused element was registered
        lastFocusedElement &&
        // and still exists
        lastFocusedElement.getBoundingClientRect().height !== 0 &&
        lastFocusedElement.getBoundingClientRect().width !== 0
      ) {
        lastFocusedElement.focus();
      } else if (lastRect) {
        // else we find the element placed the closest and focus it.
        // TV-108: this happens when navigating back after removing the prev focused
        // element i.e. when un-bookmarking a program then going back to the
        // featured page.
        const { x, y, width, height } = lastRect;
        const nextElement = document.elementFromPoint(
          x + width / 2,
          y + height / 2
        );

        if (nextElement.parentElement.parentElement.localName === 'button') {
          // this case is not concerned by TV-108.
          // looks like going back to categories. Weirdly enough, categoryButtons seem to
          // be re-rendered - should be replaced with cards.
          nextElement.parentElement.parentElement.focus();
        } else if (nextElement.parentElement.localName === 'a') {
          // this happens when going back to the home screen after removing a watchlist
          // looks like a card.
          nextElement.parentElement.focus();
        } else if (nextElement.id.startsWith('carousel-')) {
          // we are in the carousel div, getting to the first card to focus.
          nextElement.childNodes[0].firstChild.focus();
        }
      }
    } catch (e) {
      console.error(
        'could not find a precedent element to focus - review PersistedComponent!'
      );
    }
  }, 25);
};

export default () => ({
  saveLastFocus,
  restoreFocus
});

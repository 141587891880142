const qpb = paramsObject => {
  let paramsArray = [];
  for (const key of Object.keys(paramsObject)) {
    if (paramsObject[key])
      paramsArray = [
        ...paramsArray,
        {
          key,
          value: paramsObject[key]
        }
      ];
  }

  if (paramsArray.length === 0) return ``;

  return `?${paramsArray.map(pp => `${pp.key}=${pp.value}`).join('&')}`;
};

export default qpb;

import { mapCollection } from 'utils/mapper';
import { getMediaByKey, getSuggestedResumeTimeAndPercentage } from '../Medias/reducer';
import { LOGIN_SUCCESS } from '../AuthManager/reducer';

// consts

export const FETCH_COLLECTIONS = 'cs.com/Collections/FETCH_COLLECTIONS';
export const FETCH_COLLECTIONS_SUCCESS =
  'cs.com/Collections/FETCH_COLLECTIONS_SUCCESS';

export const FETCH_COLLECTION = 'cs.com/Collections/FETCH_COLLECTION';
export const FETCH_COLLECTION_SUCCESS =
  'cs.com/Collections/FETCH_COLLECTION_SUCCESS';
export const FETCH_FAILURE = 'cs.com/Collections/FETCH_FAILURE';

// reducer

export const defaultState = {
  collections: {},
  orderedIds: []
};

const addCollectionsToState = state => (collections, featured) => {
  const curCollections = { ...state.collections };
  const newCollections = collections
    .map(mapCollection)
    .reduce((prev, collection) => {
      collection.featured = !!featured;
      prev[collection.id] = collection;
      return prev;
    }, {});

  const allCollections = {
    ...curCollections,
    ...newCollections
  };

  return {
    ...state,
    collections: allCollections
  };
};

const collections = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return defaultState;

    case FETCH_COLLECTION:
      return {
        ...state,
        error: null
      };

    case FETCH_COLLECTIONS_SUCCESS:
      return {
        ...addCollectionsToState(state)(action.collections),
        orderedIds: action.collections.map(c => c.id)
      };

    case FETCH_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.collection.id]: mapCollection(action.collection)
        }
      };

    case FETCH_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state;
  }
};

export default collections;

// actions creators

export const fetchCollections = () => ({
  type: FETCH_COLLECTIONS
});

export const gotCollections = collections => ({
  type: FETCH_COLLECTIONS_SUCCESS,
  collections
});

export const failedFetch = error => ({
  type: FETCH_FAILURE,
  error
});

export const fetchCollection = collectionId => ({
  type: FETCH_COLLECTION,
  collectionId
});

export const gotCollection = collection => ({
  type: FETCH_COLLECTION_SUCCESS,
  collection
});

// selectors

const getCollectionsState = state => state.collections;

export const getCollectionById = state => id =>
  getCollectionsState(state).collections[id];

export const getCollections = state =>
  getCollectionsState(state).orderedIds.map(getCollectionById(state));

export const getCollectionVideosForId = state => collectionId =>
  getCollectionById(state)(collectionId) &&
  (getCollectionById(state)(collectionId).videosKeys || []).map(group => {
    const media = getMediaByKey(state)(group);
    return {
      ...media,
      ...(getSuggestedResumeTimeAndPercentage(state)(media.key) || {}),
    };
  });

export const getCollectionError = state => getCollectionsState(state).error;

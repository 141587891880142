import React from 'react';

import { withConfig } from 'hoc';

import { say } from 'utils/speechSynthesis';
import Text from 'components/Text';
import { Volume, Mute } from 'components/Icons';

import styles from './styles.module.css';

class VolumeControl extends React.Component {
  state = {
    volume: this.props.getVolume(),
    mute: this.props.getMute()
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  displayInfo(stateInfo) {
    this.setState({
      ...stateInfo,
      showControl: true
    });

    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.setState({ showControl: false });
    }, 2000);
  }

  onKeyDown = e => {
    if (e.keyCode === this.props.config.keyCodes.volumeDown) {
      this.props.setVolumeDown();
      this.props.setMute(false);
      this.displayInfo({ mute: false, volume: this.state.volume - 1 });
      say('volume ' + this.state.volume);
    } else if (e.keyCode === this.props.config.keyCodes.volumeUp) {
      this.props.setVolumeUp();
      this.props.setMute(false);
      this.displayInfo({ mute: false, volume: this.state.volume + 1 });
      say('volume ' + this.state.volume);
    } else if (e.keyCode === this.props.config.keyCodes.mute) {
      say(!this.state.mute ? 'mute' : 'volume ' + this.state.volume);
      this.displayInfo({ mute: !this.state.mute });
      setTimeout(() => {
        this.props.setMute(this.state.mute);
      }, this.state.mute ? 1000 : 0);
    }
  };

  render() {
    if (!this.state.showControl) {
      return null;
    }

    return (
      <div className={styles.overlay}>
        {this.state.mute && <Mute />}
        {!this.state.mute && (
          <div style={{ textAlign: 'center', height: 90 }}>
            <Volume />
            <Text title>{this.state.volume}</Text>
          </div>
        )}
      </div>
    );
  }
}

export default withConfig(VolumeControl);

import { mapSeries, mapMedia } from 'utils/mapper';

import { getMediaByKey } from '../Medias/reducer';

import { LOGIN_SUCCESS } from '../AuthManager/reducer';

// constants

export const FETCH_ALL_SERIES = 'cs.com/Series/FETCH_ALL_SERIES';
export const FETCH_SERIES = 'cs.com/Series/FETCH_SERIES';
export const FETCH_SERIES_SUCCESS = 'cs.com/Series/FETCH_SERIES_SUCCESS';
export const FETCH_RELATED = 'cs.com/Series/FETCH_RELATED';
export const FETCH_RELATED_SUCCESS = 'cs.com/Series/FETCH_RELATED_SUCCESS';

// reducer

const series = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SERIES_SUCCESS:
      const newState = { ...state };
      for (const series of action.series.map(mapSeries)) {
        newState[series.id] = {
          ...newState[series.id],
          ...series
        };
      }
      newState.fetching = false;
      return newState;

    case LOGIN_SUCCESS:
      return {};

    case FETCH_SERIES:
      return {
        ...state,
        fetching: true
      };

    case FETCH_RELATED:
      return {
        ...state,
        fetchingRelated: true
      };

    case FETCH_RELATED_SUCCESS:
      const relatedIds = action.relatedVideos.map(mapMedia).map(r => r.key);
      return {
        ...state,
        [action.seriesId]: {
          ...state[action.seriesId],
          relatedIds
        },
        fetchingRelated: false
      };

    default:
      return state;
  }
};

export default series;

// actions creators

export const fetchSeries = (seriesId, redirect, forceCDN) => ({
  type: FETCH_SERIES,
  seriesId,
  redirect,
  forceCDN
});

export const gotSeries = series => ({
  type: FETCH_SERIES_SUCCESS,
  series
});

export const fetchRelatedVideos = seriesId => ({
  type: FETCH_RELATED,
  seriesId
});

export const gotRelatedVideos = (seriesId, relatedVideos) => ({
  type: FETCH_RELATED_SUCCESS,
  seriesId,
  relatedVideos
});

// selectors

const getSeriesState = state => state.series;

export const getFetching = state => getSeriesState(state).fetching;

export const getFetchingRelated = state =>
  getSeriesState(state).fetchingRelated;

export const getRelatedVideosForId = state => seriesId =>
  getSeriesState(state)[seriesId] &&
  getSeriesState(state)[seriesId].relatedIds &&
  getSeriesState(state)[seriesId].relatedIds.map(getMediaByKey(state));

export const getSeriesById = state => seriesId =>
  getSeriesState(state)[seriesId];

export const getLastWatchedForSeriesId = state => seriesId =>
  getSeriesState(state)[seriesId] &&
  getSeriesState(state)[seriesId].lastWatchedMediaId;

export const getSeriesVideosForId = state => seriesId =>
  getSeriesById(state)(seriesId) &&
  (getSeriesById(state)(seriesId).videosKeys || []).map(getMediaByKey(state));

export const getSeriesVideosForVideoId = state => videoId => {
  if (
    getMediaByKey(state)(`media_${videoId}`) &&
    getMediaByKey(state)(`media_${videoId}`).seriesId &&
    getSeriesById(state)(getMediaByKey(state)(`media_${videoId}`).seriesId)
  ) {
    return (
      getSeriesById(state)(getMediaByKey(state)(`media_${videoId}`).seriesId)
        .videosKeys || []
    ).map(getMediaByKey(state));
  }
  return null;
};

import React from 'react';
import flatten from 'lodash.flatten';

import { withConfig } from 'hoc';

import { dNav } from 'config';

import styles from './styles.module.css';
import { setTimeout } from 'timers';

const ANIMATION_SPEED = 150;

export class ScrollArea extends React.Component {
  state = {
    translateY: 0
  };

  handleWheel = e => {
    const childrenCount = flatten(this.props.children).length;

    if (this.state.lastWheel + ANIMATION_SPEED - Date.now() < 0) {
      if (e.deltaY < 0) {
        this.onChildFocus(Math.max(0, (this.state.focusedChildIndex || 0) - 1));
      }
      if (e.deltaY > 0) {
        this.onChildFocus(
          Math.min(childrenCount - 1, (this.state.focusedChildIndex || 0) + 1)
        );
      }
      this.setState({ lastWheel: Date.now() });
    }
  };

  componentDidMount() {
    this.setState({ lastWheel: Date.now() });
    window.addEventListener('mousewheel', this.handleWheel);

    const heightInPixels =
      window.innerHeight - this.rootDiv.getBoundingClientRect().top;

    const widthInPixels = this.rootDiv.getBoundingClientRect().width;

    this.setState({ heightInPixels });
    this.props.onSizeSet && this.props.onSizeSet({ width: widthInPixels });

    if (this.props.config.name === 'sony')
      this.scrollFixInterval = setInterval(this.fixUnsafeScroll, 500);
  }

  /// makes sure focus navigation did not force scroll down the div container
  fixUnsafeScroll = () => {
    if (
      this.rootDiv &&
      this.props.config.supportedCssTransform &&
      !this.props.config.isPresto
    ) {
      if (this.rootDiv.scrollTop !== 0) this.rootDiv.scrollTop = 0;
      if (this.rootDiv.scrollLeft !== 0) this.rootDiv.scrollLeft = 0;
    }
  };

  componentWillUnmount() {
    window.removeEventListener('mousewheel', this.handleWheel);
    if (this.props.config.name === 'sony') clearInterval(this.fixUnsafeScroll);
  }

  onChildFocus = index => {
    const childrenCount = flatten(this.props.children).length;

    if (this.state.focusedChildIndex !== index) {
      setTimeout(() => {
        this.setState({
          focusedChildIndex: index
        });
      }, 10);

      dNav.enable(false);
      setTimeout(() => {
        dNav.enable(true);
      }, ANIMATION_SPEED + 200);

      if (this.rootDiv && this.innerDiv) {
        const rootDivHeight = this.rootDiv.getBoundingClientRect().height;
        const innerDivHeight = this.innerDiv.getBoundingClientRect().height;

        if (innerDivHeight > rootDivHeight) {
          const translateY =
            (index / (childrenCount - 1)) * (rootDivHeight - innerDivHeight);

          if (
            this.props.config.supportedCssTransform &&
            !this.props.config.isPresto
          ) {
            this.setState({ translateY });
          } else {
            this.rootDiv.scrollTop = -translateY;
          }
        }
      }
    }
  };

  renderRow = (child, index) => (
    <div
      data-cy={`scrollarea-row-${index}`}
      onFocus={() => this.onChildFocus(index)}
    >
      {child}
    </div>
  );

  render() {
    const {
      id,
      scrollContainerId,
      style,
      children,
      noShadow,
      css,
      config,
      paddingTop = 0,
      paddingBottom = 10
    } = this.props;

    const { heightInPixels, translateY } = this.state;

    return (
      <div
        data-cy={`scrollarea-${id || ''}`}
        ref={node => (this.rootDiv = node)}
        className={styles.root}
        style={{
          height: heightInPixels,
          boxShadow:
            !noShadow && translateY !== 0
              ? 'rgba(0, 0, 0, 0.75) 0 0 1em 0.15em'
              : null
        }}
      >
        <div
          ref={node => (this.innerDiv = node)}
          className={css + ' ' + styles.content}
          style={{
            transition: `${ANIMATION_SPEED}ms ease-in-out`,
            [config.supportedCssTransform]: `translateY(${translateY}px)`,
            ...style
          }}
        >
          {paddingTop > 0 && (
            <div
              style={{
                height: `${(paddingTop * heightInPixels) / 100}px`
              }}
            />
          )}
          {React.Children.map(flatten(children), this.renderRow)}
          <div
            style={{
              height: `${(paddingBottom * heightInPixels) / 100}px`
            }}
          />
        </div>
      </div>
    );
  }
}

export default withConfig(ScrollArea);

import React from 'react';

class FadeInDiv extends React.Component {
  state = {};

  componentDidMount() {
    this.imageLoader = new Image();
    try {
      this.imageLoader.onload = () => {
        this.setState({
          imgReady: true
        });
        this.imageLoader = null;
      };
      this.imageLoader.src = this.props.src;
    } catch (e) {
      this.setState({
        imgReady: true
      });
      this.imageLoader = null;
    }
  }

  componentWillUnmount() {
    try {
      if (this.imageLoader) {
        this.imageLoader.onload = null;
        this.imageLoader = null;
      }
    } catch (e) {
      // ninjas > pirates
    }
  }

  render() {
    const { src, className, style, children, placeHolderColor } = this.props;
    const { imgReady } = this.state;

    return (
      <div
        className={className}
        style={{
          background: placeHolderColor,
          ...style
        }}
      >
        <div
          className={className}
          style={{
            opacity: imgReady ? 1 : 0.001,
            transition: `opacity 150ms`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: imgReady ? `url(${src})` : null,
            ...style
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

FadeInDiv.defaultProps = {
  placeHolderColor: `rgba(11, 17, 32, 0.25)` // could hold the average color of the image to load
};

// FadeInDiv.propTypes = {
//   className: PropTypes.string,
//   src: PropTypes.string.isRequired,
//   style: PropTypes.object
// };

export default FadeInDiv;

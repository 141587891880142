import { secondsToMinutes } from './formatters';

const canUseWebP = () => {
  try {
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
  } catch {
    // very old browser like IE 8, canvas not supported
  }

  return false;
};

const webpSupport = canUseWebP();

export const getImage = ({ kind, id, prop, size }) => {
  return `https://cdn.curiositystream.com/webapp-v3/images/v8/${kind}-${id}-${prop}-en-a/${size}.${
    webpSupport ? 'webp' : 'jpg'
  }`;
};

export const mapMedia = ({
  id,
  tags,
  obj_type,
  title,
  duration,
  producer,
  num_ratings,
  encodings,
  quality,
  description,
  keyframes,
  is_collection,
  is_published,
  collection_id,
  image_show,
  rating_percentage,
  closed_captions,
  year_produced,
  media_count,
  collection_order,
  scrubbing_images,
  status,
  primary_category,
  type,
  is_fully_fetched,
}) => {
  return {
    key: `${!obj_type || obj_type === 'episode' ? 'media' : obj_type}_${id}`,
    id,
    tags,
    title,
    duration,
    producer,
    squareImage: image_show,
    keyframes: [
      getImage({ kind: 'video', id, prop: 'keyframes0', size: 'full' }),
      getImage({ kind: 'video', id, prop: 'keyframes1', size: 'full' }),
      getImage({ kind: 'video', id, prop: 'keyframes2', size: 'full' }),
    ],
    quality,
    encodings,
    description,
    status,
    objType: obj_type,
    closedCaptions: Array.isArray(closed_captions) ? closed_captions : [],
    scrubbingSpriteMap:
      scrubbing_images && scrubbing_images[0] && scrubbing_images[0].url,
    isSeries: is_collection,
    isPublished: is_published,
    seriesId: collection_id,
    ratingsCount: num_ratings && num_ratings.toLocaleString(),
    rating: Math.round(rating_percentage),
    yearProduced: year_produced,
    badgeContent: duration
      ? secondsToMinutes(duration)
      : `${media_count} episodes`,
    videosCount: media_count,
    primaryCategory: primary_category,
    type: type,
    collectionOrder: collection_order,
    imageUrl: getImage({
      kind: is_collection ? 'series' : 'video',
      id,
      prop: 'image_large',
      size: 300,
    }),
    navigateTo: is_collection ? `/series/${id}` : `/media/${id}`,
    isFullyFetched: is_fully_fetched,
  };
};

export const mapUserMedia = ({
  media_id,
  collection_id,
  progress_in_seconds,
  progress_percentage,
  media_progress_percentage,
  is_bookmarked,
  rating,
}) => ({
  key: collection_id ? `collection_${collection_id}` : `media_${media_id}`,
  progressInSeconds: progress_in_seconds || 0,
  progressPercentage: progress_percentage || media_progress_percentage || 0,
  isBookmarked: is_bookmarked,
  rating,
});

export const mapFeatured = ({
  id,
  display_tag,
  label,
  num_ratings,
  media_ids,
  collection_id,
  series_id,
  rating_percentage,
  media_duration,
  media_count,
  description,
}) => ({
  key: `featured_${id}`,
  linkKey: `${media_count > 1 ? 'collection' : 'media'}_${media_ids[0]}`,
  id,
  title: display_tag,
  imageUrl: getImage({ kind: 'featured', id, prop: 'image_web', size: 'full' }),
  isSeries: Number.isInteger(series_id),
  seriesId: series_id,
  label,
  collectionId: collection_id,
  mediaIds: media_ids,
  navigateTo: collection_id
    ? `/collections/${collection_id}`
    : `/media/${media_ids[0]}`,
  description: description || '',
  rating: Math.trunc(rating_percentage),
  ratingsCount: num_ratings && num_ratings.toLocaleString(),
  badgeContent:
    media_duration > 0
      ? secondsToMinutes(media_duration)
      : `${media_count} episode${media_count > 1 ? 's' : ''}`,
});

export const mapGroup = ({ id, label, name, description, type, media }) => ({
  key: `group_${name}_${id}`,
  id,
  label,
  name,
  type,
  description,
  isFeaturedSection: name === 'featured-section',
  navigateTo: `/browse/${type}/${name}?title=${label}`,
  mediasKeys: media.map(m => mapMedia(m).key),
});

export const mapCategory = ({ id, name, label }) => ({
  key: `category_${id}`,
  id,
  name,
  label,
  imageUrl: getImage({ kind: 'category', id, prop: 'image_url', size: 900 }),
});

export const mapCollection = ({
  id,
  title,
  description,
  label,
  media,
  keyframes,
  last_watched_media_id,
  collection_order,
}) => ({
  key: `collection_${id}`,
  id,
  title,
  label,
  keyframes,
  description,
  lastWatchedMediaId: last_watched_media_id,
  imageUrl: getImage({
    kind: 'collection',
    id,
    prop: 'background_url',
    size: 600,
  }),
  videosKeys: media && media.map(mapMedia).map(m => m.key),
  collectionOrder: collection_order,
});

export const mapSeries = mapCollection;

export const mapUser = ({
  first_name,
  last_name,
  email,
  referral_id,
  external_id,
  stripe_plan,
  is_free,
  roku_active,
  roku_plan,
  is_child_friendly_only,
  cancelled_at,
  subscription_platform,
}) => ({
  firstName: first_name,
  lastName: last_name,
  email,
  referralId: referral_id,
  externalId: external_id,
  stripePlan: stripe_plan,
  isFree: is_free,
  rokuActive: roku_active,
  rokuPlan: roku_plan,
  isChildFriendlyOnly: is_child_friendly_only,
  isCancelled: !!cancelled_at,
  subscriptionPlatform: subscription_platform,
});

export const mapConnectionType = type => {
  switch (type) {
    case 'wifi':
      return 'WiFi';
    case 'ethernet':
      return 'Ethernet';
    case '2g':
      return '2G';
    case '3g':
      return '3G';
    case '4g':
      return '4G';
    case 'other':
      return 'OTHER';
  }
};

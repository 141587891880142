/* eslint-disable jsx-a11y/alt-text */

import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withConfig } from 'hoc';
import Button from 'components/Button';
import Text from 'components/Text';
import styles from './styles.module.css';

import { getSuggestedResumeTimeAndPercentage } from 'reducers/Medias/reducer';
import MediaProgressComponent from 'components/MediaProgress';
import { fireEvent } from 'utils/analytics';
import VisibilitySensor from 'react-visibility-sensor';

const EpisodeButton = props => {
  const { ep, index, getResumeTime, config, history, isFocused } = props;
  const [isVisibilitySensorActive, setIsVisibilitySensorActive] = useState(true);
  let timeRemaining = null;
  const progress = getResumeTime(ep.key);
  const showProgressData = progress.progressPercentage > 2;

  if (showProgressData) {
    timeRemaining = progress.progressCaption;
  }

  const disableSensor = () => {
    setIsVisibilitySensorActive(false)
  };

  const submitEventAndRemoveEventListener = () => {
    if (
      props.analyticsData.ignoreEventCheck &&
      props.analyticsData.ignoreEventCheck(
        props.analyticsData.mediaId
      )
    ) {
      disableSensor();
      return;
    }
    console.log(props.analyticsData);
    fireEvent({
      category: props.analyticsData.category,
      action: props.analyticsData.action,
      label: props.analyticsData.label,
      property: props.analyticsData.property,
      value: props.analyticsData.value
    });
    props.analyticsData.callback &&
      props.props.analyticsData.callback(props.analyticsData.mediaId);
    disableSensor();
  };

  const timeInfo = timeRemaining ? timeRemaining : `${ep.badgeContent}`;

  return (

    <VisibilitySensor
      active={isVisibilitySensorActive}
      onChange={isVisible => {
        if (isVisible) {
          submitEventAndRemoveEventListener();
        }
      }}
    >
      <Button
        id={`episode-${index}-btn`}
        key={ep.id}
        borderPosition="Outline"
        focus={
          (index === 0 &&
            !(config.getIsTTSEnabled() && config.focusTextWhenTTS)) ||
          isFocused
        }
        className={styles.episodeButton}
        onClick={() => {
          props.onClick && props.onClick();
          history.push(`/media/${ep.id}`)
        }}
      >
        {/* Image has alt attribute empty to avoid TTS engine from reading out image resolution when focussed */}
        {!config.lowMemoryDevice && <img alt="" src={ep.imageUrl} />}
        <Text
          strong
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {typeof ep.collectionOrder == 'number' ? `Ep ${index}:` : ''} {ep.title}
        </Text>
        <Text
          small
          style={{
            marginRight: '6.5em'
          }}
        >
          {timeInfo}
        </Text>
        {showProgressData ? (
          <div className={styles.rangeHolder}>
            <MediaProgressComponent
              progressPercentage={progress.progressPercentage}
              className={styles.range}
            />
          </div>
        ) : null}
      </Button>
    </VisibilitySensor>
  );
};

const mapStateToProps = state => ({
  getResumeTime: getSuggestedResumeTimeAndPercentage(state)
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withConfig
)(EpisodeButton);

import React from 'react';
import { connect } from 'react-redux';
import chunk from 'lodash.chunk';
import { Link } from 'react-router-dom';

import styles from './styles.module.css';

import Spinner from 'components/Spinner';
import Text from 'components/Text';
import FadeInDiv from 'components/FadeInDiv';
import ScrollArea from 'components/ScrollArea';

import { fetchCollections, getCollections } from 'reducers/Collections/reducer';
import { withConfig } from 'hoc';

import { fireEvent } from 'utils/analytics';

class Collections extends React.Component {
  componentDidMount() {
    if (!this.props.collections.length) {
      this.props.fetchCollections();
    }

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'collections'
    });
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.isVisible &&
      this.props.isVisible &&
      !prevProps.collections.length
    ) {
      // happens after an unlogged user went here, logged in, then came back here.
      prevProps.fetchCollections();
    }
  }

  renderRow = (segment, index) => (
    <div key={`row${index}`}>{segment.map(this.renderCell)}</div>
  );

  renderCell = collection => (
    <Link
      aria-label={collection.title}
      key={collection.key}
      id={collection.key}
      data-cy={`collection-card-${collection.key}`}
      className={styles.card}
      to={`/collections/${collection.id}`}
    >
      <FadeInDiv src={collection.imageUrl} className={styles.cardContent}>
        <div className={styles.contents}>
          <Text noMargin large thin={false}>
            {collection.title}
          </Text>
        </div>
      </FadeInDiv>
    </Link>
  );

  render() {
    if (!this.props.collections.length) {
      return <Spinner />;
    }

    const segments = chunk(this.props.collections, 4);

    return (
      <ScrollArea paddingTop={3} css={styles.scroll} id="collections">
        {segments.map(this.renderRow)}
      </ScrollArea>
    );
  }
}

const mapStateToProps = (state, { params }) => ({
  collections: getCollections(state)
});

const mapDispatchToProps = {
  fetchCollections
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withConfig(Collections));

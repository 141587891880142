import logger from 'utils/logger';

import { setAnonymous } from './analytics';
import request from './request';

const log = logger.extend('gdpr');

let callback = null;
let approvesTracking = null;
let isUserEuropean = null;

export const setListener = cb => {
  callback = cb;
};

export const setUserEuropean = value => {
  if (isUserEuropean === null && isUserEuropean !== value) {
    log(`user is ${!value ? 'not ' : ''}european`);
    isUserEuropean = value;

    if (isUserEuropean) {
      if (window.localStorage.getItem('gdprApprovesTracking') === null) {
        log('no approval value found');
        showConsentModal({ gdpr: true });
      } else {
        setApproveTracking(window.localStorage.getItem('gdprApprovesTracking'));
      }
    } else {
      setAnonymous(false);
    }
  }
};

export const showConsentModal = ({ gdpr, privacy }) => {
  log('showing consent modal', { gdpr, privacy });
  if (callback) callback({ gdpr, privacy });
};

export const getGdprApprovesTracking = () =>
  window.localStorage.getItem('gdprApprovesTracking');

export const setApproveTracking = approves => {
  log('setApproveTracking', approves);
  approvesTracking = approves;
  window.localStorage.setItem('gdprApprovesTracking', approves);

  setAnonymous(!approves);
};

export const getApprovesTracking = () => {
  return approvesTracking;
};

export const sendPolicyUpdate = () => {
  request('v1/user/notices', {
    method: 'POST',
    body: {
      notice_name: 'privacy-policy-update'
    }
  });
};

import { mapUserMedia } from 'utils/mapper';

export const SIGN_IN = 'cs.com/AuthManager/SIGN_IN';
export const SIGN_OUT = 'cs.com/AuthManager/SIGN_OUT';
export const LOGIN_SUCCESS = 'cs.com/AuthManager/LOGIN_SUCCESS';
export const SIGN_IN_ERROR = 'cs.com/AuthManager/SIGN_IN_ERROR';

export const CHECK_AUTH_STATUS = 'cs.com/AuthManager/CHECK_AUTH_STATUS';
export const CHECK_AUTH_STATUS_FAILED = 'cs.com/AuthManager/CHECK_AUTH_STATUS_FAILED';
export const FETCH_ACTIVATION_CODE = 'cs.com/AuthManager/FETCH_ACTIVATION_CODE';
export const FETCH_ACTIVATION_CODE_SUCCESS = 'cs.com/AuthManager/FETCH_ACTIVATION_CODE_SUCCESS';
export const FETCH_ACTIVATION_CODE_ERROR = 'cs.com/AuthManager/FETCH_ACTIVATION_CODE_ERROR';

export const CLEAR_INITIAL_CHECK = 'cs.com/AuthManager/CLEAR_INITIAL_CHECK';

const defaultState = {
  initialCheckDone: false,
  loading: false,
  error: null,
  linkStatusError: false,
  redirectAfterLogin: '/',
  activationCode: null,
  qrBase64: null,
};

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        error: null,
        loading: true
      };
    case CLEAR_INITIAL_CHECK:
      return {
        ...state,
        initialCheckDone: true
      };
    case LOGIN_SUCCESS:
      let subscriptionHasEnded = false;
      try {
        subscriptionHasEnded =
          action.data.subscription_ends_at &&
          new Date(action.data.subscription_ends_at) < new Date();
      } catch (e) {
        // date has wrong format?
      }

      try {
        // heap.identify(action.data.referral_id);
        // heap.addUserProperties({
        //   is_active: action.data.is_active,
        // });
      } catch (e) {
        // ninja!!
      }

      return {
        ...state,
        loading: false,
        initialCheckDone: true,
        referralId: action.data.referral_id,
        kidsModeEnabled: action.data.is_child_friendly_only,
        subscriptionHasEnded
      };
    case SIGN_OUT:
      return {
        ...state,
        loading: false
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        loading: false,
        initialCheckDone: true,
        error: action.error
      };
    case FETCH_ACTIVATION_CODE:
      return {
        ...state,
        error: null,
        linkStatusError: false,
        loading: true
      };
    case FETCH_ACTIVATION_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        activationCode: action.data.device_link_code,
        qrBase64: action.data.device_link_image_base64
      };
    case FETCH_ACTIVATION_CODE_ERROR:
      return {
        ...state,
        activationCode: null,
        loading: false,
        error: action.data.error
      };
    case CHECK_AUTH_STATUS_FAILED:
      return {
        ...state,
        linkStatusError: true
      };
    default:
      return state;
  }
};

export default auth;

// actions creators

export const authSuccess = (data, user_media = []) => {
  const userMedia = user_media.map(mapUserMedia);
  return {
    type: LOGIN_SUCCESS,
    data,
    userMedia
  };
};

export const logOut = () => ({
  type: SIGN_OUT
});

export const clearInitialCheck = () => ({
  type: CLEAR_INITIAL_CHECK
});

export const login = credentials => ({
  type: SIGN_IN,
  credentials
});

export const loginError = error => ({
  type: SIGN_IN_ERROR,
  error
});

export const fetchActivationCode = (clientIdentifier) => ({
  type: FETCH_ACTIVATION_CODE,
  clientIdentifier
});

export const checkAuthStatus = ({clientIdentifier, deviceLinkCode}) => ({
  type: CHECK_AUTH_STATUS,
  payload: {clientIdentifier, deviceLinkCode}
});


export const checkAuthStatusFailed = () => ({
  type: CHECK_AUTH_STATUS_FAILED,
});

export const fetchActivationCodeSuccess = data => ({
  type: FETCH_ACTIVATION_CODE_SUCCESS,
  data
});

export const fetchActivationCodeError = data => ({
  type: FETCH_ACTIVATION_CODE_ERROR,
  data
});

// selectors

const getAuthState = state => state.auth;

export const getPasswordResetResult = state => ({
  error: getAuthState(state).passwordChangeError,
  success: getAuthState(state).passwordChangeSuccess
});

export const getIsLoading = state => getAuthState(state).loading;

export const getActivationCode = state => getAuthState(state).activationCode;

export const getAQrBase64 = state => getAuthState(state).qrBase64;

export const getRedirect = state => getAuthState(state).redirectAfterLogin;

export const getError = state => getAuthState(state).error;

export const getAuthStatusError = state => getAuthState(state).linkStatusError;

export const getReferralId = state => getAuthState(state).referralId;

export const isDoneWithAutoSignin = state =>
  getAuthState(state).initialCheckDone;

import React from 'react';

const BigLogo = props => (
  <svg id="Layer_1" viewBox="27 0 578 153" {...props}>
    <path
      fill="#ffffff"
      d="M561.8 68.9v5.4h-1v-5.4h-1.2v-.7h3.4v.7h-1.2zM568.3 74.3v-4c0-.6.1-1.3.1-1.3l-1.1 5.3h-1.1l-1.1-5.3s.1.7.1 1.3v4h-1v-6.1h1.6l.8 3.8c.1.5.1 1.2.1 1.2s0-.7.1-1.2l.8-3.8h1.6v6.1h-.9zM358.1 103.9c0 9.6-8 17.5-17.7 17.5-9.8 0-17.7-7.8-17.7-17.5h11.9c0 3.2 2.6 5.9 5.9 5.9 3.3 0 6-2.7 6-5.9 0-1.3-.5-2.7-1.3-3.6l-18.3-21.4c-2.6-3-4.2-7.1-4.2-11.4 0-9.6 8-17.5 17.7-17.5 9.8 0 17.7 7.8 17.7 17.5h-11.7c0-3.2-2.7-5.8-6-5.8s-5.9 2.6-5.9 5.8c0 1.4.4 2.7 1.2 3.6L354 92.5c2.7 3 4.1 7 4.1 11.4zM430.7 100v5.3c0 2.9 2.6 5.4 5.9 5.4 3.3 0 6-2.5 6-5.4h11.9c0 8.8-8 16-17.9 16-9.8 0-17.7-7.2-17.7-16V84c0-8.8 8-15.9 17.7-15.9 9.9 0 17.9 7.1 17.9 15.9v16h-23.8zm5.9-21.2c-3.3 0-5.9 2.3-5.9 5.3v5.4h11.7V84c.1-2.9-2.5-5.2-5.8-5.2zM477.1 121.2c-9.8 0-17.9-7.1-17.9-15.9s8.1-15.9 17.9-15.9h5.9V84c0-2.9-2.6-5.3-5.9-5.3-3.3 0-6 2.3-6 5.3h-11.9c0-8.8 8.1-15.9 17.9-15.9 9.8 0 17.8 7.1 17.8 15.9v21.3c-.1 8.8-8.1 15.9-17.8 15.9zm-6-15.9c0 2.9 2.7 5.3 6 5.3s5.9-2.3 5.9-5.3v-5.4h-5.9c-3.3 0-6 2.5-6 5.4zM541.6 68c9.8 0 17.7 7.1 17.7 15.9v37.2h-11.9V83.9c0-2.9-2.6-5.4-5.9-5.4-3.3 0-6 2.5-6 5.4v37.2h-11.9V83.9c0-2.9-2.6-5.4-5.9-5.4-3.3 0-6 2.5-6 5.4v37.2H500V83.9c0-8.8 8-15.9 17.7-15.9 4.6 0 8.7 1.5 11.9 4 3.3-2.5 7.5-4 12-4zM403.4 71.8v-3.3h-12.2V121h12.2l-.1-34c0-7.4 12.5-7.9 12.5-7.9V68.4s-8.9.8-12.4 3.4z"
    />
    <path
      fill="#fba60b"
      d="M305.6 68.3h11.8v37.8c0 7-4.9 13.1-11.8 15.2v11.9h-11.8v-11.9c-6.9-2.1-11.6-8.2-11.6-15.2V68.3h11.6v37.8c0 3 2.7 5.4 5.9 5.4s5.8-2.4 5.8-5.4V68.3zM306.1 143.6c0 3.5-2.9 6.4-6.4 6.4s-6.4-2.9-6.4-6.4 2.9-6.4 6.4-6.4 6.4 2.8 6.4 6.4z"
    />
    <path
      fill="#ffffff"
      d="M48.3 121.2c-9.7 0-17.6-7.9-17.6-17.6V68.3c0-9.7 7.9-17.7 17.6-17.7 9.7 0 17.6 8 17.6 17.7H54.1c0-3.2-2.6-5.9-5.8-5.9-3.2 0-5.9 2.7-5.9 5.9v35.3c0 3.2 2.7 5.8 5.9 5.8s5.8-2.6 5.8-5.8h11.8c-.1 9.7-8 17.6-17.6 17.6zM88.7 121.4c-9.8 0-17.7-7.1-17.7-16V68.3h11.8v37.1c0 2.9 2.6 5.4 5.9 5.4 3.2 0 5.8-2.5 5.8-5.4V68.3h11.8v37.1c0 8.9-7.9 16-17.6 16zM139.2 68.3h11.6V121h-11.6zM123 71.6v-3.3h-11.6V121H123V86.6c0-7.2 11.9-7.8 11.9-7.8V68.3c-.1 0-8.5.8-11.9 3.3zM174.1 121.4c-9.7 0-17.6-7.1-17.6-16V84.1c0-8.8 7.9-15.9 17.6-15.9 9.7 0 17.6 7.1 17.6 15.9v21.3c0 8.9-7.9 16-17.6 16zm0-42.5c-3.2 0-5.8 2.3-5.8 5.3v21.3c0 2.9 2.6 5.4 5.8 5.4 3.2 0 5.8-2.5 5.8-5.4V84.1c.1-2.9-2.5-5.2-5.8-5.2zM214.6 89.6c9.7 0 17.6 7.1 17.6 15.9 0 8.9-7.9 16-17.6 16-9.8 0-17.7-7.1-17.7-16h11.8c0 3 2.6 5.4 5.9 5.4 3.2 0 5.8-2.3 5.8-5.4 0-2.9-2.6-5.3-5.9-5.3-9.7 0-17.6-7.1-17.6-15.9 0-8.9 7.9-16 17.7-16 9.7 0 17.6 7.1 17.6 16h-11.8c0-3-2.6-5.4-5.8-5.4-3.4 0-5.9 2.3-5.9 5.4 0 3 2.5 5.3 5.9 5.3zM236.7 68.3h11.6V121h-11.6zM271.5 109.2h5.8V121h-5.8c-9.7 0-17.6-7.9-17.6-17.6v-53h11.6v17.9h11.8v11.5h-11.8v23.6c0 3.2 2.7 5.8 6 5.8zM380.3 109.2h5.8V121h-5.8c-9.7 0-17.6-7.9-17.6-17.6v-53h11.6v17.9h11.8v11.5h-11.8v23.6c0 3.2 2.8 5.8 6 5.8z"
    />
  </svg>
);

export default BigLogo;

import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Grid from 'components/Grid';
import { fireEvent } from 'utils/analytics';
import Spinner from 'components/Spinner';

import {
  getFetchingMedia,
  fetchHistory,
  historyMedia,
} from 'reducers/Medias/reducer';

class History extends React.Component {
  componentDidMount() {
    this.props.fetchHistory();

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'history',
    });
  }

  render() {
    if (this.props.isFetching) return <Spinner />;

    if (!this.props.historyMedia || this.props.historyMedia.length === 0)
      return (
        <>
          <FormattedMessage id="smarttv:search_no_results_found" />{' '}
          <FormattedMessage id="smarttv:account_sidenav_history_button" />
        </>
      );

    return (
      <Grid media={this.props.historyMedia.slice(0, 24)} rowSize={3} noMargin />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: getFetchingMedia(state),
  historyMedia: historyMedia(state),
});

const mapDispatchToProps = {
  fetchHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(History);

import React from 'react';
import styles from './styles.module.css';
import ReactDOM from 'react-dom';

const MediaProgressComponent = ({ progressPercentage, focus, className, rangeFillClassName }) => {
  return progressPercentage > 2 ?(
    <div className={`${styles.range} ${className}`} ref={
      focus
        ? node => {
          const domNode = ReactDOM.findDOMNode(node);
          domNode && domNode.focus();
        }
        : null
    }>
      <div
        className={`${styles.rangeFill} ${rangeFillClassName}`}
        style={{
          width: `${progressPercentage}%`,
        }}
      />
    </div>
  ) : null;
};

export default MediaProgressComponent;

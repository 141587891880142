import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

export default (reducers = {}, middleware = []) => {
  // Add the reducer to your store on the `router` key
  // Also apply our middleware for navigating
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    combineReducers({
      ...reducers
    }),
    composeEnhancers(applyMiddleware(...middleware))
  );
};

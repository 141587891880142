import React from 'react';

import { dNav } from 'config';
import { withConfig } from 'hoc';

import Button from 'components/Button';

import styles from './styles.module.css';

const LETTERS = [
  [null, 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '<-'],
  ['123', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'enter'],
  ['ABC', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '@', '.', 'close'],
  [null, 'space']
];

const NUMS = [
  [1, 2, 3, null, '!', '@', '#', '$', '[', ']', '\\', ',', '.', '?'],
  [4, 5, 6, null, '%', '^', '&', '*', '{', '}', '|', '<', '>', '/'],
  [7, 8, 9, null, '(', ')', '_', '-', '+', ';', ':', '"', "'", null],
  [0, null, null, null,'ABC', null, null, null, null, null, null, null, null]
];

class Keyboard extends React.Component {
  state = {
    shownKeyboard: LETTERS,
    caps: false
  };

  componentDidMount() {
    if (dNav) dNav.focusRoot = this.root;
    window.addEventListener('keydown', this.keydown);
  }

  keydown = e => {
    if (e.key) {
      if (
        this.props.config.numericFix &&
        e.code &&
        e.code.includes('Digit')
      )
        return;

      if (e.key.length === 1) {
        this.props.onKeyStroke(e.key);
      }
      if (e.key === 'Backspace') {
        this.props.onErase();
      }
    }
  };

  componentWillUnmount() {
    if (dNav) dNav.focusRoot = document.body;
    window.removeEventListener('keydown', this.keydown);
  }

  renderLetter = (letter, index) => {
    if (letter === null) {
      return <div key={`letter${index}`} className={styles.letter} />;
    }

    const { shownKeyboard, caps } = this.state;
    const { onKeyStroke, onErase, onEnter, onClose } = this.props;

    const capLetter =
      `${letter}`.length === 1 && caps && letter.toUpperCase
        ? letter.toUpperCase()
        : letter;

    return (
      <Button
        noTransition
        key={`letter${index}`}
        id={`letter${index}`}
        style={{
          width:
            `${letter}`.length === 1
              ? '6%'
              : letter === 'space'
              ? '51%'
              : '12.4%',
          margin: '0.25%',
          padding: '  1.2% 2.2%',
          background: 'white',
          color: 'black'
        }}
        onClick={() => {
          if (letter === null) {
            return;
          }
          if (letter === 'space') {
            onKeyStroke(' ');
          } else if (letter === '123') {
            this.setState({ shownKeyboard: NUMS });
          } else if (letter === 'ABC') {
            this.setState({
              shownKeyboard: LETTERS,
              caps: shownKeyboard === LETTERS ? !caps : false
            });
          } else if (letter === '<-') {
            onErase();
          } else if (letter === 'enter') {
            onEnter();
          } else if (letter === 'close') {
            onClose();
          } else {
            onKeyStroke(capLetter);
          }
        }}
      >
        {capLetter}
      </Button>
    );
  };

  renderRow = (row, index) => {
    return <div key={`row${index}`}>{row.map(this.renderLetter)}</div>;
  };

  shouldComponentUpdate(_, nextState) {
    if (nextState.shownKeyboard !== this.state.shownKeyboard) {
      return true;
    }

    if (nextState.caps !== this.state.caps) {
      return true;
    }

    return false;
  }

  render() {
    const { shownKeyboard } = this.state;
    return (
      <div className={styles.main} ref={node => (this.root = node)}>
        {shownKeyboard.map(this.renderRow)}
      </div>
    );
  }
}

// Keyboard.propTypes = {
//   onKeyStroke: PropTypes.func.isRequired,
//   onErase: PropTypes.func.isRequired,
//   onClose: PropTypes.func.isRequired,
//   onEnter: PropTypes.func.isRequired
// };

export default withConfig(Keyboard);

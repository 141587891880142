import { mapUser } from 'utils/mapper';

import { LOGIN_SUCCESS, SIGN_OUT } from '../AuthManager/reducer';

// constants

export const FETCH_USER = 'cs.com/Profile/FETCH_USER';
export const FETCH_USER_SUCCESS = 'cs.com/Profile/FETCH_USER_SUCCESS';

// reducer

const defaultState = {
  loading: false,
  error: null
};

const profile = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        giftCardPurchaseStatus: null,
        userData: mapUser(action.user),
        cardNeedsToBeUpdated: false
      };
    case LOGIN_SUCCESS:
      try {
        localStorage.setItem('externalId', action.data.external_id);
      } catch (e) {
        //nope
      }
      return {
        ...state,
        loading: false,
        userData: mapUser(action.data)
      };
    case SIGN_OUT:
      try {
        localStorage.removeItem('externalId');
      } catch (e) {
        //nope
      }
      return {
        ...state,
        userData: null
      };
    case FETCH_USER:
      return {
        ...state,
        cardNeedsToBeUpdated: false,
        loading: true
      };
    default:
      return state;
  }
};

export default profile;

// actions creators

export const fetchUser = () => ({
  type: FETCH_USER
});

export const gotUser = user => ({
  type: FETCH_USER_SUCCESS,
  user
});

// selectors

const getProfileState = state => state.profile;

export const getIsLoading = state => getProfileState(state).loading;

export const getUserData = state => getProfileState(state).userData;

import React from 'react';
import { WebVTT, VTTCue } from 'vtt.js';

class VTT extends React.Component {
  state = {
    vttCues: []
  };

  loadVttFromUrl(url) {
    setTimeout(() => {
      window.VTTCue = VTTCue; // necessary for Edge compatibility (see https://github.com/mozilla/vtt.js/issues/352)

      this.setState({ vttCues: [] });

      if (url && url.length) {
        if (this.xhr) {
          this.xhr.abort();
          this.xhr.onreadystatechange = null;
        }
        const component = this;
        this.xhr = new XMLHttpRequest();
        this.xhr.open('GET', url);
        this.xhr.onerror = this.props.onError;
        this.xhr.onreadystatechange = function onReadyStateChange() {
          if (component.xhr.readyState === 4) {
            const parser = new WebVTT.Parser(window, WebVTT.StringDecoder());
            parser.oncue = function onCue(cue) {
              component.setState({
                vttCues: [...component.state.vttCues, cue]
              });
            };

            parser.parse(component.xhr.responseText);
            parser.flush();
          }
        };
        this.xhr.send();
      }
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vttUrl !== this.props.vttUrl) {
      this.loadVttFromUrl(this.props.vttUrl);
    }
  }

  componentDidMount() {
    this.loadVttFromUrl(this.props.vttUrl);

    if (this.props.videoElement.addEventListener)
      this.props.videoElement.addEventListener('timeupdate', this.timeUpdate);
    else
      this.timer = setInterval(() => {
        this.timeUpdate();
      }, 500);
  }

  timeUpdate = () => {
    if (this.props.onLog && this.props.videoElement)
      this.props.onLog(this.props.videoElement.currentTime);

    WebVTT.processCues(
      window,
      this.getActiveCues(this.props.videoElement.currentTime),
      this.renderArea
    );
  };

  componentWillUnmount() {
    if (this.props.videoElement.removeEventListener)
      this.props.videoElement.removeEventListener(
        'timeupdate',
        this.timeUpdate
      );
    else clearInterval(this.timer);

    if (this.xhr) {
      this.xhr.abort();
      this.xhr.onreadystatechange = null;
    }
  }

  getActiveCues(time) {
    const currentCue = this.state.vttCues.filter(
      cue => cue.startTime <= time && cue.endTime >= time
    );
    if (this.props.onLog && currentCue && currentCue[0])
      this.props.onLog(currentCue[0].text);
    return currentCue;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.options !== nextProps.options) {
      return true;
    }
    if (this.state.cssClassNames !== nextState.cssClassNames) {
      return true;
    }
    return false;
  }

  getCueText(cue) {
    return cue.toJSON().text;
  }

  render() {
    return (
      <div ref={node => (this.renderArea = node)} className="subtitleDiv" />
    );
  }
}

export default VTT;

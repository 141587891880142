import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.css';

import Button from 'components/Button';
import Text from 'components/Text';

import { secondsToPlayerTime, toTitleCase } from 'utils/formatters';

const Bottom = props => (
  <div className={styles.bottomDarken} style={props.style}>
    <Text
      noMargin
      className={styles.time}
      style={{
        left: '5%',
      }}
    >
      {secondsToPlayerTime(props.currentTime)}
    </Text>
    <Text
      noMargin
      className={styles.time}
      style={{
        right: '5%',
      }}
    >
      {secondsToPlayerTime(props.duration - props.currentTime)}
    </Text>
    {props.onClickGoTo && (
      <div className={styles.bottomLeft}>
        <Button
          id="gotoshow"
          focus={props.focusedButton === 'gotoshow'}
          style={{ zIndex: 99999999999999999 }}
          noTransition
          onClick={props.onClickGoTo}
        >
          Go to Show
        </Button>
      </div>
    )}
    {props.closedCaptions?.length > 1 || props.audioTracks?.length > 1 ? (
      <div className={styles.bottomRight}>
        {props.audioTracks?.length > 1 && props.selectedAudioTrack ? (
          <Button
            id="audioTracks"
            style={{ zIndex: 99999999999999999 }}
            focus={props.focusCcButton}
            noTransition
            onClick={props.onAudioTracksClick}
          >
            <FormattedMessage id="smarttv:legacy_audio_language" />
            {' - '}
            {toTitleCase(props.selectedAudioTrack.lang)}
          </Button>
        ) : null}
        {props.closedCaptions?.length > 1 ? (
          <Button
            id="cc"
            style={{ zIndex: 99999999999999999 }}
            focus={props.focusCcButton}
            noTransition
            onClick={props.onSubtitlesClick}
          >
            <FormattedMessage id="smarttv:legacy_subtitles" />
            {` (${
              props.selectedCc ? (
                props.selectedCc.label
              ) : (
                <FormattedMessage id="smarttv:legacy_off" />
              )
            })`}
          </Button>
        ) : null}
      </div>
    ) : null}
    {props.children}
  </div>
);

Bottom.defaultProps = {
  closedCaptions: [],
};

export default Bottom;

import { getConfig } from 'config';
import uniq from 'lodash.uniq';

const synth = window.speechSynthesis;

let lastUtterance = null;
let lastUtteranceTime = null;

const wasJustSaid = thing => {
  try {
    return (
      JSON.stringify(thing) === JSON.stringify(lastUtterance) &&
      new Date().getTime() - lastUtteranceTime < 500
    );
  } catch (e) {
    return false;
  }
};

const sayThing = thing => {
  if (window.VIZIO) {
    try {
      window.VIZIO.Chromevox.play(thing);
    } catch (e) {
      // bad dudes
    }
  } else if (window.SpeechSynthesisUtterance) {
    const utterance = new window.SpeechSynthesisUtterance(thing);
    synth.speak(utterance);
  }
};

export const say = input => {
  const shouldSpeak = getConfig().getIsTTSEnabled() && !wasJustSaid(input);

  if (shouldSpeak) {
    lastUtterance = input;
    lastUtteranceTime = new Date().getTime();
    setTimeout(() => {
      synth && synth.cancel();
      if (Array.isArray(input)) {
        uniq(input.map(i => i.toLowerCase())).forEach(s => {
          sayThing(s);
        });
      } else {
        sayThing(input);
      }
    }, 200);
  }
};

import React from "react";

const Mute = props => (
  <svg width={25} height={20} viewBox="0 0 25 20" {...props}>
    <defs>
      <filter
        x="-50%"
        y="-50%"
        width="200%"
        height="200%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter
        x="-50%"
        y="-50%"
        width="200%"
        height="200%"
        filterUnits="objectBoundingBox"
        id="b"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="#FFF" fillRule="evenodd">
      <path
        d="M10.718.754a.515.515 0 0 0-.531.031L5.454 4.063H.786a.516.516 0 0 0-.515.515v7.011c0 .286.231.518.515.518h4.675l4.737 3.114a.517.517 0 0 0 .526.023.516.516 0 0 0 .27-.454V1.212a.517.517 0 0 0-.276-.458z"
        filter="url(#a)"
        transform="translate(0 2)"
      />
      <g filter="url(#b)" transform="translate(15 7)">
        <path d="M.948 6.73a.676.676 0 0 1-.48-.197c-.263-.262-.263-.7 0-.985L5.541.452c.262-.262.7-.262.984 0 .285.262.262.7 0 .985L1.452 6.533a.767.767 0 0 1-.504.197z" />
        <path d="M6.045 6.73a.677.677 0 0 1-.48-.197L.466 1.437c-.262-.262-.262-.7 0-.985.262-.262.7-.262.985 0l5.074 5.075c.262.262.262.7 0 .984a.617.617 0 0 1-.48.219z" />
      </g>
    </g>
  </svg>
);

export default Mute;

import React from 'react';
import { withConfig } from 'hoc';

import Text from 'components/Text';

import styles from './styles.module.css';

class Terms extends React.Component {
  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = e => {
    if (e.keyCode === this.props.config.keyCodes.up) {
      this.mainDiv.scrollTop -= 200;
    }
    if (e.keyCode === this.props.config.keyCodes.down) {
      this.mainDiv.scrollTop += 200;
    }
  };

  render() {
    return (
      <>
        <div
          className={styles.textView}
          style={{ height: window.innerHeight }}
          ref={node => (this.mainDiv = node)}
        >
          {this.props.children}
        </div>
        <div className={styles.footer}>
          <Text large>Press the back/return button to dismiss</Text>
        </div>
      </>
    );
  }
}

export default withConfig(Terms);

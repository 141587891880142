import logger from 'utils/logger';
import { take, put, call, all, takeLatest } from 'redux-saga/effects';
import {
  SIGN_IN,
  SIGN_OUT,
  FETCH_ACTIVATION_CODE,
  CHECK_AUTH_STATUS,
  authSuccess,
  loginError,
  clearInitialCheck,
  fetchActivationCodeSuccess,
  checkAuthStatusFailed,
} from './reducer';
import { auth } from 'utils/tokenManager';
import request from 'utils/request';

import { fireEvent } from 'utils/analytics';
import braze from 'utils/braze';

import { setUserIdForTrackers } from 'utils/analytics';

import { setApproveTracking, showConsentModal } from 'utils/gdpr';

const log = logger.extend('auth');

function* logWithCredentials(credentials) {
  return yield call(request, `${process.env.REACT_APP_API_ROOT}/v1/login`, {
    method: 'POST',
    mode: 'cors',
    body: credentials,
  });
}

function* logWithToken() {
  return yield call(request, `${process.env.REACT_APP_API_ROOT}/v1/user`);
}

function* completeAuthAndInitUser(responsePayload) {
  if (responsePayload.user_notices) {
    if (responsePayload.user_notices.includes('eu-privacy-policy')) {
      showConsentModal({ gdpr: true });
    }

    if (responsePayload.user_notices.includes('privacy-policy-update')) {
      showConsentModal({ privacy: true });
    }
  }

  if (responsePayload.privacy_opt_out !== null) {
    log('privacy_opt_out', responsePayload.privacy_opt_out);
    setApproveTracking(!responsePayload.privacy_opt_out);
  }

  const [media, series] = yield all([
    call(request, `${process.env.REACT_APP_API_ROOT}/v1/user_media`),
    call(request, `${process.env.REACT_APP_API_ROOT}/v1/user_collection`),
  ]);

  yield put(authSuccess(responsePayload, [...media.data, ...series.data]));
}

function* authFlowSaga() {
  let token = yield call(auth.get);

  if (token) {
    try {
      log('token found');
      token = yield call(logWithToken, token);
      const responsePayload = token.data;
      setUserIdForTrackers(token.data.referral_id);

      braze.setUser(token.data);
      fireEvent(
        {
          category: `login`,
          action: 'succeed',
        },
        { nonInteraction: true }
      );

      yield call(completeAuthAndInitUser, responsePayload);

      yield take(SIGN_OUT);
      log('signing out');

      yield call(auth.remove);
      token = null;
      setUserIdForTrackers(null);
    } catch (e) {
      log('token error', e);
      fireEvent({
        category: `fail`,
        action: 'succeed',
        label: 'token error',
      });

      try {
        yield call(auth.remove);
        setUserIdForTrackers(null);
        yield put(loginError());
      } catch (e) {}
    }
  } else {
    log('no token found');
    yield put(clearInitialCheck());
  }

  while (!token) {
    const { credentials } = yield take(SIGN_IN);

    try {
      if (credentials.email && credentials.password) {
        log('signing in', credentials);
        token = yield call(logWithCredentials, credentials);
        const responsePayload = token.message;
        setUserIdForTrackers(responsePayload.referral_id);

        braze.setUser(responsePayload);
        fireEvent({
          category: `login`,
          action: 'succeed',
        });

        yield call(auth.set, responsePayload.auth_token);
        yield call(completeAuthAndInitUser, responsePayload);
        log('signing in success');

        yield take(SIGN_OUT);
        log('signing out');

        yield call(auth.remove);
        token = null;
        setUserIdForTrackers(null);
      } else {
        try {
          yield put(loginError('Please fill both email and password fields.'));
        } catch (e) {}
      }
    } catch (e) {
      log('error', e);
      token = null;
      fireEvent({
        category: `login`,
        action: 'fail',
        label: 'Wrong email or password',
      });

      try {
        yield put(loginError('Wrong email or password.'));
      } catch (e) {}
    }
  }
}

export function* fetchActivationCode(action) {
  try {
    const data = yield call(
      request,
      `${process.env.REACT_APP_API_ROOT}/link-code`,
      {
        method: 'POST',
        body: {
          client_identifier: action.clientIdentifier,
        },
      }
    );

    yield put(fetchActivationCodeSuccess(data));
  } catch (e) {
    console.error(e);
  }
}

export function* checkAuthStatus(action) {
  try {
    const data = yield call(
      request,
      `${process.env.REACT_APP_API_ROOT}/link-auth`,
      {
        method: 'POST',
        body: {
          client_identifier: action.payload.clientIdentifier,
          device_link_code: action.payload.deviceLinkCode,
        },
      }
    );

    if (data.auth_token) {
      yield call(auth.set, data.auth_token);
      window.location.reload();
    }

    // yield put(fetchActivationCodeSuccess(data));
  } catch (e) {
    yield put(checkAuthStatusFailed());
    console.error(e);
  }
}

export function* getActivationCode() {
  yield takeLatest(FETCH_ACTIVATION_CODE, fetchActivationCode);
}

export function* getAuthStatus() {
  yield takeLatest(CHECK_AUTH_STATUS, checkAuthStatus);
}

export default [authFlowSaga, getActivationCode, getAuthStatus];

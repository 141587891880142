import React from 'react';
import ReactDOM from 'react-dom';

import Top from './Top';
import Bottom from './Bottom';
import Controls from './Controls';
import Preview from './Preview';

class Overlay extends React.Component {
  static Top = Top;
  static Bottom = Bottom;
  static Controls = Controls;
  static Preview = Preview;

  render() {
    // Using a portal is mandatory to let overlay elements being displayed
    // over other dom elements that are not directly related (see specific
    // video players i.e. Vizio and Tizen)
    return ReactDOM.createPortal(
      this.props.children,
      document.getElementById('player-overlay')
    );
  }
}

export default Overlay;

import React from 'react';

import { withAuth, withConfig } from 'hoc';

import Button from 'components/Button';
import Text from 'components/Text';
import { BigLogo } from 'components/Icons';

import styles from './styles.module.css';
import request from 'utils/request';
import OnNowLogo from 'components/OnNow/Logo';
import { injectIntl } from 'react-intl';

class Navbar extends React.Component {
  state = {};

  componentDidMount() {
    window.addEventListener('keydown', e => {
      if (
        this.props.config.name === 'xbox' &&
        e.keyCode === this.props.config.keyCodes.search
      ) {
        this.props.history.push('/search');
      }
    });

    if (this.props.isLogged) this.checkOnNowButton();
  }

  checkOnNowButton() {
    request(`${process.env.REACT_APP_API_ROOT}/v1/user`)
      .catch(() => this.setState({ onNow: false }))
      .then(user => {
        this.setState({ onNow: false });
        // disabling on now due to performance issues.
        // try {
        //   let onNow =
        //     user.data.experiments['on-now'] &&
        //     ['tizen', 'lg', 'vizio'].includes(this.props.config.name);

        //   if (
        //     this.props.config.name == 'lg' &&
        //     this.props.config.device.version < '3.0.0'
        //   )
        //     onNow = false;

        //   this.setState({ onNow });
        // } catch (e) {
        //   console.error(e);
        // }
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.onNow !== nextState.onNow) {
      return true;
    }
    if (this.props.isLogged !== nextProps.isLogged) {
      return true;
    }

    if (
      this.getActiveButton(this.props.location) !==
      this.getActiveButton(nextProps.location)
    ) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    if (
      this.getActiveButton(this.props.location) !==
      this.getActiveButton(prevProps.location)
    ) {
      setTimeout(() => {
        this.focusActiveNavButton();
      }, 250);
    }

    if (prevProps.isLogged !== this.props.isLogged) {
      this.checkOnNowButton();
    }
  }

  focusActiveNavButton = () => {
    const activeButton = document.getElementById(
      `navlink-${this.getActiveButton(this.props.location)}`
    );
    activeButton && activeButton.focus();
  };

  getActiveButton = location => {
    if (location.pathname === '/') {
      return '';
    } else if (location.pathname.indexOf('/collections') === 0) {
      return 'collections';
    } else if (location.pathname.indexOf('/onnow') === 0) {
      return 'onnow';
    } else if (location.pathname.indexOf('/browse') === 0) {
      return 'browse';
    } else if (location.pathname.indexOf('/categories') === 0) {
      return 'categories';
    } else if (location.pathname.indexOf('/search') === 0) {
      return 'search';
    } else if (location.pathname.indexOf('/profile') === 0) {
      return 'profile';
    } else if (location.pathname.indexOf('/login') === 0) {
      return 'login';
    }
  };

  renderNavLink = ({ label, route, content }, index, array) => (
    <Button
      focusDownOverride="#login-input"
      id={`navlink-${route}`}
      blockRight={index === array.length - 1}
      blockLeft={index === 0}
      borderPosition="Bottom"
      key={route}
      active={this.getActiveButton(this.props.location) === route}
      onClick={() => this.props.history.push(`/${route}`)}
      className={styles.navLink}
      style={{
        transition: this.props.config.performanceIndex < 4000 ? '350ms' : '0s',
        ...(content
          ? {
              marginTop: 0,
              paddingTop: 0,
              paddingBottom: '0.25em',
              transform: 'translateY(0.4em)',
            }
          : {}),
      }}
    >
      {content || (
        <Text
          as="span"
          thin={false}
          shadow
          style={{ textTransform: 'capitalize' }}
        >
          {label}
        </Text>
      )}
    </Button>
  );

  render() {
    const { show, isLogged, intl } = this.props;

    if (!show) {
      return null;
    }

    const buttons = [
      { label: intl.formatMessage({ id: 'smarttv:nav_home' }), route: '' },
      isLogged &&
        this.state.onNow && {
          content: (
            <OnNowLogo
              aria-label="on now"
              style={{ height: '1.5em' }}
              rectangle={this.state.onNow === 'red' ? 'blink-red' : 'hidden'}
            />
          ),
          route: 'onnow',
        },
      {
        label: intl.formatMessage({ id: 'smarttv:nav_collections' }),
        route: 'collections',
      },
      {
        label: intl.formatMessage({ id: 'smarttv:nav_browse' }),
        route: 'categories',
      },
      {
        label: intl.formatMessage({ id: 'smarttv:nav_search' }),
        route: 'search',
      },
      isLogged
        ? {
            label: intl.formatMessage({
              id: 'smarttv:account_sidenav_account_button',
            }),
            route: 'profile',
          }
        : {
            label: intl.formatMessage({ id: 'smarttv:common_sign_in_button' }),
            route: 'login',
          },
    ].filter(b => !!b);

    return (
      <div className={styles.root} data-cy="navbar">
        <div className={styles.applogo}>
          <BigLogo />
        </div>
        <div className={styles.navContent}>
          {buttons.map(this.renderNavLink)}
        </div>
      </div>
    );
  }
}

export default withConfig(withAuth(injectIntl(Navbar)));

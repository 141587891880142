import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';

import {
  FETCH_RELATED,
  FETCH_ALL_SERIES,
  FETCH_SERIES,
  gotSeries,
  gotRelatedVideos,
} from './reducer';
import request from 'utils/request';
import imageQueryBuilder from 'utils/imageQueryBuilder';
import qpb from 'utils/queryParamsBuilder';
import { getConfig } from 'config';

export const seriesUrl = `${process.env.REACT_APP_API_ROOT}/v2/series`;

export function* fetchAllSeries() {
  try {
    const response = yield call(request, seriesUrl);
    const series = response.data;
    yield put(gotSeries(series));
  } catch (e) {
    console.error(e);
  }
}

export function* fetchSeries(action) {
  try {
    let { encodingsParams } = getConfig();

    const limitToHd = localStorage.getItem('limitToHD') === 'true';
    if (limitToHd)
      encodingsParams.encodingsMaxHeight = Math.min(
        encodingsParams.encodingsMaxHeight,
        1080
      );

    const paramsObject = {
      forceCDN: action.forceCDN,
      ...encodingsParams,
    };

    const paramsString = qpb(paramsObject);

    const response = yield call(
      request,
      `${seriesUrl}/${action.seriesId}${paramsString}&${imageQueryBuilder()}`
    );
    const series = response.data;
    yield put(gotSeries([series]));
  } catch (e) {
    console.error(e);
  }
}
export function* fetchRelatedMedias(action) {
  try {
    const response = yield call(
      request,
      `${
        process.env.REACT_APP_API_ROOT
      }/v1/media?filterBy=series-recommended&collections=true&term=${
        action.seriesId
      }&${imageQueryBuilder()}`
    );
    const media = response.data;
    yield put(gotRelatedVideos(action.seriesId, media));
  } catch (e) {
    console.error(e);
  }
}

export function* getAllSeries() {
  yield takeLatest(FETCH_ALL_SERIES, fetchAllSeries);
}

export function* getSeries() {
  yield takeEvery(FETCH_SERIES, fetchSeries);
}

export function* getRelatedVideos() {
  yield takeLatest(FETCH_RELATED, fetchRelatedMedias);
}

export default [getSeries, getAllSeries, getRelatedVideos];

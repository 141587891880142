import React from 'react';

import Button from 'components/Button';
import { withConfig } from 'hoc';

import ProgressBar from 'components/Progress';
import { Play, Pause } from 'components/Icons';
import styles from './styles.module.css';

class Controls extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.playing !== this.props.playing ||
      nextProps.focusedButton !== this.props.focusedButton ||
      (!!nextProps.focusedButton && nextProps.progress !== this.props.progress)
    );
  }

  componentDidMount() {
    setTimeout(() => {
      this.forceUpdate();
    }, 1000);
  }

  render() {
    const {
      onPlay,
      onPause,
      playing,
      progress,
      isHidden,
      focusedButton,
      config,
      jumpBack,
      jumpDirect,
      buffering,
      jumpForward,
      canControl,
    } = this.props;

    return (
      <div className={styles.controls}>
        <ProgressBar
          focusable={canControl}
          focused={focusedButton === 'scrubber'}
          isHidden={isHidden}
          ariaLabel="progress bar"
          onClickedPosition={jumpDirect}
          className={styles.progressBar}
          style={
            config.isPresto
              ? {
                  position: 'fixed',
                  bottom: '20%',
                  height: '1%',
                }
              : undefined
          }
          jumpBack={jumpBack}
          jumpForward={jumpForward}
          value={progress}
        />
        {canControl &&
          (playing ? (
            <Button
              style={{
                opacity: buffering ? 0 : 1,
              }}
              focus={focusedButton && focusedButton !== 'scrubber'}
              key="play"
              noTransition
              ariaLabel="play"
              onClick={onPause}
            >
              {/* the following weird ternary is necessary - for some reason, Presto engines won't initially display the icon if we rely on the `invertPlayPause` config prop. */}
              {config.isPresto ? (
                <Pause />
              ) : config.invertPlayPause ? (
                <Pause />
              ) : (
                <Play />
              )}
            </Button>
          ) : (
            <Button
              style={{
                opacity: buffering ? 0 : 1,
              }}
              focus={focusedButton && focusedButton !== 'scrubber'}
              key="pause"
              noTransition
              ariaLabel="pause"
              onClick={onPlay}
            >
              {/* the following weird ternary is necessary - for some reason, Presto engines won't initially display the icon if we rely on the `invertPlayPause` config prop. */}
              {config.isPresto ? (
                <Play />
              ) : config.invertPlayPause ? (
                <Play />
              ) : (
                <Pause />
              )}
            </Button>
          ))}
      </div>
    );
  }
}

export default withConfig(Controls);

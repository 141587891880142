import algoliasearch from 'algoliasearch/lite';
import aa from 'search-insights';
import { useState, useEffect } from 'react';
import useDebounce from 'utils/useDebounce';
import { useStore } from 'react-redux';

const APP_ID = '261QMVOKMX';
const API_KEY = '0efb0a4fabee2fad3b6ed3b87b0b6a53';

const searchClient = algoliasearch(APP_ID, API_KEY);

const isGeoBlocked = ({ mediaGeoList, mediaGeoblockType }) => {
  try {
    const userCountry = localStorage.getItem('country');

    if (!userCountry || !mediaGeoList || mediaGeoList.length === 0)
      return false;

    const countryCodes = mediaGeoList.split(',');

    if (countryCodes.length === 0) return false;

    const isUserCountryInMediaList = countryCodes.includes(userCountry);

    return mediaGeoblockType === 'block'
      ? isUserCountryInMediaList
      : !isUserCountryInMediaList;
  } catch (e) {
    return false;
  }
};

const getExternalId = () => {
  try {
    return localStorage.getItem('externalId');
  } catch (e) {
    //woops
  }
};

aa('init', {
  appId: APP_ID,
  apiKey: API_KEY
});

const BASE_INDEX = 'smart-tv';

let _lastQueryID, _lastQueryResults, _selectedSearchResult;

// used to navigate to the most relevant series episode if a series card is clicked
// on the search results
export const popLastSelectedSearchResult = () => {
  const result = _selectedSearchResult;
  _selectedSearchResult = null;
  return result;
};

export const searchAnalyticsClickEvent = ({ eventName, media }) => {
  try {
    const objectIDs = (media.isSeries ? media.mediaIds : [media.id])
      .slice(0, 20)
      .map(id => `${id}`);

    _selectedSearchResult = media;

    const positions = objectIDs.map(
      () => _lastQueryResults.findIndex(a => a.videoId == objectIDs[0]) + 1
    );

    if (objectIDs.length === positions.length) {
      aa('clickedObjectIDsAfterSearch', {
        userToken: getExternalId(),
        eventName,
        index: BASE_INDEX,
        queryID: _lastQueryID,
        objectIDs,
        positions
      });
    }
  } catch (e) {}
};

export const searchAnalyticsConversionEvent = ({ eventName, videoId }) => {
  try {
    const position = _lastQueryResults.findIndex(a => a.videoId == videoId);

    if (_lastQueryID && position > -1) {
      aa('convertedObjectIDsAfterSearch', {
        userToken: getExternalId(),
        eventName,
        index: BASE_INDEX,
        queryID: _lastQueryID,
        objectIDs: [`${videoId}`]
      });
    }
  } catch (e) {}
};

export const useQuery = query => {
  const store = useStore();

  const user = store.getState().profile.userData;

  const searchIndex = searchClient.initIndex(BASE_INDEX);

  const [page, setCurrentPage] = useState(0);
  const [results, setResults] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    _lastQueryID = null;
    _lastQueryResults = [];
  }, []);

  let debouncedQuery = useDebounce(query, 1000);

  useEffect(() => {
    if (!debouncedQuery && debouncedQuery.length === 0) return;

    setIsFetching(true);

    searchIndex
      .search(debouncedQuery, {
        clickAnalytics: true,
        hitsPerPage: 30,
        removeWordsIfNoResults: 'allOptional', // removes words if a search debouncedQuery does not return results
        page,
        headers: {
          'X-Algolia-UserToken': getExternalId()
        }
      })
      .then(({ hits, queryID }) => {
        _lastQueryID = queryID;

        const filterChildFriendlyOnly = user?.isChildFriendlyOnly;

        const mappedHits = hits
          .map(r => ({ ...r, id: r.objectID }))
          // filters out geoblocked items...
          .filter(
            result =>
              !isGeoBlocked({
                mediaGeoList: result.geoBlockList,
                mediaGeoblockType: result.geoBlockType
              })
          )
          // filters out kids content...
          .filter(result => !filterChildFriendlyOnly || result.kidFriendly);

        let groupedResults = [];

        for (const hit of mappedHits) {
          const groupedSeriesIndex = groupedResults.findIndex(
            a =>
              hit.canBeGroupedWithThisSeriesId &&
              ((a.seriesId &&
                a.seriesId === hit.canBeGroupedWithThisSeriesId) ||
                (a.is_collection && a.id === hit.canBeGroupedWithThisSeriesId))
          );
          if (groupedSeriesIndex > -1) {
            // TODO add condition on groupedResults[groupedSeriesIndex].episodes_count. Grouping
            // media into a series should be done only if most of them part of the search results.
            groupedResults[groupedSeriesIndex] = {
              title: hit.seriesTitle,
              id: hit.seriesId,
              description: hit.seriesDesc,
              media_count: hit.episodes_count,
              is_collection: true,
              media_ids: groupedResults[groupedSeriesIndex].is_collection
                ? [...groupedResults[groupedSeriesIndex].media_ids, hit.id]
                : [groupedResults[groupedSeriesIndex].id, hit.id]
            };
          } else groupedResults.push(hit);
        }

        _lastQueryResults = [..._lastQueryResults, ...hits];

        setIsFetching(false);

        setResults(groupedResults);
      });
  }, [debouncedQuery, page]);

  // we should destroy whenever possible, i.e. when search input is blurred.
  // useEffect(() => searchIndex.destroy, []);

  return { results, loadMore: () => setCurrentPage(page + 1), isFetching };
};

import { mapGroup, mapFeatured, mapMedia } from 'utils/mapper';
import {
  getMediaByKey,
  getSuggestedResumeTimeAndPercentage,
} from '../Medias/reducer';
import { LOGIN_SUCCESS, SIGN_OUT } from '../AuthManager/reducer';

export const FETCH_UNTYPED_GROUP = 'cs.com/Home/FETCH_UNTYPED_GROUP';
export const FETCH_UNTYPED_GROUP_SUCCESS =
  'cs.com/Home/FETCH_UNTYPED_GROUP_SUCCESS';

export const FETCH_HOME_FEED = 'cs.com/Home/FETCH_HOME_FEED';
export const FETCH_HOME_FEED_SUCCESS = 'cs.com/Home/FETCH_HOME_FEED_SUCCESS';

export const FETCH_FEATURED_ITEMS = 'cs.com/Home/FETCH_FEATURED_ITEMS';
export const FETCH_FEATURED_ITEMS_SUCCESS =
  'cs.com/Home/FETCH_FEATURED_ITEMS_SUCCESS';
export const FETCH_FEATURED_ITEMS_FAILURE =
  'cs.com/Home/FETCH_FEATURED_ITEMS_FAILURE';

export const FETCH_FAILURE = 'cs.com/Home/FETCH_FAILURE';

// reducer

export const defaultState = {
  isFetching: true,
};

const home = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_HOME_FEED:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_HOME_FEED_SUCCESS:
      const groups = action.groups.map(mapGroup);
      return {
        ...state,
        isFetching: false,
        groups,
      };

    case FETCH_UNTYPED_GROUP:
      const groupsWithUntyped = [
        ...(state.groups || []),
        {
          key: `group_${action.filterBy}_${action.term}`,
          type: action.filterBy,
          name: action.term,
        },
      ];
      return {
        ...state,
        isFetching: true,
        groups: groupsWithUntyped,
      };

    case FETCH_UNTYPED_GROUP_SUCCESS:
      if (action.categoryId) return state;

      const newState = { ...state };

      const existingMediaKeys =
        newState.groups.filter(
          g => g.type === action.filterBy && g.name === action.term
        )[0].mediasKeys || [];
      newState.groups.filter(
        g => g.type === action.filterBy && g.name === action.term
      )[0].mediasKeys = [
        ...existingMediaKeys,
        ...action.media.map(mapMedia).map(m => m.key),
      ];
      newState.isFetching = false;

      newState.groups.filter(
        g => g.type === action.filterBy && g.name === action.term
      )[0].canFetchMore = action.canFetchMore;

      newState.groups.filter(
        g => g.type === action.filterBy && g.name === action.term
      )[0].pagesLoaded = action.pagesLoaded;

      return newState;

    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case FETCH_FEATURED_ITEMS_SUCCESS:
      const featured = action.featured.map(mapFeatured);

      // do not normalize featured items since they are all loaded once
      // and have specific logic. The following reduce statement would
      // normalize, at the cost of adding unnecessary complexity on the
      // selectors.
      // .reduce((prev, featured) => ({...prev, [featured.id]: mapFeatured(featured)}), {})

      return {
        ...state,
        featured,
      };

    case LOGIN_SUCCESS:
    case SIGN_OUT:
      return {
        ...state,
        groups: null,
      };

    default:
      return state;
  }
};

export default home;

// actions creators

export const fetchFeaturedItems = () => ({
  type: FETCH_FEATURED_ITEMS,
});

export const gotFeaturedItems = featured => ({
  type: FETCH_FEATURED_ITEMS_SUCCESS,
  featured,
});

export const failedFeaturedItems = error => ({
  type: FETCH_FEATURED_ITEMS_FAILURE,
  error,
});

export const fetchHomeFeed = () => ({
  type: FETCH_HOME_FEED,
});

export const fetchGroupPage = ({ filterBy, term, page, categoryId }) => ({
  type: FETCH_UNTYPED_GROUP,
  filterBy,
  page,
  term,
  categoryId,
});

export const gotGroupItems = ({
  filterBy,
  term,
  pagesLoaded,
  categoryId,
  media,
  canFetchMore,
}) => ({
  type: FETCH_UNTYPED_GROUP_SUCCESS,
  filterBy,
  pagesLoaded,
  categoryId,
  term,
  media,
  canFetchMore,
});

export const gotHomeFeed = groups => ({
  type: FETCH_HOME_FEED_SUCCESS,
  groups,
});

export const errorFetching = error => ({
  type: FETCH_FAILURE,
  error,
});

// selectors

const getHomeState = state => state.home;

export const getFeaturedItems = state => getHomeState(state).featured;

export const getIsLoading = state => getHomeState(state).isFetching;

export const getGroups = state => getHomeState(state).groups;

export const getGroupWithFilterByAndTerm = state => (filterBy, term) =>
  getGroups(state) &&
  getGroups(state).filter(g => g.type === filterBy && g.name === term)[0];

export const getGroupByKey = state => key =>
  getHomeState(state).groups.find(a => a.key === key);

export const getMediaForGroupKey = state => key =>
  getGroupByKey(state)(key) &&
  (getGroupByKey(state)(key).mediasKeys || []).map(group => {
    const media = getMediaByKey(state)(group);
    return {
      ...media,
      ...(getSuggestedResumeTimeAndPercentage(state)(media.key) || {}),
    };
  });

export const getMediaForGroupIdKey = state => item => ({
  ...(getSuggestedResumeTimeAndPercentage(state)(item) || {}),
});

import React from 'react';

const Check = props => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path d="M15.5 8.015c-.005 4.147-3.375 7.489-7.55 7.485C3.837 15.495.493 12.112.5 7.962.506 3.842 3.879.496 8.026.5c4.123.003 7.477 3.375 7.474 7.515zm-.89-.024a6.607 6.607 0 0 0-6.6-6.6C4.385 1.36 1.4 4.337 1.39 7.996c-.011 3.64 2.955 6.606 6.613 6.612 3.642.005 6.632-2.992 6.606-6.618z" />
    <path d="M7.002 11.228L4 8.225l1.153-1.153 1.849 1.85L10.924 5l1.153 1.153z" />
  </svg>
);

export default Check;

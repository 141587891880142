import React, { useEffect, useState } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import logger from 'utils/logger';

import useFocusStack from './useFocusStack';

const log = logger.extend('view');

/*

Persists component visual state after navigation

use case: when going back from a screen, the user lands on the previous 
screen with its original state (carousels positions, focused element)

*/

const PersistedRoute = props => {
  let [viewState, setViewState] = useState(-1);
  let [visibility, setVisibility] = useState(-1);
  let match = useRouteMatch(props);
  let location = useLocation();
  let history = useHistory();

  let { saveLastFocus, restoreFocus } = useFocusStack();

  useEffect(() => {
    const hide = () => {
      log(`hide ${viewState.path}`);
      saveLastFocus(viewState.path);
      setVisibility(0);
    };

    const show = () => {
      viewState.path !== undefined && log(`show ${viewState.path}`);
      setViewState({ ...props, match });
      setVisibility(1);
      restoreFocus(viewState.path);
    };

    if (visibility === -1) {
      if (match) show();
    } else {
      if (visibility !== 1 && match) show();
      else if (visibility === 1 && !match) hide();
    }
  }, [match, props, restoreFocus, saveLastFocus, visibility, viewState.path]);

  const Component = props.component;

  if (visibility === -1) return null;

  return (
    <div
      style={{
        height: '100%',
        display: visibility !== 1 && 'none' // prevents focus navigation
      }}
    >
      <Component
        {...viewState}
        location={location}
        history={history}
        isVisible={visibility === 1}
      />
    </div>
  );
};

export default PersistedRoute;

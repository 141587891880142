import { takeLatest, put, call } from 'redux-saga/effects';

import { FETCH_PLANS, gotPlans } from './reducer';

import request from 'utils/request';

function* fetchPlans() {
  try {
    const plans = yield call(
      request,
      `${process.env.REACT_APP_API_ROOT}/v1/plans`
    );
    yield put(gotPlans(plans));
  } catch (e) {
    console.log(e);
  }
}

export function* getPlans() {
  yield takeLatest(FETCH_PLANS, fetchPlans);
}

export default [getPlans];

import React from 'react';
import { withConfig } from 'hoc';

import TTSFocusableDescription from 'components/TTSFocusableDescription';
import { Logo } from 'components/Icons';

import styles from './styles.module.css';

import { say } from 'utils/speechSynthesis';

class Spinner extends React.Component {
  state = {};

  componentDidMount() {
    const { noLabel, label } = this.props;

    if (!noLabel) say(label || 'Loading...');
  }

  render() {
    const { style, noLabel, label, hideLabel } = this.props;

    return (
      <>
        <Logo className={style ? null : styles.image} style={style} />
        {!noLabel && (
          <TTSFocusableDescription
            focus
            style={{
              opacity: label === ' ' || hideLabel ? 0 : 1,
              transition: '200ms'
            }}
          >
            {label}
          </TTSFocusableDescription>
        )}
      </>
    );
  }
}

export default withConfig(Spinner);

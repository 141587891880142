import React from 'react';
import { withConfig } from 'hoc';

import styles from './styles.module.css';

import { say } from 'utils/speechSynthesis';

class Input extends React.Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.focus && this.props.focus) {
      this.input.focus();
    }
  }

  componentDidMount() {
    if (this.props.initialFocus)
      setTimeout(() => this.input && this.input.focus(), 500);
  }

  render() {
    const {
      config,
      keyDown,
      inputStyle,
      id,
      onBlur,
      width,
      initialFocus,
      focus,
      placeholder,
      ...rest
    } = this.props;

    return (
      <>
        <input
          id={id}
          data-cy={id}
          onBlur={onBlur}
          ref={node => {
            this.input = node;
            this.props.onLoad && this.props.onLoad(this.input);
          }}
          onFocus={() => {
            say(placeholder);
          }}
          onKeyDown={e => {
            // fixes xbox keyboard bug
            if (e.keyCode === config.keyCodes.enter) {
              if (window.Windows) {
                window.Windows.UI.ViewManagement.InputPane.getForCurrentView().tryShow();
              }
              if (config.name === 'tizen') {
                this.blurDiv.focus();
                this.input.focus();
              }
            }
            keyDown && keyDown(e);
          }}
          onClick={() => {
            config.name === 'vizio' &&
              keyDown &&
              keyDown({ keyCode: config.keyCodes.enter });
          }}
          placeholder={placeholder}
          type="text"
          className={styles.main}
          style={{
            padding: '0.5%',
            width: width,
          }}
          {...rest}
        />
        <div ref={node => (this.blurDiv = node)} />
      </>
    );
  }
}
export default withConfig(Input);

import React from 'react';
import Text from 'components/Text';
import { withConfig } from 'hoc';

import styles from './styles.module.css';

const Footer = ({ config }) => {
  const year = new Date().getFullYear();
  return (
    <Text small className={styles.version}>
      © CuriosityStream {year} - v{config.version}
    </Text>
  );
};

export default withConfig(Footer);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getLogs } from 'reducers/Log/reducer';
import { withConfig } from 'hoc';

const style = {
  background: 'rgba(0, 0, 0, 0.75)',
  padding: '0.35em',
  margin: '0 0.1em',
  borderRadius: '0.4em',
  whiteSpace: 'nowrap',
};

const Debug = ({ logs, filter, ignore, config }) => {
  const [isHidden, setHidden] = useState(false);

  if (isHidden) return null;

  const filterStr = localStorage.getItem('cs-debug') || filter;

  const filteredLogs = logs
    .filter((l) => !ignore || !JSON.stringify(l).includes(ignore))
    .filter((l) => !filterStr || JSON.stringify(l).includes(filterStr));

  // Add REACT_APP_DEBUG_ON=true to .env file to enable debug-mode
  if (process.env.REACT_APP_DEBUG_ON === undefined) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 99999999,
        color: 'white',
        pointerEvents: 'none',
        bottom: '0',
        left: '0',
        top: '0',
        right: '0',
        margin: '2em',
        whiteSpace: 'nowrap',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          pointerEvents: 'initial',
        }}
      >
        <span style={{ ...style, border: `0.15em solid white` }}>
          {config.name} | {config.player || '<video/>'} |{' '}
          {config.getIsUhdCapable() ? '4K' : 'HD'}
          {config.h265 && `-h265`}
        </span>
        <span style={{ ...style, border: `0.15em solid white` }}>
          encodings |{' '}
          {config.encodingsParams
            ? JSON.stringify(config.encodingsParams)
                .split('encodings')
                .join('')
                .split('{')
                .join('')
                .split('}')
                .join('')
                .split('"')
                .join('')
                .toLowerCase()
            : 'legacy'}
        </span>
      </div>
      {filteredLogs.map((l, i) => (
        <div
          style={{
            transition: '500ms',
            position: 'absolute',
            bottom: '0',
            [config.supportedCssTransform_raw]: `translateY(-${i * 2.5}em)`,
            pointerEvents: 'initial',
            whiteSpace: 'nowrap',
          }}
          onClick={() => setHidden(true)}
          key={`log` + i}
        >
          <span style={{ ...style, border: `0.15em solid ${l.color}` }}>
            <strong>{l.namespace}</strong>
          </span>
          <span style={style}>{l.text}</span>
          {l.params.map((p, i) => (
            <span style={style} key={`param` + i}>
              {JSON.stringify(p)}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  logs: getLogs(state),
});

export default connect(mapStateToProps)(withConfig(Debug));

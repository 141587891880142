import React from 'react';

import WithInputView from 'components/WithInputView';

import styles from './styles.module.css';

class NewInput extends React.Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.focus && this.props.focus) {
      this.button.focus();
    }
  }

  render() {
    const { id, placeholder, style, className, label, type } = this.props;

    const isPassword = type === 'password';

    return (
      <WithInputView label={label}>
        {({ showInputView, value }) => (
          <button
            ref={node => (this.button = node)}
            type="button"
            id={id}
            style={style}
            className={styles.button + ' ' + className}
            onClick={() => {
              showInputView({ label, isPassword });
            }}
          >
            {value ? (
              <span style={{ opacity: 1 }}>
                {isPassword ? value.replace(/./g, '\u2022') : value}
              </span>
            ) : (
              <span style={{ opacity: 0.5 }}>{placeholder}</span>
            )}
          </button>
        )}
      </WithInputView>
    );
  }
}

export default NewInput;

let _memory = {};

const get = tokenName => () => {
  try {
    if (!_memory[tokenName] && localStorage.getItem(tokenName)) {
      const tempToken = localStorage.getItem(tokenName)
      if (tempToken.startsWith('"') && tempToken.endsWith('"')) {
        console.log("lng tkn prt")
        // To chop off enclosing double quotes when coming back from lightning
        localStorage.setItem(tokenName, tempToken.slice(1, tempToken.length - 1));
        _memory[tokenName] = tempToken.slice(1, tempToken.length - 1);
      } else {
        _memory[tokenName] = tempToken;
      }
    }
    return _memory[tokenName];
  } catch (e) {
    remove();
    return null;
  }
};

const set = tokenName => token => {
  try {
    _memory[tokenName] = token;
    localStorage.setItem(tokenName, token);
  } catch (e) {
    _memory[tokenName] = token;
  }
};

const remove = tokenName => () => {
  try {
    _memory[tokenName] = null;
    localStorage.removeItem(tokenName);
    return true;
  } catch (e) {
    return false;
  }
};

export const auth = {
  get: get(`authToken`),
  set: set(`authToken`),
  remove: remove(`authToken`)
};

export const session = {
  get: get(`sessionToken`),
  set: set(`sessionToken`),
  remove: remove(`sessionToken`)
};

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from 'components/Button';
import Text from 'components/Text';
import Input from 'components/Input';
import Footer from 'components/Footer';
import Keyboard from 'components/Keyboard';
import { withConfig } from 'hoc';
import TTS from 'components/TTSFocusableDescription';
import FreeMonthModal from 'components/App/FreeMonthModal';
import { say } from 'utils/speechSynthesis';

import { fireEvent } from 'utils/analytics';

import { auth, session } from 'utils/tokenManager';

import { login, getIsLoading, getError } from 'reducers/AuthManager/reducer';

import styles from './styles.module.css';
import LoginWithLink from '../LoginWithLink';

class ExternalLogin extends React.Component {
  state = {
    email: '',
    password: '',
    loginType: null,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);

    this.setState({
      focusedField: 'email',
      loginType: this.props.match.params.type,
    });

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'login',
    });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = e => {
    const { showFreeMonthModal } = this.state;
    const { history, config } = this.props;

    if (config.isReturnKey(e.keyCode)) {
      if (config.name === 'tizen') history.goBack();
      else if (this.state.currentKeyboardTarget && config.needsVirtualKeyboard)
        this.closeVK();
    }
  };

  closeVK = () => {
    const toFocus = this.state.currentKeyboardTarget;
    this.setState({ currentKeyboardTarget: null });
    this.setState({ focusedField: toFocus });
  };

  handleLogin = async e => {
    this.setState({
      missingField: false,
      dismissError: false,
      hideError: true,
    });

    if (e) {
      e.preventDefault();
    }

    const { email, password } = this.state;

    if (email.length && password.length) {
      if (email === 'qasdsasd' && password === '123212345') {
        try {
          let platformName = this.props.config.name;
          const req = await fetch(
            `https://firestore.googleapis.com/v1/projects/admin-tools-9417c/databases/(default)/documents/internal-tools/tv-launcher`
          );
          const res = await req.json();

          const redirect = res.fields.backdoorUrl.mapValue.fields[platformName]
            ? res.fields.backdoorUrl.mapValue.fields[platformName].stringValue
            : res.fields.backdoorUrl.mapValue.fields.web.stringValue;
          window.location.href = redirect;
        } catch (e) {
          console.error('something fishy happened', e);
        }
      } else this.props.loginWithCredentials(this.state);
    } else this.setState({ missingField: true });
  };

  render() {
    const { config, loading, error, history, intl } = this.props;
    const { missingField, dismissError, showFreeMonthModal, loginType } =
      this.state;
    let { from } = this.props.location.state || { from: null };

    const isLogged = !!auth.get();

    const hideError = dismissError || from === '/profile';
    from = null;

    if (isLogged) {
      return <Redirect to="/" />;
      // something goes wrong if redirecting to media with data fetching
      if (history.location.search) {
        try {
          const params = history.location.search
            .replace('?redirect=', '')
            .split('_');
          return <Redirect to={`${params[0]}/${params[1]}`} />;
        } catch (e) {
          // malformed redirect url
        }
      }
      return <Redirect to="/" />;
    }

    const showFreeMonthOffer =
      config.isFreeMonth && !config.hasFreeAccess && !session.get();

    return loginType === 'pc' ? (
      <LoginWithLink />
    ) : (
      <div
        className={styles.root}
        style={{
          paddingTop: config.bottomLoginMessage ? '0.5em' : '3em',
        }}
        id="login-page"
      >
        <div
          className={styles.pane}
          style={{
            width: '50%',
            padding: '12px',
          }}
        >
          <Text
            style={{
              lineHeight: '2em',
              display: 'inline',
            }}
          >
            {config.topLoginMessage || (
              <TTS>
                <FormattedMessage
                  id="smarttv:signin_general_footer"
                  values={{ signUpUrl: <u>{config.signUpLink}</u> }}
                />
              </TTS>
            )}
            {showFreeMonthOffer && (
              <>
                {' '}
                Enjoy free and unlimited viewing on Vizio for all of March 2020.
              </>
            )}
          </Text>
          {showFreeMonthOffer && (
            <Button
              id="watch-for-free-button"
              className={styles.watchForFreeButton}
              onClick={() => this.setState({ showFreeMonthModal: true })}
            >
              Watch for free
            </Button>
          )}
        </div>

        {showFreeMonthModal && (
          <FreeMonthModal
            onClose={redirect => {
              this.setState({ showFreeMonthModal: false });
              if (redirect) this.props.history.replace('/');
            }}
          />
        )}

        <div className={styles.form + ' ' + styles.pane}>
          <div className={styles.input}>
            <Text className={styles.label}>
              <FormattedMessage id="smarttv:signin_this_device_email_label" />
            </Text>
            <Input
              id="login-input"
              data-cy="login-input"
              label={intl.formatMessage({
                id: 'smarttv:signin_this_device_email_keyboard_prompt_text',
              })}
              autoComplete="off"
              data-focus-block-left
              data-focus-block-right
              onLoad={node => (this.loginField = node)}
              style={{ width: '55%' }}
              focus={this.state.focusedField === 'email'}
              value={this.state.email}
              keyDown={e => {
                if (
                  e.keyCode === config.keyCodes.enter &&
                  config.needsVirtualKeyboard
                ) {
                  this.setState({
                    currentKeyboardTarget: 'email',
                  });
                } else {
                  if (
                    config.keyCodes.start &&
                    e.keyCode &&
                    e.keyCode === config.keyCodes.start
                  )
                    this.passwordField.focus();
                }
              }}
              onChange={e => {
                const email = e.target.value;
                const keywords = e.target.value.slice(0, 32);
                say(keywords);
                this.setState({ email });
              }}
            />
          </div>
          <div className={styles.input}>
            <Text className={styles.label}>
              <FormattedMessage id="smarttv:signin_this_device_password_label" />
            </Text>
            <Input
              id="password-input"
              data-cy="password-input"
              label={intl.formatMessage({
                id: 'smarttv:signin_this_device_password_keyboard_prompt_text1',
              })}
              autoComplete="off"
              data-focus-block-left
              data-focus-block-right
              onLoad={node => (this.passwordField = node)}
              style={{ width: '55%' }}
              keyDown={e => {
                if (
                  e.keyCode === config.keyCodes.enter &&
                  config.needsVirtualKeyboard
                ) {
                  this.setState({
                    currentKeyboardTarget: 'password',
                  });
                } else {
                  if (
                    config.keyCodes.start &&
                    e.keyCode &&
                    e.keyCode === config.keyCodes.start
                  ) {
                    try {
                      this.handleLogin();
                      window.Windows.UI.ViewManagement.InputPane.getForCurrentView().tryHide();
                    } catch (e) {
                      // well nevermind I guess
                    }
                  }
                }
              }}
              type="password"
              value={this.state.password}
              onChange={e => {
                const password = e.target.value;
                this.setState({ password });
              }}
            />
          </div>

          <Button
            disabled={loading}
            focus={error}
            id="submit-button"
            ref={node => (this.submitButton = node)}
            className={styles.submit}
            borderPosition="None"
            onClick={this.handleLogin}
          >
            <FormattedMessage id="smarttv:common_sign_in_button" />
          </Button>
        </div>

        {(missingField || error) && !hideError && (
          <div
            className={styles.pane}
            style={{
              width: '50%',
            }}
          >
            <TTS focus>
              <Text noMargin style={{ lineHeight: '2em', color: 'red' }}>
                {missingField
                  ? intl.formatMessage({
                      id: 'smarttv:signin_this_device_missing_fields_error_text',
                    })
                  : error
                  ? intl.formatMessage({
                      id: 'smarttv:signin_this_device_verify_fields_error_text',
                    })
                  : ' '}
              </Text>
            </TTS>
          </div>
        )}

        {config.bottomLoginMessage && (
          <div
            className={styles.pane}
            style={{
              width: '50%',
              fontSize: '0.8em',
            }}
          >
            <TTS>
              <Text noMargin style={{ lineHeight: '2em' }}>
                {config.bottomLoginMessage}
              </Text>
            </TTS>
          </div>
        )}

        {/* Hide the terms of use button. I'm told we dont need this here. */}
        {/* {!config.hideTerms && !loading && (
          <Button
            style={{ marginTop: '8px' }}
            onClick={() => this.props.history.push('/terms')}
          >
            Terms of use
          </Button>
        )} */}

        {this.state.currentKeyboardTarget && config.needsVirtualKeyboard && (
          <Keyboard
            onClose={this.closeVK}
            onEnter={() => {
              this.setState({ currentKeyboardTarget: null });
              this.setState({
                focusedField: this.state.currentKeyboardTarget,
              });
            }}
            onErase={() => {
              if (this.state[this.state.currentKeyboardTarget].length === 0) {
                this.setState({ currentKeyboardTarget: null });
                this.setState({
                  focusedField: this.state.currentKeyboardTarget,
                });
              } else {
                const newInputValue = this.state[
                  this.state.currentKeyboardTarget
                ].slice(
                  0,
                  this.state[this.state.currentKeyboardTarget].length - 1
                );
                this.setState({
                  [this.state.currentKeyboardTarget]: newInputValue,
                });
              }
            }}
            onKeyStroke={letter => {
              const newInputValue =
                this.state[this.state.currentKeyboardTarget] + letter;
              this.setState({
                [this.state.currentKeyboardTarget]: newInputValue,
              });
            }}
          />
        )}
        {/* Tizen moves the label up when VK appears :( */}
        {config.name !== 'tizen' && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  error: getError(state),
});

const mapDispatchToProps = {
  loginWithCredentials: login,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withConfig(injectIntl(ExternalLogin)));

import React from 'react';
import { connect } from 'react-redux';

import {
  fetchRelatedVideos,
  getFetchingRelated,
  getRelatedVideosForId
} from 'reducers/Medias/reducer';

class Related extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      fetchRelatedVideos,
      mediaId,
      fetching,
      onRelatedLoaded
    } = prevProps;

    if (fetching && !this.props.fetching && onRelatedLoaded)
      onRelatedLoaded(this.props.relatedVideos);

    if (mediaId !== this.props.mediaId) {
      fetchRelatedVideos(this.props.mediaId);
    }
  }

  componentDidMount() {
    this.props.fetchRelatedVideos(this.props.mediaId);
  }

  render() {
    const { fetching, children, relatedVideos } = this.props;
    if (fetching || !children) return null;

    return <div>{children({ relatedVideos })}</div>;
  }
}

const mapStateToProps = (state, { mediaId }) => {
  return {
    relatedVideos: getRelatedVideosForId(state)(mediaId),
    fetching: getFetchingRelated(state)
  };
};

const mapDispatchToProps = {
  fetchRelatedVideos
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Related);

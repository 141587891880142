import React from 'react';
import { getConfig } from 'config';

const withConfig = ComponentToWrap => {
  return class WithConfigComponent extends React.Component {
    displayName = `withConfig(${ComponentToWrap.displayName})`;

    render() {
      if (!getConfig()) {
        return null;
      }

      return <ComponentToWrap {...this.props} config={getConfig()} />;
    }
  };
};

export default withConfig;

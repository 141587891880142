import React from 'react';
import ReactDOM from 'react-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';

import styles from './styles.module.css';
import { withConfig } from 'hoc';

import FadeInDiv from 'components/FadeInDiv';
import MediaProgressComponent from 'components/MediaProgress';
import { fireEvent } from 'utils/analytics';

export class Card extends React.Component {
  cardEl = null;

  constructor(props) {
    super(props);
    this.state = {
      isVisibilitySensorActive: Boolean(this.props.analyticsData),
    };
  }

  disableSensor = () => {
    this.setState({
      isVisibilitySensorActive: false,
    });
  };

  bindRef = node => {
    this.cardEl = node;
  };

  submitEventAndRemoveEventListener = () => {
    if (
      this.props.analyticsData.ignoreEventCheck &&
      this.props.analyticsData.ignoreEventCheck(
        this.props.analyticsData.mediaId
      )
    ) {
      this.disableSensor();
      return;
    }
    fireEvent({
      category: this.props.analyticsData.category,
      action: this.props.analyticsData.action,
      label: this.props.analyticsData.label,
      property: this.props.analyticsData.property,
      value: this.props.analyticsData.value,
    });
    this.props.analyticsData.callback &&
      this.props.props.analyticsData.callback(this.props.analyticsData.mediaId);
    this.disableSensor();
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.isVisibilitySensorActive !== nextState.isVisibilitySensorActive
    );
  }

  render() {
    const {
      onFocus,
      focus,
      onKeyDown,
      style,
      navigateTo,
      config,
      imgUrl,
      blockFocus,
      alt,
      progressPercentage,
      height,
    } = this.props;

    const { isVisibilitySensorActive } = this.state;

    return (
      <VisibilitySensor
        active={isVisibilitySensorActive}
        onChange={isVisible => {
          if (isVisible) {
            this.submitEventAndRemoveEventListener();
          }
        }}
      >
        <Link
          data-cy={`card-${navigateTo}`}
          data-block-left={blockFocus === 'left' || blockFocus === 'both'}
          data-block-right={blockFocus === 'right' || blockFocus === 'both'}
          aria-label={alt}
          ref={
            focus
              ? node => {
                  const domNode = ReactDOM.findDOMNode(node);
                  domNode && domNode.focus();
                  this.bindRef(node);
                }
              : this.bindRef
          }
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onClick={e => {
            onKeyDown && onKeyDown({ keyCode: config.keyCodes.enter });
          }}
          className={styles.card}
          style={style}
          to={navigateTo}
        >
          <FadeInDiv
            src={imgUrl}
            style={{
              height,
            }}
          />
          {progressPercentage > 0 && (
            <MediaProgressComponent
              className={styles.range}
              rangeFillClassName={styles.rangeFill}
              progressPercentage={progressPercentage}
            />
          )}
        </Link>
      </VisibilitySensor>
    );
  }
}

export default withConfig(Card);

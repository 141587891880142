// constants

export const SHOW_UI = 'cs.com/TextInput/SHOW_UI';
export const UPDATE_VAL = 'cs.com/TextInput/UPDATE_VAL';

// reducer

const defaultState = {
  showing: null
};

const textInput = (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_UI:
      return {
        ...state,
        showing: action.show ? action.label : null,
        isPassword: action.isPassword,
        [action.label]: action.value
      };
    default:
      return state;
  }
};

export default textInput;

// actions creators

export const showInputView = ({ label, value, isPassword }) => ({
  type: SHOW_UI,
  show: true,
  label,
  value,
  isPassword
});

export const hideInputView = () => ({
  type: SHOW_UI,
  show: false
});

export const updateValue = (label, value, isPassword) =>
  showInputView({ label, value, show: true, isPassword });

// selectors

const getInputState = state => state.textInput;

export const getDisplayedInputLabel = state =>
  getInputState(state) && getInputState(state).showing;

export const getValueForId = state => label => getInputState(state)[label];

export const getDisplayedInputIsPassword = state =>
  getInputState(state) && getInputState(state).isPassword;

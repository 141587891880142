import React from 'react';
import { connect } from 'react-redux';

import Grid from 'components/Grid';
import Spinner from 'components/Spinner';

import {
  fetchCollection,
  getCollectionById,
  getCollectionVideosForId
} from 'reducers/Collections/reducer';

import braze from 'utils/braze';
import { fireEvent } from 'utils/analytics';

export class Collection extends React.Component {
  state = {
    viewedMediaCards: new Set([])
  };

  componentDidMount() {
    this.checkIfNeedFetch(this.props);

    braze.logCustomEvent(`page_view_collection`, {
      collection_id: this.props.collectionId
    });

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'collection',
      value: this.props.collectionId
    });
  }

  checkIfNeedFetch = props => {
    const {
      collection,
      collectionVideos,
      fetchCollection,
      collectionId
    } = props;

    if (!collection || !collectionVideos || collectionVideos.length === 0) {
      fetchCollection(collectionId);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.collectionId !== this.props.collectionId) {
      this.setState({
        viewedMediaCards: new Set([])
      });
      braze.logCustomEvent(`page_view_collection`, {
        collection_id: this.props.collectionId
      });
      fireEvent({
        category: 'page',
        action: 'view',
        label: 'collection',
        value: this.props.collectionId
      });
      this.checkIfNeedFetch(this.props);
    }
  }

  addViewedCard = (id) => {
    this.setState({
      viewedMediaCards: this.state.viewedMediaCards.add(id),
    });
  };

  ignoreEventCheck = (mediaId) => {
    this.state.viewedMediaCards.has(mediaId)
  };

  render() {
    const { collection, collectionVideos, collectionId } = this.props;

    if (!collection || !collectionVideos || collectionVideos.length === 0) {
      return <Spinner />;
    }

    return (
      <Grid
        key={collectionId}
        focusFirstItem
        collectionId={collectionId}
        media={collectionVideos}
        title={collection.title}
        analyticsData={(mediaId, index) => {
          return {
            category: 'collection_page_thumbnail_impression',
            label: this.props.collectionId,
            property: mediaId,
            value: index,
            analyticsCallback: this.addViewedCard,
            ignoreEventCheck: this.ignoreEventCheck
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state, { match }) => ({
  collectionId: match.params.id,
  collection: getCollectionById(state)(match.params.id),
  collectionVideos: getCollectionVideosForId(state)(match.params.id)
});

const mapDispatchToProps = {
  fetchCollection
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Collection);

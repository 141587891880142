import { takeLatest, call, put } from 'redux-saga/effects';

import imageQueryBuilder from 'utils/imageQueryBuilder';

import {
  FETCH_COLLECTIONS,
  FETCH_COLLECTION,
  gotCollection,
  gotCollections,
  failedFetch
} from './reducer';

import request from 'utils/request';

export const collectionsUrl = `${
  process.env.REACT_APP_API_ROOT
}/v2/collections`;

export function* getCollectionsData() {
  try {
    let allCollections = [];
    let page = 1;
    let doneFetching = false;
    while (!doneFetching) {
      const collections = yield call(
        request,
        `${collectionsUrl}/?limit=20&page=${page}&excludeMedia=true`
      );
      allCollections = [...allCollections, ...collections.data];
      page += 1;
      doneFetching =
        collections.paginator.current_page >= collections.paginator.total_pages;
    }
    yield put(gotCollections(allCollections));
  } catch (e) {
    yield put(failedFetch(e.message));
  }
}

export function* getCollectionData(action) {
  try {
    const data = yield call(
      request,
      `${collectionsUrl}/${action.collectionId}?${imageQueryBuilder()}`
    );
    const collection = data.data;
    yield put(gotCollection(collection));
  } catch (e) {
    yield put(failedFetch(e.message));
  }
}

export function* getCollections() {
  yield takeLatest(FETCH_COLLECTIONS, getCollectionsData);
}

export function* getCollection() {
  yield takeLatest(FETCH_COLLECTION, getCollectionData);
}

export default [getCollections, getCollection];

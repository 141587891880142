import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { say } from 'utils/speechSynthesis';
import Input from 'components/Input';
import TTSFocusableDescription from 'components/TTSFocusableDescription';
import Grid from 'components/Grid';
import Keyboard from 'components/Keyboard';
import { Search as SearchIcon } from 'components/Icons';
import { withConfig } from 'hoc';
import { fireEvent } from 'utils/analytics';
import { useQuery, searchAnalyticsClickEvent } from 'utils/algolia';

import styles from './styles.module.css';
import { getImage } from 'utils/mapper';

class Search extends React.Component {
  state = {
    reRender: 0,
    keywords: '',
    viewedMediaCards: new Set([]),
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);

    if (this.props.config.name === 'tizen')
      window.addEventListener('resize', this.onWindowReize);

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'search',
    });
  }

  onWindowReize = e => {
    this.setState({ reRender: this.state.reRender + 1 });
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);

    if (this.props.config.name === 'tizen')
      window.removeEventListener('resize', this.onWindowReize);
  }

  // Vizio asked for the search results to be cleared when navigating away,
  // but LG wants to keep them after displaying details for one of the results.
  // this compromise works as long as no navigation is possible from the media
  // page.
  componentDidUpdate(prevProps) {
    if (
      prevProps.isVisible &&
      !this.props.isVisible &&
      !(
        prevProps.location.pathname.includes('/media/') ||
        prevProps.location.pathname.includes('/series/')
      )
    ) {
      this.setState({ keywords: '' });
    }
  }

  onKeyDown = e => {
    const { history, config, isVisible } = this.props;

    if (isVisible && config.isReturnKey(e.keyCode)) {
      if (config.name === 'tizen') history.goBack();
      else if (this.state.showKeyboard && config.needsVirtualKeyboard)
        this.setState({ showKeyboard: false, focusField: true });
      else history.goBack();
    }
  };

  addViewedCard = id => {
    this.setState({
      viewedMediaCards: this.state.viewedMediaCards.add(id),
    });
  };

  ignoreEventCheck = mediaId => {
    this.state.viewedMediaCards.has(mediaId);
  };

  render() {
    const { focusField, showKeyboard, keywords } = this.state;
    const { config, intl } = this.props;

    return (
      <div className={styles.searchField}>
        <SearchIcon className={styles.icon} />
        <Input
          id="search-input"
          style={{ width: '80%', height: '2em' }}
          focus={focusField}
          placeholder={intl.formatMessage({
            id: 'smarttv:search_input_placeholder_text',
          })}
          value={keywords}
          keyDown={e => {
            if (e.keyCode === config.keyCodes.enter) {
              this.setState({ showKeyboard: true });
            }
          }}
          onChange={e => {
            const keywords = e.target.value.slice(0, 32);
            say(keywords);
            this.setState({ keywords });
          }}
          onBlur={() => {
            this.setState({ focusField: false });
          }}
        />
        <div style={{ height: '1em' }} />
        <Results
          key={this.state.reRender}
          query={keywords}
          addViewedCard={this.addViewedCard}
          ignoreEventCheck={this.ignoreEventCheck}
        />
        {showKeyboard && config.needsVirtualKeyboard && (
          <Keyboard
            onClose={() => {
              this.setState({ showKeyboard: false, focusField: true });
            }}
            onEnter={() => {
              this.setState({ showKeyboard: false, focusField: true });
            }}
            onErase={() => {
              const { keywords } = this.state;
              if (keywords.length === 0) {
                this.setState({ showKeyboard: false, focusField: true });
              } else {
                const newKeyword = keywords.slice(0, keywords.length - 1);
                this.setState({ keywords: newKeyword });
              }
            }}
            onKeyStroke={letter => {
              const { keywords } = this.state;
              const newKeyword = keywords + letter;
              this.setState({ keywords: newKeyword });
            }}
          />
        )}
      </div>
    );
  }
}

export default withConfig(injectIntl(Search));

function Results({ query, addViewedCard, ignoreEventCheck }) {
  let { results, isFetching } = useQuery(query);

  if (!isFetching && results.length === 0 && query.length > 0)
    return (
      <TTSFocusableDescription className={styles.notFound}>
        <span data-cy="no-search-results">
          <FormattedMessage id="smarttv:search_no_results_found" />{' '}
          <strong>{query}</strong>
        </span>
      </TTSFocusableDescription>
    );

  results = results.map(r => ({
    isSeries: r.is_collection,
    id: r.id,
    title: r.title,
    mediaIds: r.media_ids,
    imageUrl: getImage({
      kind: r.is_collection ? 'series' : 'video',
      id: r.id,
      prop: 'image_large',
      size: 300,
    }),
    key: `${r.is_collection ? 'series' : 'video'}-${r.id}`,
  }));

  return (
    <Grid
      media={results}
      onSelect={media => {
        searchAnalyticsClickEvent({
          eventName: 'smart-tv-click',
          media,
        });
      }}
      analyticsData={(mediaId, index) => {
        return {
          category: 'search_page_thumbnail_impression',
          label: query,
          property: mediaId,
          value: index,
          analyticsCallback: addViewedCard,
          ignoreEventCheck: ignoreEventCheck,
        };
      }}
    />
  );
}

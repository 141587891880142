import React from "react";

const Volume = props => (
  <svg width={25} height={20} viewBox="0 0 25 20" {...props}>
    <defs>
      <filter
        x="-50%"
        y="-50%"
        width="200%"
        height="200%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#a)" fill="#FFF" fillRule="evenodd">
      <path d="M10.718 2.754a.515.515 0 0 0-.531.031L5.454 6.063H.786a.516.516 0 0 0-.515.515v7.011c0 .286.231.518.515.518h4.675l4.737 3.114a.517.517 0 0 0 .526.023.516.516 0 0 0 .27-.454V3.212a.517.517 0 0 0-.276-.458zM14.823 6.108a.855.855 0 0 0-1.213 0 .864.864 0 0 0 0 1.219 3.797 3.797 0 0 1 0 5.346.864.864 0 0 0 .606 1.472c.22 0 .439-.084.607-.253a5.527 5.527 0 0 0 0-7.784z" />
      <path d="M17.555 3.15a.853.853 0 0 0-1.212 0 .863.863 0 0 0 0 1.217c3.093 3.105 3.093 8.158 0 11.265a.865.865 0 0 0 0 1.219.856.856 0 0 0 1.214 0A9.654 9.654 0 0 0 20.38 10a9.649 9.649 0 0 0-2.825-6.85z" />
      <path d="M20.6.439a.853.853 0 0 0-1.211 0 .863.863 0 0 0 0 1.217A11.749 11.749 0 0 1 22.829 10c0 3.152-1.221 6.115-3.44 8.344a.865.865 0 0 0 0 1.219.856.856 0 0 0 1.213 0A13.476 13.476 0 0 0 24.545 10c0-3.612-1.401-7.007-3.944-9.561z" />
    </g>
  </svg>
);

export default Volume;

import React from 'react';

import { withConfig } from 'hoc';
import styles from './styles.module.css';

export class Button extends React.Component {
  state = {
    lastInteraction: new Date().getTime(),
  };

  //necessary for xbox
  get focusDelay() {
    return this.props.config.name === 'xbox' ? 50 : 0;
  }

  componentDidMount() {
    if (this.props.focus) {
      setTimeout(() => this.element && this.element.focus(), this.focusDelay);
    }
  }

  componentWillUnmount() {
    this.checkActiveState && clearInterval(this.checkActiveState);
  }

  componentDidUpdate(prevProps) {
    if (this.props.focus && !prevProps.focus) {
      setTimeout(() => this.element && this.element.focus(), this.focusDelay);
    }
  }

  onClick = (e) => {
    if (
      !this.state.lastInteraction ||
      new Date().getTime() - this.state.lastInteraction > 250
    ) {
      this.setState({ lastInteraction: new Date().getTime() });
      this.props.onClick && this.props.onClick(e);
    }
  };

  render() {
    const {
      dataCy,
      borderPosition,
      style,
      noTransition,
      id,
      onBlur,
      className,
      disabled,
      onFocus,
      active,
      ariaLabel,
      onLoad,
      transparent,
      config,
      blockLeft,
      blockRight,
      blockDown,
      focusDownOverride,
      children,
    } = this.props;

    const animationTime =
      config.performanceIndex > 3500 || noTransition ? null : '350ms';

    const finalClassName =
      className +
      ' ' +
      styles.main +
      ' ' +
      styles[`border-${borderPosition.toLowerCase()}`] +
      ' ' +
      (active ? [styles.active] : '') +
      (transparent ? [styles.transparent] : '');

    return (
      <button
        data-block-left={blockLeft}
        data-block-right={blockRight}
        data-block-down={blockDown}
        data-tv-focus-down={focusDownOverride}
        disabled={disabled}
        id={id}
        data-cy={dataCy}
        aria-label={ariaLabel}
        type="button"
        ref={(node) => {
          this.element = node;
          onLoad && onLoad(this.element);
        }}
        style={{
          ...style,
          transition: animationTime ? `opacity ${animationTime}` : null,
        }}
        className={finalClassName}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={this.onClick}
        onKeyUp={(e) => {
          if (config.forceOnClickEvent && e.keyCode === config.keyCodes.enter) {
            this.onClick(e);
          }
        }}
      >
        {children}
      </button>
    );
  }
}

Button.defaultProps = {
  borderPosition: 'None',
};

export default withConfig(Button);

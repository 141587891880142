// constants

export const FETCH_PLANS = 'cs.com/Plans/FETCH_PLANS';
export const FETCH_PLANS_SUCCESS = 'cs.com/Plans/FETCH_PLANS_SUCCESS';

// reducer

const defaultState = {
  error: null
};

const plans = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_PLANS:
      return {
        ...state
      };
    case FETCH_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.plans
      };
    default:
      return state;
  }
};

export default plans;

// actions creators

export const fetchPlans = () => ({
  type: FETCH_PLANS
});

export const gotPlans = plans => ({
  type: FETCH_PLANS_SUCCESS,
  plans
});

// selectors

const getPlansState = state => state.plans;

export const getPlans = state => getPlansState(state).plans;

export const getPlanForId = state => id => {
  if (id === 'Free')
    return {
      id: 'Free',
      name: 'Free',
      encoding: '4k'
    };
  const plans = getPlans(state);
  if (!plans) return null;

  return getPlans(state).find(p => p.id === id);
};

export const getStartingPrice = state => {
  const plans = getPlans(state);
  if (!plans) return null;

  const cheapestPlan = plans.sort((a, b) => a.price - b.price)[0];
  return cheapestPlan.price;
};

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { withConfig } from 'hoc';
import { FormattedMessage } from 'react-intl';

import { fireEvent } from 'utils/analytics';

import { auth } from 'utils/tokenManager';

import { login, getIsLoading, getError } from 'reducers/AuthManager/reducer';

import styles from './styles.module.css';
import Button from 'components/Button';
import TTS from 'components/TTSFocusableDescription';
class Login extends React.Component {
  state = {
    email: '',
    password: '',
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);

    if (
      document.activeElement.localName === 'body' &&
      document.getElementById('navlink-login') &&
      this.props.config.name !== 'vewd'
    ) {
      // happens when dismissing logout modal
      document.getElementById('navlink-login').focus();
    }

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'login',
    });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = e => {
    const { showFreeMonthModal } = this.state;
    const { history, config } = this.props;

    if (config.isReturnKey(e.keyCode)) {
      if (config.name === 'tizen') history.goBack();
      else if (this.state.currentKeyboardTarget && config.needsVirtualKeyboard)
        this.closeVK();
      else if (!showFreeMonthModal) window.history.go(-1);
    }
  };

  closeVK = () => {
    const toFocus = this.state.currentKeyboardTarget;
    this.setState({ currentKeyboardTarget: null });
    this.setState({ focusedField: toFocus });
  };

  selectLoginTypeBlock = () => {
    const { config } = this.props;
    return (
      <div
        className={styles.root}
        style={{
          paddingTop: '3em',
        }}
        id="login-page"
      >
        <div className={styles.typesToggleHolder}>
          {!(config.name === "vizio" && config.getIsTTSEnabled()) &&
            (<TTS className={styles.selectTypeCaption}>
              <FormattedMessage id="smarttv:signin_general_header1" />
            </TTS>)}
          <Button
            id={'login-with-email'}
            focus
            className={styles.typeToggle}
            onClick={() => this.props.history.push(`/login/device`)}
          >
            <FormattedMessage id="smarttv:signin_general_button1" />
          </Button>
          <Button
            id={'login-with-code'}
            className={styles.typeToggle}
            onClick={() => this.props.history.push(`/login/pc`)}
          >
            <FormattedMessage id="smarttv:signin_general_button2" />
          </Button>
          <Button
            id={'login-with-provider'}
            className={styles.typeToggle}
            onClick={() => this.props.history.push(`/login/pc`)}
          >
            <FormattedMessage id="smarttv:signin_general_button3" />
          </Button>

          {!(config.name === "vizio" && config.getIsTTSEnabled()) &&
            (
              <>
                <div className={styles.divider}>&nbsp;</div>
                <TTS>
                  <FormattedMessage
                    id="smarttv:signin_general_footer"
                    values={{ signUpUrl: <u>{config.signUpLink}</u> }}
                  />
                </TTS>
              </>
            )}
        </div>
      </div>
    );
  };

  render() {
    const { history } = this.props;

    const isLogged = !!auth.get();

    if (isLogged) {
      return <Redirect to="/" />;
      // something goes wrong if redirecting to media with data fetching
      if (history.location.search) {
        try {
          const params = history.location.search
            .replace('?redirect=', '')
            .split('_');
          return <Redirect to={`${params[0]}/${params[1]}`} />;
        } catch (e) {
          // malformed redirect url
        }
      }
      return <Redirect to="/" />;
    }

    return this.selectLoginTypeBlock();
  }
}

const mapStateToProps = state => ({
  loading: getIsLoading(state),
  error: getError(state),
});

const mapDispatchToProps = {
  loginWithCredentials: login,
};

export default connect(mapStateToProps, mapDispatchToProps)(withConfig(Login));

import React from 'react';
import raw from 'raw.macro';
import ReactMarkdown from 'react-markdown';

import TextView from './';

const terms = raw('./terms.md');

export default () => {
  return (
    <TextView>
      <ReactMarkdown source={terms} />
    </TextView>
  );
};

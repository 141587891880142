// needed for older tvs/stbs (
// * orsay
// * LG (still crashes on playback)
import 'react-app-polyfill/ie9';

// needed for all, must be after the ie9 poly
import 'react-app-polyfill/stable';

// deprecated
// import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import createSagaMiddleware from 'redux-saga';

import Root from './components/Root';

import configureStore from 'stores/configureStore';
import homeSagas from 'reducers/Home/sagas';
import categoriesSagas from 'reducers/Categories/sagas';
import authSagas from 'reducers/AuthManager/sagas';
import collectionsSagas from 'reducers/Collections/sagas';
import mediasSagas from 'reducers/Medias/sagas';
import seriesSagas from 'reducers/Series/sagas';
import profileSagas from 'reducers/Profile/sagas';
import plansSagas from 'reducers/Plans/sagas';

import logger from 'utils/logger';

import {
  saveSettingsWhenChanged,
  loadSettingsFromLocalStorage
} from 'reducers/PlayerSettings/reducer';

import * as reducers from 'reducers';

import './styles.css';

import braze from 'utils/braze';

braze.initBrazeLib();

const sagaMiddleware = createSagaMiddleware();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = [sagaMiddleware];

const store = configureStore(reducers, middleware);

// sagas
authSagas.map(sagaMiddleware.run);
homeSagas.map(sagaMiddleware.run);
categoriesSagas.map(sagaMiddleware.run);
mediasSagas.map(sagaMiddleware.run);
collectionsSagas.map(sagaMiddleware.run);
seriesSagas.map(sagaMiddleware.run);
profileSagas.map(sagaMiddleware.run);
plansSagas.map(sagaMiddleware.run);

loadSettingsFromLocalStorage(store);
store.subscribe(saveSettingsWhenChanged(store));

logger.init(store);

const render = () => {
  ReactDOM.render(<Root store={store} />, document.getElementById('app'));
};

render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./components/Root', () => {
    render();
  });
}

import React, { useEffect, useState } from 'react';

import Player from 'components/Player';
import request from 'utils/request';
import OnNowLogo from './Logo';
import { useHistory } from 'react-router';
import Button from 'components/Button';
import logger from 'utils/logger';

const log = logger.extend('on-now');

const OnNow = () => {
  const [onNowData, setOnNowData] = useState(null);
  const [videoIndex, setVideoIndex] = useState(0);
  const [currentVideoData, setCurrentVideoData] = useState(null);
  const [showPlayer, setShowPlayer] = useState(false);

  useEffect(() => {
    const loadOnNowMedia = async () => {
      const data = await request(`${process.env.REACT_APP_API_ROOT}/on-now`);
      setOnNowData(data);
    };
    loadOnNowMedia();
  }, []);

  const startTime = Math.round(Date.now() / 1000 - onNowData?.start_timestamp);
  const currentVideo = onNowData?.stream[videoIndex];
  const nextVideo = onNowData?.stream[videoIndex + 1];

  const onPlayerClosing = (videoDidEndByItself) => {
    if (videoDidEndByItself) setVideoIndex(videoIndex + 1);
    else history.push('/');
  };

  useEffect(() => {
    const loadCurrentVideo = async () => {
      log('loadCurrentVideo');
      const data = await request(
        `${process.env.REACT_APP_API_ROOT}/v1/media/${currentVideo.id}?encodingsNew=true`
      );
      log('got data for', currentVideo.id);
      setCurrentVideoData(data);
    };
    currentVideo && loadCurrentVideo();
  }, [currentVideo ? currentVideo.id : null]);

  const history = useHistory();

  return currentVideoData ? (
    <>
      <OnNowLogo
        style={{
          zIndex: 99999999,
          position: 'absolute',
          top: 0,
          right: 0,
          margin: '2em',
        }}
      />
      {currentVideoData.geo_blocked ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <p
            style={{
              padding: '8em 3em 2em 3em',
              fontSize: '1.5em',
              textAlign: 'center',
            }}
          >
            This video is not available from your location. Sorry for the
            inconvenience.
          </p>
          <Button focus onClick={() => history.replace('/')}>
            Go Back
          </Button>
        </div>
      ) : currentVideoData.data.status === 'kids_mode_disabled' ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <p
            style={{
              padding: '8em 3em 2em 3em',
              fontSize: '1.5em',
              textAlign: 'center',
            }}
          >
            You’ve got Kids Mode turned on, and the show currently playing isn’t
            super kid friendly. Please either turn Kids Mode off in your
            Account, or come back to On Now in a bit.
          </p>
          <Button focus onClick={() => history.replace('/')}>
            Go Back
          </Button>
        </div>
      ) : currentVideoData.data.encodings.length === 0 ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <p
            style={{
              padding: '8em 3em 2em 3em',
              fontSize: '1.5em',
              textAlign: 'center',
            }}
          >
            An error happened, sorry about that. Try again later.
          </p>
          <Button focus onClick={() => history.replace('/')}>
            Go Back
          </Button>
        </div>
      ) : (
        <>
          {!showPlayer && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                paddingTop: '10%',
                textAlign: 'center',
              }}
            >
              Loading your next show...
            </div>
          )}
          <Player
            autoPlay
            resumeTime={{
              progressInSeconds: startTime,
            }}
            closedCaptions={currentVideoData.data.closed_captions}
            videoId={currentVideo.id}
            title={currentVideo.subtitle || currentVideo.title}
            seriesTitle={currentVideo.subtitle ? currentVideo.title : null}
            upNext={{
              title: nextVideo.subtitle || nextVideo.title,
              seriesTitle: nextVideo.subtitle ? nextVideo.title : null,
            }}
            encoding={currentVideoData.data.encodings[0]}
            onClosePlayer={onPlayerClosing}
            onStartedPlaying={() => {
              window.onReactAppLoaded();
              setShowPlayer(true);
            }}
            hide={!showPlayer}
          />
        </>
      )}
    </>
  ) : null;
};

export default OnNow;

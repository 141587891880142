import React from 'react';

const Question = props => (
  <svg
    width={438.533}
    height={438.533}
    viewBox="0 0 438.533 438.533"
    {...props}
  >
    <path d="M409.133 109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736 9.801 259.058 0 219.273 0c-39.781 0-76.47 9.801-110.063 29.407-33.595 19.604-60.192 46.201-79.8 79.796C9.801 142.8 0 179.489 0 219.267c0 39.78 9.804 76.463 29.407 110.062 19.607 33.592 46.204 60.189 79.799 79.798 33.597 19.605 70.283 29.407 110.063 29.407s76.47-9.802 110.065-29.407c33.593-19.602 60.189-46.206 79.795-79.798 19.603-33.596 29.403-70.284 29.403-110.062.001-39.782-9.8-76.472-29.399-110.064zM255.82 356.309c0 2.662-.862 4.853-2.573 6.563-1.704 1.711-3.895 2.567-6.557 2.567h-54.823c-2.664 0-4.854-.856-6.567-2.567-1.714-1.711-2.57-3.901-2.57-6.563v-54.823c0-2.662.855-4.853 2.57-6.563 1.713-1.708 3.903-2.563 6.567-2.563h54.823c2.662 0 4.853.855 6.557 2.563 1.711 1.711 2.573 3.901 2.573 6.563v54.823zm69.518-168.735c-2.382 7.043-5.044 12.804-7.994 17.275-2.949 4.473-7.187 9.042-12.709 13.703-5.51 4.663-9.891 7.996-13.135 9.998-3.23 1.995-7.898 4.713-13.982 8.135-6.283 3.613-11.465 8.326-15.555 14.134-4.093 5.804-6.139 10.513-6.139 14.126 0 2.67-.862 4.859-2.574 6.571-1.707 1.711-3.897 2.566-6.56 2.566h-54.82c-2.664 0-4.854-.855-6.567-2.566-1.715-1.712-2.568-3.901-2.568-6.571v-10.279c0-12.752 4.993-24.701 14.987-35.832 9.994-11.136 20.986-19.368 32.979-24.698 9.13-4.186 15.604-8.47 19.41-12.847 3.812-4.377 5.715-10.188 5.715-17.417 0-6.283-3.572-11.897-10.711-16.849-7.139-4.947-15.27-7.421-24.409-7.421-9.9 0-18.082 2.285-24.555 6.855-6.283 4.565-14.465 13.322-24.554 26.263-1.713 2.286-4.093 3.431-7.139 3.431-2.284 0-4.093-.57-5.424-1.709L121.35 145.89c-4.377-3.427-5.138-7.422-2.286-11.991 24.366-40.542 59.672-60.813 105.922-60.813 16.563 0 32.744 3.903 48.541 11.708 15.796 7.801 28.979 18.842 39.546 33.119 10.554 14.272 15.845 29.787 15.845 46.537-.014 8.374-1.208 16.079-3.58 23.124z" />
  </svg>
);

export default Question;

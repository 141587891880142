const zeroPadded = value => {
  const valueString = Math.trunc(value || 0).toString();
  if (valueString.length < 2) {
    return `0${valueString}`;
  }
  return valueString;
};

export const secondsToPlayerTime = seconds => {
  const truncSeconds = zeroPadded(seconds % 60);
  const minutes = zeroPadded(seconds / 60);
  const hours = Math.floor(minutes / 60);

  let result = `${minutes}:${truncSeconds}`;
  if (hours > 0) {
    result = `${hours}:${zeroPadded(minutes % 60)}:${truncSeconds}`;
  }
  return result;
};

export const secondsToMinutes = seconds => {
  const minutes = Math.max(1, Math.floor(seconds / 60));
  const unit = minutes === 1 ? 'minute' : 'minutes';

  return `${minutes} ${unit}`;
};

export const secondsToHoursAndMinutes = seconds => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  if (hours >= 1) {
    return `${hours}h ${minutes % 60}min`;
  }
  return `${minutes}min`;
};

export const toTitleCase = str => {
  return str.toLowerCase().split(' ').map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

export const detailsPageSecondsToHoursAndMinutes = seconds => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  return `${hours >= 1 ? `${hours}h` : ''}${
    minutes >= 1 ? `${minutes % 60}m` : ''
  }`;
};

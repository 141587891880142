import React from 'react';
import { connect } from 'react-redux';

import {
  fetchPlans,
  getPlans,
  getStartingPrice,
  getPlanForId
} from 'reducers/Plans/reducer';

class WithPlans extends React.Component {
  state = {};

  componentDidMount() {
    const { fetchPlans, plans } = this.props;

    if (!plans) {
      fetchPlans();
    }
  }

  render() {
    const {
      plans,
      startingPrice,
      planForId,
      planId,
      loaderComponent = null
    } = this.props;

    if (!plans) return loaderComponent;

    return this.props.children({
      plans,
      startingPrice,
      plan: planId && planForId(planId)
    });
  }
}

const mapStateToProps = state => ({
  planForId: getPlanForId(state),
  plans: getPlans(state),
  startingPrice: getStartingPrice(state)
});

const mapDispatchToProps = {
  fetchPlans
};

export default connect(mapStateToProps, mapDispatchToProps)(WithPlans);

import React, { useState, useEffect } from 'react';

const Back = ({ rectangle, ...props }) => {
  const [showRect, setShowRect] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => setShowRect(!showRect), 2000);
    return () => clearInterval(interval);
  });

  return (
    <svg
      width="136px"
      height="50px"
      viewBox="0 0 136 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Group-2-Copy" transform="translate(3.000000, 3.000000)">
          {rectangle === 'hidden' ? null : rectangle === 'blink-red' ? (
            <rect
              id="Rectangle"
              stroke="red"
              style={{ opacity: showRect ? 1 : 0, transition: '1500ms' }}
              stroke-width="2.93333333"
              x="-1.46666667"
              y="-1.46666667"
              width="132.733333"
              height="46.9333333"
            ></rect>
          ) : (
            <rect
              id="Rectangle"
              stroke="#FBA60B"
              stroke-width="2.93333333"
              x="-1.46666667"
              y="-1.46666667"
              width="132.733333"
              height="46.9333333"
            ></rect>
          )}
          <g
            id="now"
            transform="translate(40.568000, 8.981867)"
            fill-rule="nonzero"
          >
            <polygon
              id="N"
              fill="#FBA60B"
              points="2.9216 25.4848 2.9216 3.9072 15.6288 25.4848 19.1488 25.4848 19.1488 0.3168 16.2272 0.3168 16.2272 21.12 3.9776 0.3168 3.62139948e-13 0.3168 3.62139948e-13 25.4848"
            ></polygon>
            <path
              d="M36.9952,25.8016 C44.3168,25.8016 49.2096,20.0992 49.2096,12.9888 L49.2096,12.7072 C49.2096,5.632 44.3168,7.03437308e-14 36.9248,7.03437308e-14 C29.5328,7.03437308e-14 24.5344,5.632 24.5344,12.7776 L24.5344,13.0592 C24.5344,20.0992 29.568,25.8016 36.9952,25.8016 Z M36.9952,23.2672 C31.7504,23.2672 27.7024,19.1136 27.7024,13.024 L27.7024,12.7424 C27.7024,6.7584 31.0816,2.464 36.9248,2.464 C42.7328,2.464 46.0416,7.04 46.0416,12.672 L46.0416,12.9536 C46.0416,19.0432 42.592,23.2672 36.9952,23.2672 Z M61.072,25.4848 L66.9504,5.5968 L72.6528,25.4848 L75.4688,25.4848 L82.016,0.3168 L79.1648,0.3168 L73.9904,20.6976 L68.0768,0.3168 L65.8944,0.3168 L59.9808,20.6272 L54.8768,0.3168 L51.744,0.3168 L58.2912,25.4848 L61.072,25.4848 Z"
              id="OW"
              fill="#FFFFFF"
            ></path>
          </g>
          <g id="Group-Copy" transform="translate(7.333333, 8.066667)">
            <g id="Group-3">
              <g id="Clip-2"></g>
              <path
                d="M13.7951079,0.295032804 C6.17630619,0.295032804 0,6.46683598 0,14.0806286 C0,21.6944212 6.17630619,27.8666667 13.7951079,27.8666667 C21.4139095,27.8666667 27.5897731,21.6944212 27.5897731,14.0806286 C27.5897731,6.46683598 21.4139095,0.295032804 13.7951079,0.295032804"
                id="Fill-1"
                fill="#FBA60B"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M13.9328659,22.99 C13.1630504,22.99 12.54,22.3667405 12.54,21.5976018 C12.54,20.8265928 13.1630504,20.2033333 13.9328659,20.2033333 C14.7026814,20.2033333 15.3266667,20.8265928 15.3266667,21.5976018 C15.3266667,22.3667405 14.7026814,22.99 13.9328659,22.99"
              id="Fill-4"
              fill="#FFFFFE"
            ></path>
            <path
              d="M17.4166667,13.9755871 C17.4166667,15.4056527 16.4433321,16.641707 15.0868992,17.078891 L15.0868992,19.5066667 L12.7549963,19.5066667 L12.7549963,17.078891 C11.3981363,16.641707 10.45,15.4056527 10.45,13.9755871 L10.45,6.27 L12.7549963,6.27 L12.7549963,13.9755871 C12.7549963,14.580885 13.2931278,15.0667912 13.9337604,15.0667912 C14.573966,15.0667912 15.0868992,14.580885 15.0868992,13.9755871 L15.0868992,6.27 L17.4166667,6.27 L17.4166667,13.9755871"
              id="Fill-5"
              fill="#FFFFFE"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Back;

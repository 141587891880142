import { getConfig } from 'config';

// DO NOT EDIT THE FOLLOWING VALUES WITHOUT HAVING THE BACKEND REBUILD THE
// IMAGES WITH THAT VALUE.
export const MOBILE_CARD_WIDTH = 150;
export const TABLET_CARD_WIDTH = 210;
export const BROWSER_CARD_WIDTH = 300;
export const KEYFRAMES_WIDTH = 1400;
export const MOBILE_FEATURED_HERO_WIDTH = 1000;

const DEFAULT_QUALITY = 90;
const LOWER_QUALITY = 65;

export const CARD_WIDTH = getConfig().lowMemoryDevice
  ? MOBILE_CARD_WIDTH
  : BROWSER_CARD_WIDTH;

// eslint-disable-next-line
let config = [
  {
    key: 'image_medium',
    width: CARD_WIDTH,
    quality: DEFAULT_QUALITY,
    ext: 'jpg'
  },
  {
    key: 'image_keyframe',
    width: CARD_WIDTH,
    quality: LOWER_QUALITY,
    ext: 'jpg'
  },
  {
    key: 'keyframes',
    width: KEYFRAMES_WIDTH,
    quality: LOWER_QUALITY,
    ext: 'jpg'
  }
];

if (getConfig().lowMemoryDevice) {
  config = config.concat([
    {
      key: 'image_hero',
      width: MOBILE_FEATURED_HERO_WIDTH,
      quality: DEFAULT_QUALITY,
      ext: 'jpg'
    }
  ]);
}

export default queries =>
  `img_spec=${encodeURIComponent(JSON.stringify(config))}`;

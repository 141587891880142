import enUS from 'localization/content/smarttv.en-US.json';
import deDE from 'localization/content/smarttv.de-DE.json';

// A map of whitelisted browser locales.
// Keys are the locale from window.navigator.language, and the
//  value is what that locale should be mapped to for i18n purposes.
//  Anything not listed in this map will fallback to defaultLocale.
const intlMap = {
  'de-DE': 'de-DE',
  de: 'de-DE',
};

const getIntlMessages = locale => {
  return (
    {
      'de-DE': deDE,
    }[locale] || {}
  );
};

export const defaultLocale = 'en-US';
export const defaultIntlMessages = enUS;
export const intlLocale = intlMap[window.navigator.language] || defaultLocale;

// Merge the default strings with those of the desired locale.
//  This way, if a particular string is missing from the target locale,
//  we will fallback to the default locale version, assuming it exists.
export const intlMessages =
  intlLocale === defaultLocale
    ? defaultIntlMessages
    : Object.assign(defaultIntlMessages, getIntlMessages(intlLocale));

import { takeLatest, put, call } from 'redux-saga/effects';
import { FETCH_USER, gotUser } from './reducer';

import request from 'utils/request';

function* fetchUserData() {
  try {
    const user = yield call(
      request,
      `${process.env.REACT_APP_API_ROOT}/v1/user`
    );
    const userData = user.data;
    yield put(gotUser(userData));
  } catch (e) {}
}

export function* getUserData() {
  yield takeLatest(FETCH_USER, fetchUserData);
}

export default [getUserData];

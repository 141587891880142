import { getConfig } from '../config';
/*eslint-disable no-undef*/
const config = getConfig();

const convivaKey =
  process.env.NODE_ENV === 'production'
    ? `d610c6b5d07a306cfc23f1283d92f306ec28e8c6`
    : `e5e92e941b7b4368a913b60d23bfa6477e6e352d`;

const ConvivaIntegration = function () {
  // using default device info
  const deviceMetadata = {};
  deviceMetadata[Conviva.Constants.DeviceMetadata.BRAND] = config.name;
  deviceMetadata[Conviva.Constants.DeviceMetadata.MANUFACTURER] = config.name;
  deviceMetadata[Conviva.Constants.DeviceMetadata.MODEL] = 'NA';
  deviceMetadata[Conviva.Constants.DeviceMetadata.TYPE] =
    config.name === 'xbox'
      ? Conviva.Constants.DeviceType.CONSOLE
      : config.name === 'tivo' ||
        config.name === 'vewd' ||
        config.name === 'zeasn' ||
        config.name === 'altice' ||
        config.name === 'layer3tv'
      ? Conviva.Constants.DeviceType.SETTOP
      : Conviva.Constants.DeviceType.SMARTTV;

  deviceMetadata[Conviva.Constants.DeviceMetadata.VERSION] =
    process.env.REACT_APP_VERSION;

  deviceMetadata[Conviva.Constants.DeviceMetadata.OS_NAME] =
    config.device?.os || config.name;
  deviceMetadata[Conviva.Constants.DeviceMetadata.OS_VERSION] =
    config.device?.osVersion || 'NA';

  deviceMetadata[Conviva.Constants.DeviceMetadata.CATEGORY] =
    config.name === 'xbox'
      ? Conviva.Constants.DeviceCategory.XBOX
      : config.name === 'tivo'
      ? Conviva.Constants.DeviceCategory.TIVO
      : config.name === 'lg'
      ? Conviva.Constants.DeviceCategory.LG_TV
      : config.name === 'samsung' || config.name === 'tizen'
      ? Conviva.Constants.DeviceCategory.SAMSUNG_TV
      : config.name === 'tivo' ||
        config.name === 'vewd' ||
        config.name === 'zeasn' ||
        config.name === 'altice' ||
        config.name === 'layer3tv'
      ? Conviva.Constants.DeviceCategory.SET_TOP_BOX
      : Conviva.Constants.DeviceCategory.SMARTTV;

  const convivaConfigs = {};

  if (process.env.NODE_ENV !== 'production')
    convivaConfigs[
      Conviva.Constants.GATEWAY_URL
    ] = `https://curiositystream-test.testonly.conviva.com`;

  if (process.env.NODE_ENV === 'development')
    convivaConfigs[Conviva.Constants.LOG_LEVEL] =
      Conviva.Constants.LogLevel.DEBUG;

  Conviva.Analytics.init(convivaKey, null, convivaConfigs);
  Conviva.Analytics.setDeviceMetadata(deviceMetadata);
};
/*eslint-enable no-undef*/

export default ConvivaIntegration;

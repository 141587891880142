/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FontFaceObserver from 'fontfaceobserver';

import { withConfig, withAuth } from 'hoc';
import styles from './styles.module.css';

import Debug from 'components/Debug';
import Modal from 'components/Modal';
import TTS from 'components/TTSFocusableDescription';
import Button from 'components/Button';

import Navbar from 'components/Navbar';
import Featured from 'components/Featured';
import Search from 'components/Search';
import Profile from 'components/Profile';
import Login from 'components/Login';
import ExternalLogin from 'components/ExternalLogin';
import Collection from 'components/Collection';
import Collections from 'components/Collections';
import Categories from 'components/Categories';
import Category from 'components/Category';
import Media from 'components/Media';
import VolumeControl from 'components/VolumeControl';
import Terms from 'components/TextView/Terms';
import Text from 'components/Text';
import Privacy from 'components/TextView/Privacy';
import OnNow from 'components/OnNow';

import PersistedRoute from './PersistedRoute';
import FreeMonthModal from './FreeMonthModal';

import { setListener, setApproveTracking, sendPolicyUpdate } from 'utils/gdpr';

import { logOut } from 'reducers/AuthManager/reducer';

import { pushPageView } from 'utils/analytics';
import { session } from 'utils/tokenManager';
import { say } from 'utils/speechSynthesis';

const NETWORK_DEBOUNCE = 5000;

class App extends React.Component {
  state = {
    isOnline: true,
    configWasLoaded: false,
    initialDataFetchComplete: false,
  };

  constructor(props) {
    super(props);

    // Store the previous pathname and search strings
    this.currentPathname = null;
    this.currentSearch = null;
  }

  clearTTS = () => {
    if (this.props.config.getIsTTSEnabled()) {
      say(' ');
    }
  };

  onHistoryUpdate = (newLocation, action) => {
    const { history } = this.props;
    pushPageView(newLocation.pathname);

    if (action === 'PUSH') {
      if (
        newLocation.pathname !== this.currentPathname ||
        newLocation.search !== this.currentSearch
      ) {
        // Save new location
        this.currentPathname = newLocation.pathname;
        this.currentSearch = newLocation.search;
      }
    } else if (action === 'POP') {
      const dividedPathname = newLocation.pathname.split('/') || [];
      if (
        dividedPathname[1] === 'media' &&
        dividedPathname[2] &&
        !isNaN(dividedPathname[2]) &&
        dividedPathname[3] === 'play'
      ) {
        history.replace(dividedPathname.slice(0, -1).join('/'));
      }
    }

    //clear TTS Que so it doesn't carry over from page to page visit
    this.clearTTS();
  };

  checkTizenDeepLink = () => {
    const reqAppControl = window.tizen.application
      .getCurrentApplication()
      .getRequestedAppControl();

    if (reqAppControl) {
      const launchData = reqAppControl.appControl.data;

      if (launchData && launchData.length > 0) {
        const deepLinkArg = launchData.filter(a => a.key === 'PAYLOAD')[0];
        if (deepLinkArg) {
          const deepLinkObj = JSON.parse(
            JSON.parse(deepLinkArg.value[0]).values
          );

          this.checkGenericDeepLink({
            type: deepLinkObj.obj_type,
            id: deepLinkObj.media_id,
          });
        }
      }
    }
  };

  checkIfAppIsReady = () => {
    if (this.state.initialDataFetchComplete && this.state.configWasLoaded) {
      window.onReactAppLoaded();

      setTimeout(() => {
        if (
          this.props.config.isFreeMonth &&
          !this.props.config.hasFreeAccess &&
          !this.props.isLogged &&
          !session.get() &&
          localStorage.getItem('freeMonthModal') !== 'dont load'
        ) {
          // prevents auto-play if the modal is displayed
          if (this.props.location.pathname.includes('/play'))
            this.props.history.replace(
              this.props.location.pathname.replace('/play', '')
            );

          this.setState({ showFreeMonthModal: true });
        }
      }, 1000); // setTimeout needed to work properly on vizio
    }
  };

  pageIsDoneLoading = () => {
    this.setState({ initialDataFetchComplete: true }, this.checkIfAppIsReady);
  };

  componentDidMount() {
    const { config, history, location } = this.props;

    this.checkLocationTimeout = setTimeout(
      () =>
        this.setState({
          showDebug:
            window.location.href.indexOf('https://tv') === -1 &&
            window.location.href.indexOf('https://testingtv') === -1 &&
            window.location.href.indexOf('.platformsh.site') === -1,
        }),
      1000
    );

    config.onFinishedLoading(() => {
      this.setState({ configWasLoaded: true }, this.checkIfAppIsReady);
    });

    if (!config.hasNativeVTTSupport) {
      this.checkAndDownloadFonts();
    }

    // first pageview is non interactive to prevent an inaccurate bouce rate to be reported
    // https://analytical42.com/2016/low-bounce-rate-google-analytics/
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/events#non-interaction_events
    pushPageView(location.pathname);

    history.listen(this.onHistoryUpdate);

    setListener(({ gdpr, privacy }) => {
      if (gdpr) {
        setTimeout(() => this.setState({ showGdprModal: true }), 1500);
      }
      if (privacy) {
        setTimeout(() => this.setState({ showPrivacyModal: true }), 1500);
      }
    });

    window.addEventListener('keydown', this.onKeyDown);
    window.navigator.gamepadInputEmulation = 'keyboard';

    if (config.onNetworkChanged) {
      config.onNetworkChanged(result => {
        clearTimeout(this.networkPopupTimer);
        this.networkPopupTimer = setTimeout(() => {
          this.setState({
            isOnline: result.online,
          });
        }, NETWORK_DEBOUNCE);
      });
    }

    config.onPerformanceSet = () => {
      if (config.performanceIndex < 5000) {
        const backgroundImage = `url(${require('./background.jpg')})`;
        this.setState({ backgroundImage });
      } else if (config.keyRepeatThrottleTime) {
        // higher throttling prevents focus to jump out of fixed layout
        // when a direction is being help on the remote
        try {
          window.TVJS.DirectionalNavigation.keyRepeatDelay =
            config.keyRepeatThrottleTime;
        } catch (e) {
          // no need for action. also, ninjas
        }
      }
    };

    if (config.name === 'tizen') {
      this.checkTizenDeepLink();
      window.addEventListener('appcontrol', this.checkTizenDeepLink);
    }

    if (config.name === 'lg') {
      this.checkLgDeepLink();
      document.addEventListener('webOSRelaunch', this.checkLgDeepLink);
    }

    const genericParams = this.props.location.search
      .replace('?', '')
      .split('&');

    const mediaIdParam = genericParams[0].split('=')[1];

    if (mediaIdParam) {
      const [type, id] = mediaIdParam.split('-');
      let play = this.props.location.search.includes('play');

      this.checkGenericDeepLink({ type, id, play });
    }
  }

  checkLgDeepLink = () => {
    try {
      const launchParams = window.launchParams
        ? window.launchParams
        : window.PalmSystem && window.PalmSystem.launchParams
        ? window.PalmSystem.launchParams
        : '';

      if (launchParams && launchParams.includes('-')) {
        var params = JSON.parse(launchParams);
        if (params.contentTarget) {
          const [type, id] = params.contentTarget.split('-');
          this.checkGenericDeepLink({ type, id });
        }
      } else {
      }
    } catch (e) {
      console.log(e);
      // no big deal, go get a drink
    }
  };

  checkGenericDeepLink = ({ type, id, play }) => {
    if (id === undefined || id === null) return;
    if (type === undefined || type === null) return;

    // the following helps re-init metadata loading when opening deep link while
    // app already open
    this.props.history.push(`/`);

    if (type === 'media' || type === 'episode') {
      this.props.history.replace(`/media/${id}${play ? '/play' : ''}`);
    } else {
      this.props.history.replace(`/series/${id}`);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
    clearTimeout(this.checkLocationTimeout);
  }

  onKeyDown = e => {
    this.setState({ keyDown: e.keyCode });

    const { config, history, location } = this.props;

    if (config.isReturnKey(e.keyCode) || e.keyCode === 9 /* tab */) {
      e.preventDefault();
    }

    // VIZIO: the chromecast execution environment (vizio) do not seem to let dom
    // elements trigger their own events. For that reason, we gotta fire their
    // callbacks from here.
    // if (
    //   config.name === 'vizio' &&
    //   e.keyCode === config.keyCodes.enter &&
    //   document.activeElement
    // ) {
    //   document.activeElement.click();
    // }

    // IMPORTANT: the following can not be refactored as long as Vizio devices
    // support is needed. Trying to isolate the 'back' behavior in each page is
    // impossible since events attached to a single element won't be triggered
    // on vizio. Thats why it is managed globally here.
    console.log(e.keyCode);
    if (
      config.isReturnKey(e.keyCode) &&
      !location.pathname.includes('/onnow') &&
      !location.pathname.includes('/play') &&
      location.pathname !== '/search' &&
      location.pathname !== '/profile' &&
      location.pathname !== '/login' &&
      !this.state.showFreeMonthModal
    ) {
      if (location.pathname === '/' && config.exitApp) {
        if (config.skipExitConfirm) {
          config.exitApp();
        } else {
          this.toggleExitDialog();
        }
      } else {
        if (history.length <= 2) history.replace('/');
        else history.goBack();
      }
    }

    if (e.keyCode === config.keyCodes.exit && config.exitApp) {
      if (config.skipExitConfirm) {
        config.exitApp();
      } else {
        this.toggleExitDialog();
      }
    }
  };

  toggleExitDialog = () => {
    this.setState({ showExitDialog: !this.state.showExitDialog });
  };

  checkAndDownloadFonts() {
    const fonts = [
      'Roboto',
      'Courier New',
      'Times New Roman',
      'Andale Mono',
      'Pacifico',
      'Comic Sans MS',
    ];

    fonts.forEach(font => {
      const fontObserver = new FontFaceObserver(font);
      fontObserver.load();
    });
  }

  get modalToShow() {
    const {
      showExitDialog,
      showFreeMonthModal,
      isOnline,
      showGdprModal,
      showGdprModalConfirm,
      showPrivacyModal,
    } = this.state;

    const { config, history, location, logOut } = this.props;

    if (location.pathname === '/logout')
      return (
        <Modal>
          <TTS focus>
            <Text large>
              <FormattedMessage id="smarttv:account_settings_logoutmodal_content" />
            </Text>
          </TTS>
          <Button
            className={styles.modalButton}
            id="logout-yes"
            onClick={() => {
              logOut();
              history.replace('/login');
            }}
          >
            <FormattedMessage id="smarttv:common_yes_button" />
          </Button>
          <div style={{ width: '.5em', display: 'inline-block' }} />
          <Button
            className={styles.modalButton}
            id="logout-no"
            focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
            onClick={() => {
              history.goBack();
            }}
          >
            <FormattedMessage id="smarttv:common_no_button" />
          </Button>
        </Modal>
      );

    if (showExitDialog)
      return (
        <Modal id="exit">
          <TTS focus>
            <Text large>
              <FormattedMessage id="smarttv:exit_modal_content_text" />
            </Text>
          </TTS>
          <>
            <Button
              className={styles.modalButton}
              focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
              onClick={this.toggleExitDialog}
            >
              <FormattedMessage id="smarttv:common_no_button" />
            </Button>
            <div style={{ width: '.5em', display: 'inline-block' }} />
            <Button className={styles.modalButton} onClick={config.exitApp}>
              <FormattedMessage id="smarttv:common_yes_button" />
            </Button>
          </>
        </Modal>
      );

    if (showFreeMonthModal)
      return (
        <FreeMonthModal
          onClose={() => {
            localStorage.setItem('freeMonthModal', `dont load`);
            this.setState({ showFreeMonthModal: false });
          }}
        />
      );

    if (showGdprModal && !location.pathname.startsWith('/privacy'))
      return (
        <Modal key="gdpr">
          <TTS focus>
            <Text title>This App Uses Cookies...</Text>
            <Text large>
              and similar technologies to improve your browsing experience and
              the site's performance, collect site analytics, track marketing
              campaigns and site activity, and deliver interest-based
              advertising.
            </Text>
            <Text large>
              Find out more about how we use cookies and how you can change your
              settings by reading our Privacy Policy.
            </Text>
          </TTS>
          <>
            <Button
              dataCy="accept-cookies"
              className={styles.modalButton}
              focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
              onClick={() => {
                setApproveTracking(true);
                this.setState({ showGdprModal: false });
              }}
            >
              I Accept Cookies
            </Button>
            <br />
            <Button
              className={styles.modalButton}
              onClick={() => history.push('/privacy')}
            >
              See Privacy Policy
            </Button>
            <br />
            <Button
              className={styles.modalButton}
              onClick={() => {
                this.setState({
                  showGdprModal: false,
                  showGdprModalConfirm: true,
                });
              }}
            >
              I Decline Cookies
            </Button>
          </>
        </Modal>
      );

    if (showGdprModalConfirm && !location.pathname.startsWith('/privacy'))
      return (
        <Modal key="showGdprModalConfirm">
          <TTS focus>
            <Text title>Are You Sure You Want to Decline?</Text>
            <Text large>
              If you decline cookies, your access to our site will be limited
              and you may not receive all its features.
            </Text>
            <Text large>
              Find out more avout how we use cookies and how you can change your
              settings by reading our Privacy Policy.
            </Text>
          </TTS>
          <>
            <Button
              className={styles.modalButton}
              focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
              onClick={() => {
                setApproveTracking(false);
                this.setState({ showGdprModalConfirm: false });
              }}
            >
              Yes, I decline
            </Button>
            <br />
            <Button
              className={styles.modalButton}
              onClick={() => history.push('/privacy')}
            >
              See Privacy Policy
            </Button>
            <br />
            <Button
              className={styles.modalButton}
              onClick={() => {
                setApproveTracking(true);
                this.setState({ showGdprModalConfirm: false });
              }}
            >
              No, I accept
            </Button>
          </>
        </Modal>
      );

    if (showPrivacyModal && !location.pathname.startsWith('/privacy'))
      return (
        <Modal id="gdpr">
          <TTS focus>
            <Text title>Updated Privacy Policy</Text>
            <Text large>
              We have updated our privacy policy recently. To see the update,
              please select the “See Privacy Policy” button below.
            </Text>
          </TTS>
          <>
            <Button
              className={styles.modalButton}
              focus={!(config.getIsTTSEnabled() && config.focusTextWhenTTS)}
              onClick={() => {
                sendPolicyUpdate();
                this.setState({ showPrivacyModal: false });
              }}
            >
              OK
            </Button>
            <br />
            <Button
              className={styles.modalButton}
              onClick={() => {
                this.setState({ showPrivacyModal: false });
                this.props.history.push('/privacy');
              }}
            >
              See Privacy Policy
            </Button>
          </>
        </Modal>
      );

    if (!isOnline)
      return (
        <Modal id="offline">
          <TTS focus>
            <Text large>
              It looks like you are disconnected from your network. You should
              check your wireless or wired connection.
            </Text>
          </TTS>
        </Modal>
      );

    return null;
  }

  render() {
    const { configWasLoaded, backgroundImage, showDebug } = this.state;

    const { isDoneWithAutoSignin, config } = this.props;

    const appIsReady = isDoneWithAutoSignin && configWasLoaded;

    return (
      <div
        ref={node => (this.root = node)}
        className={styles.background}
        style={{
          backgroundImage,
        }}
      >
        {showDebug && <Debug key={showDebug} />}
        {this.modalToShow}
        {!(this.modalToShow && config.focusRootIsBroken) && (
          <div>
            <Route
              render={({ location, history }) => {
                if (
                  !location.pathname.startsWith('/privacy') &&
                  !location.pathname.startsWith('/terms') &&
                  !location.pathname.startsWith('/onnow') &&
                  !location.pathname.startsWith('/series/') &&
                  !location.pathname.startsWith('/media/')
                )
                  return <Navbar show location={location} history={history} />;
                else return null;
              }}
            />

            {appIsReady && (
              <PersistedRoute
                key={this.props.isLogged ? 'featured-logged' : 'featured-guest'} // refreshes content and focus after login/logout
                path="/"
                exact
                onFinishedLoading={this.pageIsDoneLoading}
                component={Featured}
              />
            )}

            <PersistedRoute path="/categories" exact component={Categories} />

            <PersistedRoute path="/collections" exact component={Collections} />

            <PersistedRoute path="/search" component={Search} />

            <Route exact path="/login/:type" component={ExternalLogin} />
            <Route exact path="/login" component={Login} />

            {isDoneWithAutoSignin && (
              <Route path="/profile" component={Profile} />
            )}

            <PersistedRoute
              path="/collections/:id"
              exact
              component={Collection}
            />

            <Route path="/privacy" component={Privacy} />

            <Route path="/terms" component={Terms} />

            {appIsReady && <Route path="/onnow" component={OnNow} />}

            <PersistedRoute
              path="/categories/:id"
              key={
                this.props.isLogged ? 'categories-logged' : 'categories-guest'
              } // refreshes content and focus after login/logout
              exact
              component={Category}
            />

            {appIsReady && (
              <Route
                path="/:type/:id"
                type="media"
                render={props => {
                  if (props.match) {
                    if (
                      props.match.params.type === 'media' ||
                      props.match.params.type === 'series'
                    ) {
                      return (
                        <Media
                          {...props}
                          onFinishedLoading={this.pageIsDoneLoading}
                        />
                      );
                    }
                  }
                  return null;
                }}
              />
            )}

            {config.managesVolumeControl && window.webapis && (
              <VolumeControl
                getVolume={window.webapis.audiocontrol.getVolume}
                getMute={window.webapis.audiocontrol.getMute}
                setVolumeUp={window.webapis.audiocontrol.setVolumeUp}
                setVolumeDown={window.webapis.audiocontrol.setVolumeDown}
                setMute={window.webapis.audiocontrol.setMute}
              />
            )}
          </div>
        )}
        {this.state.configWasLoaded && (
          <video
            data-dashjs-player
            autoPlay
            id="video-player"
            className={styles.vizioPlayer}
          />
        )}
        {config.name === 'tizen' && (
          <object
            id="tizen-player"
            style={{
              opacity: 0,
              position: 'fixed',
              top: 0,
              height: '100%',
              left: 0,
              width: '100%',
              zIndex: 9,
            }}
            type="application/avplayer"
          />
        )}
        {/* do not remove, this is a portal used by the player overlay */}
        <div id="player-overlay" />
      </div>
    );
  }
}

const mapDispatchToProps = {
  logOut,
};

export default compose(
  connect(null, mapDispatchToProps),
  withConfig,
  withAuth
)(App);

import React from 'react';

import Carousel from 'components/Carousel';

class Featured extends React.Component {
  state = {
    page: 1
  };

  fetchNextPage = () => {
    const { fetchMoreAction, group } = this.props;

    if (fetchMoreAction) {
      const newPageIndex = this.state.page + 1;
      this.setState({ page: newPageIndex });

      fetchMoreAction({
        categoryId: group.type ? null : group.id,
        filterBy: group.type || 'category',
        term: group.name,
        page: newPageIndex
      });
    }
  };

  render() {
    return (
      <Carousel
        focus={this.props.focus}
        analyticsData={this.props.analyticsData}
        header={this.props.group.label}
        media={this.props.media}
        onShouldLoadMore={this.fetchNextPage}
        onSelect={this.props.onSelect}
      />
    );
  }
}

export default Featured;

import React from 'react';
import { connect } from 'react-redux';

import CategoryButton from './CategoryButton';

import {
  fetchCategories,
  getMainCategories,
} from 'reducers/Categories/reducer';

import { fireEvent } from 'utils/analytics';

import styles from './styles.module.css';
import ScrollArea from 'components/ScrollArea';
import chunk from 'lodash.chunk';

class Browse extends React.Component {
  state = {};

  componentDidMount() {
    if (!this.props.categories.length) {
      this.props.fetchCategories();
    }

    fireEvent({
      category: 'page',
      action: 'view',
      label: 'categories',
    });
  }

  render() {
    const { categories, history } = this.props;

    const segments = chunk(categories, 2);

    return (
      <ScrollArea
        paddingTop={3}
        css={styles.categories}
        id="categories"
        data-cy="page-categories"
      >
        {segments.map((segment, index) => (
          <div key={`row${index}`}>
            {segment.map(c => (
              <CategoryButton
                key={c.id}
                category={c}
                onClick={() => history.push(`/categories/${c.id}`)}
              />
            ))}
          </div>
        ))}
      </ScrollArea>
    );
  }
}

// Browse.propTypes = {
//   params: PropTypes.object,
//   fetchCategories: PropTypes.func.isRequired,
//   categories: PropTypes.array
// };

const mapStateToProps = (state, { match }) => ({
  categories: getMainCategories(state),
});

const mapDispatchToProps = {
  fetchCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Browse);
